import {createSlice} from '@reduxjs/toolkit'


export const adminClientDisplay = createSlice({
    name: 'adminClientDisplay',
    initialState: {
        clientForm: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateClientForm: (state, action) => {
            state.clientForm = action.payload;
        }
    }
})


export default adminClientDisplay.reducer


export const {updateClientForm} = adminClientDisplay.actions

export const adminClientDisplaySelector = (state) => state.adminClientDisplay;
