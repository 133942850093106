import {createSlice} from '@reduxjs/toolkit'
import {fetchClientList} from "./features";


export const redactorClientList = createSlice({
    name: 'redactorClientList',
    initialState: {
        clients: null,
        isLoadingClients: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClientList.fulfilled, (state, {payload}) => {
            state.clients = payload;
            state.isLoadingClients = false;
            state.isError = false;
        }).addCase(fetchClientList.pending, (state, {payload}) => {
            state.isLoadingClients = true;
            state.isError = false;
        }).addCase(fetchClientList.rejected, (state, {payload}) => {
            state.isLoadingClients = false;
            state.isError = true;
        })
    }
})


export default redactorClientList.reducer


export const redactorClientListSelector = (state) => state.redactorClientList;
