import {createSlice} from '@reduxjs/toolkit'
import {fetchRedactorCommandDisplay} from "./features";


export const redactorCommandDisplay = createSlice({
    name: 'redactorCommandDisplay',
    initialState: {
        command: null,
        isLoading: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRedactorCommandDisplay.fulfilled, (state, {payload}) => {
            state.command = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchRedactorCommandDisplay.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchRedactorCommandDisplay.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default redactorCommandDisplay.reducer


export const redactorCommandDisplaySelector = (state) => state.redactorCommandDisplay;
