import React, {useEffect, useState} from 'react'
import {Jumbotron, Modal, ModalFooter, ModalHeader} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import {Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {
    COULEUR_BLEUE,
    DeliveredTexts,
    formatDate,
    getDateFormatInput,
    ISOlanguages,
    ValidatedTexts
} from "../../helpers/Constants";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import Analyzer from "./Analyzer";
import {DownloadAll, FetchDataForPdq, setKeyWordList} from '../../helpers/PdfFunction'
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import ModalBody from "reactstrap/es/ModalBody";
import AccordionActions from "@material-ui/core/AccordionActions";
import HistoriqueIntervention from "./HistoriqueIntervention";
import {useDispatch, useSelector} from "react-redux";
import {adminCommandDisplaySelector, updateLineDataTable} from "../../store/Admin/CommandDisplay";

import {useHistory} from 'react-router-dom';
import {UserSessionSelector} from "../../store/UserSession";
import {updateForm} from "../../store/Admin/Analyser";
import Container from "reactstrap/lib/Container";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CommandLineDetail from "./CommandLineDetail";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {fetchAdminCommandList} from "../../store/Admin/CommandList/features";
import {adminCommandListSelector} from "../../store/Admin/CommandList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//TODO check if i have to add url field


export default function CommandeDisplay() {
    let {command, isLoading, isError} = useSelector(adminCommandDisplaySelector);
    let {url, title} = useSelector(adminCommandListSelector);
    const dispatch = useDispatch();
    const {user} = useSelector(UserSessionSelector)
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const [titre, setTitre] = useState('');
    const [consultant, setConsultant] = useState(null);
    const [client, setClient] = useState([]);
    const [langue, setLangue] = useState('');
    const [nbrText, setNbrText] = useState('');
    const [briefDetaille, setBriefDetaille] = useState('');
    const [documentationClient, setDocumentationClient] = useState('');
    const [gabSemantique, setGabSemantique] = useState('');
    const [redacteur, setRedacteur] = useState([]);
    const [noteDeadline, setNoteDeadline] = useState(0)
    const [noteConsigne, setNoteConsigne] = useState(0)
    const [noteQualite, setNoteQualite] = useState(0)

    const [showHistorique, setShowHistorique] = useState(false);
    const [showLineCommand, setShowLineCommand] = useState(false);
    const [listConsultant, setListConsultant] = useState([])
    const [listRedacteur, setListRedacteur] = useState([]);

    const [thematique, setThematique] = useState([]);
    const [ThématiqueList, setThématiqueList] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [listText, setListText] = useState([])
    const [loading, setLoading] = useState(false);
    const [newProps, setNewProps] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const toggleSuccess = () => {
        if (success) {
            setEditMode(false)
        }
        setSuccess(!success);
    }
    const toggleReturn = () => history.goBack();
    const toggleError = () => setError(!error);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        axios.get("/Admin/GetConsultant").then(res => setListConsultant(res.data)).catch(e => console.log(e.response.data))
        axios.get("/Admin/GetRedacteurWithNoTracking").then(res => {
            setListRedacteur(res.data);
        }).catch(e => console.log(e.response.data))

        axios.get("/Admin/GetListThematique").then(res => setThématiqueList(res.data))
    }, [])
    useEffect(() => {
        if (!isLoading && !isError) {
            console.log(command.commandeRedacteurRelation.map(x => x.text))
            setTitre(command.titre);
            setLangue(command.langue);
            setNbrText(command.nbrText);
            setBriefDetaille(command.briefDetaille);
            setDocumentationClient(command.documentationClient);
            setGabSemantique(command.gabSemantique);
            setRedacteur(command.commandeRedacteurRelation.map(x => x.idRedacteurNavigation));
            setNoteDeadline(command.noteDeadline);
            setNoteConsigne(command.noteConsigne);
            setNoteQualite(command.noteQualite);
            setThematique(command.commandeThematique.map(x => x.thematique))
            setListText(command.commandeRedacteurRelation.map(x => x.text).reduce((accum, el) => accum.concat(el), []));
            setExpanded(command.commandeRedacteurRelation.map(x => x.text).reduce((accum, el) => accum.concat(el), []).length > 0);
            setConsultant(command.websiteNavigation.consultantNavigation);
            setClient(command.websiteNavigation.clientWebsiteRelation.map(x => x.clientNavigation.mail));
            setNewProps(false);
        }
        document.title = "Typix - Détails de la commande";
    }, [command])


    const Update = () => {
        setLoading(true);
        axios.post("/Admin/UpdateCommande", {
            id: command.id,
            langue: langue,
            nbrText: nbrText,
            briefDetaille: briefDetaille,
            documentationClient: documentationClient,
            gabSemantique: gabSemantique,
            commandeThematique: thematique,
            consultant: consultant.id,
            noteDeadline: noteDeadline,
            noteConsigne: noteConsigne,
            noteQualite: noteQualite
        }).then(() => {
            dispatch(fetchAdminCommandList({
                url,
                title
            }))
            setSuccess(true)
            setLoading(false)
        }).catch(() => {
            setError(true)
            setLoading(false)
        })
    }

    if (isError) {
        history.push('/Admin/MainMenu')
    }
    if (newProps || isLoading) {
        return (
            <Container>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </Container>
        )
    }

    return (
        <Container>
            <Jumbotron>
                <HistoriqueIntervention visible={showHistorique} titre={command.titre}
                                        Close={() => setShowHistorique(false)} id={command.id}/>
                <CommandLineDetail visible={showLineCommand} titre={command.titre}
                                   Close={() => {
                                       setShowLineCommand(false)
                                   }} nbrText={parseInt(command.nbrText)}/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                       onClick={() => history.goBack()}/>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{flexDirection: 'row' , display : 'flex'}}>
                            <Typography variant={'h5'}>Détails de la commande </Typography>
                            {
                                editMode ? <Button onClick={Update}
                                                   disabled={loading}>
                                        <img src={"/Images/update.png"} height={30} width={30}/>
                                    </Button> :
                                    <Button
                                        onClick={() => setEditMode(true)}>
                                        <img src={"/Images/edit.png"} height={30} width={30}/></Button>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => setShowHistorique(true)} variant="contained" color={'primary'}
                                    style={{marginBottom: 25}}>Historique
                                intervention</Button>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => setShowLineCommand(true)} variant="contained" color={'primary'}
                                    style={{marginBottom: 25}}>Détail ligne de commande</Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Titre de la commande"}
                                    value={titre}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="consultant"
                                    fullWidth
                                    disableClearable
                                    disabled={!editMode}
                                    value={consultant}
                                    onChange={(event, newValue) => {
                                        setConsultant(newValue);
                                    }}
                                    options={listConsultant}
                                    getOptionLabel={(option) => option.nomPrenom}
                                    renderInput={(params) => <TextField {...params} label="Nom du Consultant"
                                                                        variant="standard"/>}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField id="select" label={"Mail des clients"} defaultValue={client[0]} select>
                                    {
                                        client.map((x, key) => <MenuItem key={key} value={x}>{x}</MenuItem>)
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="redacteur"
                                    multiple
                                    fullWidth
                                    disabled={true}
                                    value={redacteur}
                                    onChange={(event, newValue) => {
                                        setRedacteur(newValue);
                                    }}
                                    options={listRedacteur}
                                    getOptionLabel={(option) => option.mail}
                                    renderInput={(params) => <TextField {...params} label="Mail des rédacteurs"
                                                                        variant="standard"/>}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                {
                                    command.commandeRedacteurRelation.length > 0 &&
                                    <FormControl disabled>
                                        <InputLabel shrink>Nombre de mots par texte</InputLabel>
                                        <Input type={"number"}
                                               value={Math.min.apply(Math, command.commandeRedacteurRelation.map(x => x.nbrMotsMin))}
                                               placeholder={"Nombre minimal"} style={{marginTop: 30}}
                                               readOnly={true}
                                               endAdornment={<InputAdornment position="end">Min</InputAdornment>}/>
                                        <Input type={"number"}
                                               value={Math.max.apply(Math, command.commandeRedacteurRelation.map(x => x.nbrMotsMax))}
                                               placeholder={"Nombre maximum"}
                                               readOnly={true}
                                               endAdornment={<InputAdornment position="end">Max</InputAdornment>}

                                        />
                                    </FormControl>
                                }

                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="langue"
                                    value={langue}
                                    disabled={!editMode}
                                    disableClearable
                                    onChange={(event, newValue) => {
                                        setLangue(newValue);
                                    }}
                                    options={ISOlanguages.sort(function (a, b) {
                                        if (["Français", "Anglais", "Espagnol", "Italien", "Allemand"].includes(a.name)) {
                                            return 0;
                                        }
                                        if (["Français", "Anglais", "Espagnol", "Italien", "Allemand"].includes(b.name)) {
                                            return 0;
                                        }
                                        if (a.name > b.name) {
                                            return 1;
                                        }
                                        if (b.name > a.name) {
                                            return -1;
                                        }
                                        return 0;
                                    }).map(x => x.name)}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label="Langue" variant="standard"/>}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label={"Nombre de textes"}
                                    value={nbrText}
                                    type={'number'}
                                    disabled={!editMode}
                                    inputProps={{min: 0}}
                                    onBlur={() => {
                                        if (nbrText < 0) {
                                            setNbrText(0)
                                        }
                                    }}
                                    onChange={e => setNbrText(parseInt(e.target.value))}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                {
                                    command.commandeRedacteurRelation.length > 0 && <TextField
                                        label={"Deadline"}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type={"date"}
                                        value={formatDate(new Date(Math.max.apply(Math, command.commandeRedacteurRelation.filter(x => x.deadline).map(x => new Date(x.deadline)))))}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    command.commandeRedacteurRelation.length > 0 && <TextField
                                        label="Tarif (au mot)"
                                        type={"number"}
                                        disabled
                                        value={Math.min.apply(Math, command.commandeRedacteurRelation.map(x => x.tarif))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            readOnly: !editMode
                                        }}
                                    />
                                }

                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Bref détaillé"}
                                    placeholder={"Lien drive.."}
                                    fullWidth
                                    disabled={!editMode}
                                    value={briefDetaille}
                                    onChange={e => setBriefDetaille(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Documentation client"}
                                    fullWidth
                                    placeholder={"Lien drive.."}
                                    value={documentationClient}
                                    disabled={!editMode}
                                    onChange={e => setDocumentationClient(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel shrink>Gap sémantique de la commande</InputLabel>
                                    <Input type={"text"} value={gabSemantique} style={{marginTop: 25}}
                                           placeholder={"Lien drive.."}
                                           onChange={e => setGabSemantique(e.target.value)}
                                           readOnly={!editMode}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth disabled={!editMode}>
                                    <InputLabel shrink>Thématiques d’expertise</InputLabel>
                                    <Select
                                        multiple
                                        displayEmpty
                                        value={thematique}
                                        onChange={(event) => {
                                            setThematique(event.target.value);
                                        }}
                                        style={{marginTop: 25}}
                                        MenuProps={MenuProps}
                                        input={<Input/>}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>Liste des thématiques</em>;
                                            }
                                            return selected.map((value) => (
                                                <Chip key={value} label={value}/>
                                            ))
                                        }}
                                    >
                                        {
                                            ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                                    <Checkbox checked={thematique.indexOf(item) > -1}/>
                                                    <ListItemText primary={item}/>
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel>Note rédacteur :</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component="fieldset" mb={2} borderColor="transparent">
                                    <Typography component="legend">Respect des deadlines</Typography>
                                    <Rating value={noteDeadline}
                                            onChange={(event, newValue) => setNoteDeadline(newValue)}
                                            readOnly={!editMode}/>
                                </Box>
                                <Box component="fieldset" mb={2} borderColor="transparent">
                                    <Typography component="legend">Respect des consignes</Typography>
                                    <Rating value={noteConsigne}
                                            onChange={(event, newValue) => setNoteConsigne(newValue)}
                                            readOnly={!editMode}/>
                                </Box>
                                <Box component="fieldset" mb={2} borderColor="transparent">
                                    <Typography component="legend">Qualité rédactionnelle</Typography>
                                    <Rating value={noteQualite} onChange={(event, newValue) => setNoteQualite(newValue)}
                                            readOnly={!editMode}/>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <DeliveredTexts
                                    deliveredText={command.commandeRedacteurRelation.map(x => x.text)
                                        .reduce((accum, el) => accum.concat(el), []).filter(x => x.delivered).length}
                                    text
                                    total={parseInt(command.nbrText)}/>
                            </Grid>

                            <Grid item xs={6}>
                                <ValidatedTexts
                                    validatedText={command.commandeRedacteurRelation.map(x => x.text)
                                        .reduce((accum, el) => accum.concat(el), []).filter(x => x.delivered && x.validateAt).length}
                                    text
                                    total={parseInt(command.nbrText)}/>
                            </Grid>

                            {
                                listText ?
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid item xs={12}>
                                                        {
                                                            !(listText.length > 0) ?
                                                                <Typography align={'center'}>Contenu
                                                                    indisponible</Typography> :
                                                                <Typography align={'center'}>Contenu
                                                                    disponible</Typography>

                                                        }
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>

                                                        <Grid container xs={12} spacing={1}>
                                                            {
                                                                listText.length > 0 &&
                                                                listText.map((item, key) => {
                                                                    const keywords = item.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n");
                                                                    const convertedText = setKeyWordList(keywords);
                                                                    console.log(item)
                                                                    return (
                                                                        <React.Fragment key={key}>
                                                                            <Grid item xs={5}>
                                                                                <Typography
                                                                                    className={item.validateAt ? 'text-success' : 'text'}>Texte {key + 1} : {item.motCle}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <FetchDataForPdq text={item.text1}
                                                                                                 user={user}
                                                                                                 list={convertedText}
                                                                                                 langue={'FRENCH'}
                                                                                                 keywords={keywords}
                                                                                                 htmlText={item.htmlText}
                                                                                                 result={item.result}
                                                                                                 nomText={item.nomText}/>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <Button variant="contained"
                                                                                        color="primary"
                                                                                        style={{marginLeft: 25}}
                                                                                        onClick={() => {
                                                                                            dispatch(updateForm({
                                                                                                data: item,
                                                                                                idCommande: command.id,
                                                                                                idCommandeRedacteurRelation: item.commandeRedacteurRelation,
                                                                                                nbrTextMax: command.commandeRedacteurRelation.find(x => x.id === item.commandeRedacteurRelation).nbrMotsMax,
                                                                                                nbrTextMin: command.commandeRedacteurRelation.find(x => x.id === item.commandeRedacteurRelation).nbrMotsMin
                                                                                            }))
                                                                                            history.push('/Admin/TextDetails');
                                                                                        }}>
                                                                                    Voir Texte
                                                                                </Button>
                                                                            </Grid>
                                                                        </React.Fragment>)
                                                                })
                                                            }

                                                        </Grid>


                                                    </Grid>
                                                </AccordionDetails>

                                                <AccordionActions>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={6}>
                                                            <DownloadAll disabled={!listText.length > 0}
                                                                         data={listText}/>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionActions>
                                            </Accordion>
                                        </Grid>

                                    </React.Fragment>
                                    :
                                    <Grid item xs={12}>
                                        <div>
                                            <LinearProgress/>
                                            <LinearProgress color="secondary"/>
                                        </div>
                                    </Grid>
                            }


                        </Grid>
                    </Grid>
                </Grid>
                <Modal isOpen={success} toggle={toggleReturn}>
                    <ModalHeader  toggle={toggleSuccess}>Commande modifiée avec succès !</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/succes.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleSuccess}>Fermer</Button>{' '}
                        <Button color="secondary" onClick={toggleReturn}>Revenir a l'accueil</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={error} toggle={toggleError}>
                    <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/error.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                    </ModalFooter>
                </Modal>
            </Jumbotron>
        </Container>
    );
}
/*

                                <FormControl fullWidth>

                                    <InputLabel shrink>Mail Rédacteur </InputLabel>
                                    <Select
                                        multiple
                                        value={redacteur}
                                        onChange={e => {
                                            setRedacteur(e.target.value)
                                            console.log(redacteur)
                                        }}  style={{marginTop: 25}}
                                        MenuProps={MenuProps}
                                        input={<Input/>}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>Liste des rédacteur</em>;
                                            }
                                            return (<div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value.mail} className={classes.chip}/>
                                                ))}
                                            </div>)
                                        }}
                                        inputProps={{
                                            readOnly: !editMode
                                        }}
                                    >
                                        {
                                            listRedacteur.map((item, key) => <MenuItem key={key} value={item}>
                                                    <Checkbox checked={redacteur.map(x => x.id).indexOf(item.id) > -1}/>
                                                    <ListItemText primary={item.mail}/>
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
 */
