import React, {useEffect, useState} from 'react'
import {Jumbotron} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import {Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {DeliveredTexts, getDateFormatInput, ValidatedTexts} from "../../helpers/Constants";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {DownloadAll, FetchDataForPdq, setKeyWordList} from "../../helpers/PdfFunction";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccordionActions from "@material-ui/core/AccordionActions";
import {useDispatch, useSelector} from "react-redux";
import {clientCommandDisplaySelector} from "../../store/Client/CommandDisplay";
import {updateForm} from "../../store/Client/Analyser";
import {useHistory} from "react-router-dom";
import Container from "reactstrap/lib/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//TODO check if i have to add url field


export default function CommandeDisplay() {

    let {command, isLoading, isError} = useSelector(clientCommandDisplaySelector);

    const dispatch = useDispatch();
    const history = useHistory();
    const [titre, setTitre] = useState('');
    const [consultant, setConsultant] = useState(null);
    const [langue, setLangue] = useState('');
    const [nbrText, setNbrText] = useState('');
    const [deadline, setDeadline] = useState(null);
    const [briefDetaille, setBriefDetaille] = useState('');
    const [documentationClient, setDocumentationClient] = useState('');
    const [gabSemantique, setGabSemantique] = useState('');
    const [nbrTextMin, setNbrTextMin] = useState('');
    const [nbrTextMax, setNbrTextMax] = useState('');
    const [tarif, setTarif] = useState('');

    const [listText, setListText] = useState([])
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            setTitre(command.titre);
            setLangue(command.langue);
            setConsultant(command.idConsultantNavigation);
            setNbrText(command.nbrText);
            setDeadline(command.deadline && getDateFormatInput(command.deadline));
            setBriefDetaille(command.briefDetaille);
            setDocumentationClient(command.documentationClient);
            setGabSemantique(command.gabSemantique);
            setNbrTextMin(command.nbrTextMin);
            setNbrTextMax(command.nbrTextMax);
            setTarif(command.tarif);
            setListText(command.commandeRedacteurRelation.map(x => x.text).reduce((accum, el) => accum.concat(el), []).filter(x => x.delivered));
            setExpanded(command.commandeRedacteurRelation.map(x => x.text).reduce((accum, el) => accum.concat(el), []).length > 0);
        }

        document.title = "Typix - Détails de la commande";
    }, [command, isLoading])

    const formatDate = (date) => {
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const ifDisabled = (item) => {
        if (!item)
            return false;
        const deadline = new Date(item);
        const date2 = new Date();
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc2 = Date.UTC(deadline.getFullYear(), deadline.getMonth(), deadline.getDate());
        const utc1 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const result = Math.floor((utc2 - utc1) / _MS_PER_DAY);

        return (result && result < 0)

    }
    if (isLoading) {
        return (
            <Container>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </Container>
        )
    }
    if (isError) {
        history.push('/Client/MainMenu')
    }
    return (
        <Container>
            <Jumbotron>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                       onClick={() => history.goBack()}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h5'}>Détails de la commande </Typography>
                    </Grid>
                    <Grid item container xs={6} spacing={6}>
                        <Grid item xs={12}>
                            {
                                command.websiteNavigation.clientWebsiteRelation.length > 0 &&
                                <TextField id="select" label={"Mail des clients"}
                                           defaultValue={command.websiteNavigation.clientWebsiteRelation.map(x => x.clientNavigation.mail)[0]}
                                           select>
                                    {
                                        command.websiteNavigation.clientWebsiteRelation.map(x => x.clientNavigation.mail)
                                            .map((x, key) => <MenuItem key={key} value={x}>{x}</MenuItem>)
                                    }
                                </TextField>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Titre de la commande"}
                                value={titre}
                                fullWidth
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        {
                            command.websiteNavigation.consultantNavigation
                            &&

                            <Grid item xs={6}><TextField
                                label={"Mail du Consultant"}
                                value={command.websiteNavigation.consultantNavigation ? command.websiteNavigation.consultantNavigation.mail : 'Non spécifié'}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                label={"Langue"}
                                value={langue}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={"Nombre de textes"}
                                value={nbrText}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {
                                command.commandeRedacteurRelation.length > 0 &&
                                <FormControl>
                                    <InputLabel shrink>Nombre de mots par texte</InputLabel>
                                    <Input type={"number"}
                                           value={Math.min.apply(Math, command.commandeRedacteurRelation.map(x => x.nbrMotsMin))}
                                           placeholder={"Nombre minimal"} style={{marginTop: 30}}
                                           readOnly={true}
                                           endAdornment={<InputAdornment position="end">Min</InputAdornment>}/>
                                    <Input type={"number"}
                                           value={Math.max.apply(Math, command.commandeRedacteurRelation.map(x => x.nbrMotsMax))}
                                           placeholder={"Nombre maximum"}
                                           readOnly={true}
                                           endAdornment={<InputAdornment position="end">Max</InputAdornment>}

                                    />
                                </FormControl>
                            }

                        </Grid>
                        <Grid item xs={6}>
                            {
                                command.commandeRedacteurRelation.length > 0 && <TextField
                                    label={"Deadline"}
                                    contentEditable={false}
                                    suppressContentEditableWarning={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type={"date"}
                                    value={formatDate(new Date(Math.max.apply(Math, command.commandeRedacteurRelation.filter(x => x.deadline).map(x => new Date(x.deadline)))))}
                                />
                            }

                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label={"Bref détaillée"}
                                placeholder={"Lien drive.."}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                                value={briefDetaille}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={"Documentation client"}
                                placeholder={"Lien drive.."}
                                value={documentationClient}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <InputLabel shrink>Gap sémantique de la commande</InputLabel>
                                <Input type={"text"} value={gabSemantique} style={{marginTop: 25}}
                                       placeholder={"Lien drive.."}
                                       readOnly={false}/>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={6}>

                            <Grid item xs={6}>
                                <DeliveredTexts
                                    deliveredText={command.commandeRedacteurRelation.map(x => x.text)
                                        .reduce((accum, el) => accum.concat(el), []).filter(x => x.delivered).length}
                                    text
                                    total={parseInt(command.nbrText)}/>
                            </Grid>

                            <Grid item xs={6}>
                                <ValidatedTexts
                                    validatedText={command.commandeRedacteurRelation.map(x => x.text)
                                        .reduce((accum, el) => accum.concat(el), []).filter(x => x.delivered && x.validateAt).length}
                                    text
                                    total={parseInt(command.nbrText)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid item xs={12}>
                                            {
                                                !(listText.length > 0) ?
                                                    <Typography align={'center'}>Contenu indisponible</Typography> :
                                                    <Typography align={'center'}>Contenu disponible</Typography>

                                            }
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid container xs={12} spacing={1}>
                                                {
                                                    listText.length > 0 &&
                                                    listText.map((item, key) => {
                                                        const keywords = item.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n");
                                                        const convertedText = setKeyWordList(keywords);
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <Grid item xs={5}>
                                                                    <Typography
                                                                        className={item.validateAt ? 'text-success' : 'text'}>Texte {key + 1} : {item.motCle}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FetchDataForPdq text={item.text1}
                                                                                     list={convertedText}
                                                                                     langue={'FRENCH'}
                                                                                     keywords={keywords}
                                                                                     htmlText={item.htmlText}
                                                                                     result={item.result}
                                                                                     nomText={item.nomText}/>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Button variant="contained" color="primary"
                                                                            style={{marginLeft: 25}} onClick={() => {
                                                                        dispatch(updateForm({
                                                                            data: item,
                                                                            idCommande: command.id,
                                                                            revisionDisabled: ifDisabled(command.finishedAt),
                                                                            idCommandeRedacteurRelation: item.commandeRedacteurRelation,
                                                                            finished: command.finished
                                                                        }));
                                                                        history.push('/Client/TextDetails');
                                                                    }}>
                                                                        Voir Texte
                                                                    </Button>
                                                                </Grid>
                                                            </React.Fragment>)
                                                    })
                                                }

                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <DownloadAll disabled={!listText.length > 0}
                                                             data={listText}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Jumbotron>


        </Container>
    );
}
