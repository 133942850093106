import {createSlice} from '@reduxjs/toolkit'
import {fetchAdminCommandDisplay} from "./features";


export const adminCommandDisplay = createSlice({
    name: 'adminCommandDisplay',
    initialState: {
        command: null,
        lineData: [],
        nbrRemaining: 0,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateData: (state, {payload}) => {
            let value = state.lineData;
            value[payload.key][payload.column] = payload.data;
            state.lineData = value;
            if (payload.column === "nbrText") {
                state.nbrRemaining = state.command.nbrText - state.lineData
                    .filter(x => x.idRedacteur)
                    .map(x => x.nbrText)
                    .reduce((prev, next) => prev + next,0);
            }
        },
        updateLineDataTable: (state, {payload}) => {
            state.nbrRemaining = payload.nbrText - payload.commandeRedacteurRelation
                .map(x => x.nbrText)
                .reduce((prev, next) => prev + next,0);
            state.lineData = payload.commandeRedacteurRelation;
            state.command = payload;
        }, addLineDataTable: (state, {payload}) => {
            state.lineData.push({
                idRedacteur: null,
                idRedacteurNavigation : null,
                tarif: null,
                nbrText: 0,
                nbrMotsMax: 0,
                nbrMotsMin: 0,
                nbrMotsManaged: 0,
                actualBilling: false,
                text : []
            });
        }, removeLineDataTable: (state, {payload}) => {
            state.lineData.splice(payload, 1);
            state.nbrRemaining = state.command.nbrText - state.lineData
                .filter(x => x.idRedacteur)
                .map(x => x.nbrText)
                .reduce((prev, next) => prev + next,0);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAdminCommandDisplay.fulfilled, (state, {payload}) => {
            state.command = payload;
            state.lineData = payload.commandeRedacteurRelation;
            state.nbrRemaining = payload.nbrText - payload.commandeRedacteurRelation
                .filter(x => x.idRedacteur)
                .map(x => x.nbrText)
                .reduce((prev, next) => prev + next,0);
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchAdminCommandDisplay.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchAdminCommandDisplay.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminCommandDisplay.reducer


export const adminCommandDisplaySelector = (state) => state.adminCommandDisplay;


export const {updateData, updateLineDataTable, addLineDataTable, removeLineDataTable} = adminCommandDisplay.actions
