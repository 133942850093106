import {createSlice} from '@reduxjs/toolkit'
import {fetchWebsiteDisplay} from "./features";


export const adminWebsiteDetails = createSlice({
    name: 'adminWebsiteDetails',
    initialState: {
        formData: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWebsiteDisplay.fulfilled, (state, {payload}) => {
            state.formData = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchWebsiteDisplay.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchWebsiteDisplay.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminWebsiteDetails.reducer


export const adminWebsiteDetailsSelector = (state) => state.adminWebsiteDetails;
