import {createSlice} from '@reduxjs/toolkit'
import {fetchClientList} from "./features";


export const consultantClientList = createSlice({
    name: 'consultantClientList',
    initialState: {
        myClients: null,
        allClients: null,
        isLoadingClients: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClientList.fulfilled, (state, {payload}) => {
            state.myClients = payload.myClients;
            state.allClients = payload.allClients;
            state.isLoadingClients = false;
            state.isError = false;
        }).addCase(fetchClientList.pending, (state, {payload}) => {
            state.isLoadingClients = true;
            state.isError = false;
        }).addCase(fetchClientList.rejected, (state, {payload}) => {
            state.isLoadingClients = false;
            state.isError = true;
        })
    }
})


export default consultantClientList.reducer


export const consultantClientListSelector = (state) => state.consultantClientList;
