import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {MDBDataTable} from "mdbreact";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import {Container, Row} from "reactstrap";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export default function HistoriqueIntervention(props) {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (props.id && props.visible) {
            axios.get( "/Admin/GetHistoriqueIntervention?id=" + props.id).then(res => {
                const columns = [
                    {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Texte</div>,
                        field: 'text',
                        sort: 'asc',
                        width: 250,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date d'ajout</div>,
                        field: 'date_ajout',
                        sort: 'asc',
                        width: 250,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Edité par le client</div>,
                        field: 'edite',
                        sort: 'asc',
                        width: 800,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Demande de révision</div>,
                        field: 'demande_revision',
                        sort: 'asc',
                        width: 800,
                    }, {
                        label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Validé</div>,
                        field: 'validate',
                        sort: 'asc',
                        width: 250,
                    }, {
                        label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Date de validation</div>,
                        field: 'date_validate',
                        sort: 'asc',
                        width: 800,
                    }
                ];
                let rows = [];
                res.data.map(item => rows.push({
                    text: item.nomText,
                    date_ajout: formatDate(item.createdAt),
                    edite: item.edited ? "Oui" : "Non",
                    demande_revision: item.revisionSent ? <div style={{align: 'center'}}>Oui <LightTooltip  title={
                        <Container>
                            <Row>
                                Date : {formatDate(item.revisionDate)}
                            </Row>
                            <Row>
                                Mail envoyé :
                                <p style={{
                                    wordBreak: "break-all",
                                    whiteSpace: "normal"
                                }}>{item.revisionText}</p>
                            </Row>
                        </Container>}>
                        <MailIcon/>
                    </LightTooltip></div> : "Non",
                    validate: item.validateAt ? "Oui" : "Non",
                    date_validate: item.validateAt ? formatDate(item.validateAt) : "-",
                }));

                setList({columns, rows});
            })
        }
    }, [props]);


    const formatDate = (date) => {
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
    if (!list) {
        return null
    }

    return (
        <Dialog open={props.visible} onClose={props.Close} fullWidth maxWidth={'lg'}>
            <DialogTitle disableTypography style={{minWidth: 800}}>
                <div style={{display: 'flex'}}>
                    <Typography style={{width: '80%'}} variant="h6">HISTORIQUE D'INTERVENTION</Typography>
                    <IconButton onClick={props.Close} style={{display: 'contents', justifyContent: 'flex-end'}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{minWidth: 800}}>
                <h3>{"Commande : " + props.titre}</h3>
                <MDBDataTable striped hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4}
                              noBottomColumns
                              infoLabel={['', '-', 'sur', 'résultats']}
                              sortable
                              paging={list && list.rows && list.rows.length > 0}
                              paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                              searchLabel={'Recherche'}
                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                              info={true}
                              entriesLabel={'Afficher les résultats'}
                              data={list}/>
            </DialogContent>
        </Dialog>
    )
}
