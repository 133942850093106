import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import axios from "axios";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Modal, Jumbotron, ModalHeader, ModalFooter, Container} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalBody from "reactstrap/es/ModalBody";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import CommandeClient from "./CommandeClient";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import {consultantClientsDetailsSelector} from "../../store/Consultant/ClientsDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";


export default function AddClient() {

    let {clientForm, isLoading, isError} = useSelector(consultantClientsDetailsSelector);

    const history = useHistory();

    const [detailPrestationContenu, setDetailPrestationContenu] = useState('')
    const [clientName, setClientName] = useState('')
    const [mail, setMail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [nbrCocon, setNbrCocon] = useState('')
    const [nbrTextCocon, setNbrTextCocon] = useState('')
    const [nbrFaq, setNbrFaq] = useState('')
    const [nbrGmb, setNbrGmb] = useState('')
    const [nbrTextOpt, setNbrTextOpt] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const [selectedWebsite, setSelectedWebsite] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedNavigation, setSelectedNavigation] = useState(null);
    const [website, setWebsite] = useState([])
    const [showCommande, setShowCommande] = useState(false);
    const [mailExist, setMailExist] = useState(false);
    const toggleSuccess = () => setSuccess(!success);
    const toggleError = () => setError(!error);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, [])

    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || clientName.length === 0
            || telephone.length === 0 || nbrCocon.length === 0 || nbrTextCocon.length === 0 || nbrFaq.length === 0
            || nbrGmb.length === 0 || setNbrTextOpt.length === 0) {
            return true
        }
        if (loading) {
            return true
        }
        return (!mail.match(emailcheck) && mail.length > 0);


    }
    useEffect(() => {
        if (clientForm) {
            try {
                setReadOnly(true)
                setSelectedNavigation(clientForm[0])
                setSelectedClient(clientForm[0].clientNavigation)
                setMail(clientForm[0].clientNavigation.mail);
                setClientName(clientForm[0].clientNavigation.nomPrenom);
                setTelephone(clientForm[0].clientNavigation.numTelephone);
                const websiteList = clientForm.map(x => x.websiteNavigation);
                setWebsite(websiteList)
                setNbrTextOpt(websiteList[0].nbrTextOptimiser);
                setNbrTextCocon(websiteList[0].nbrTextCocons);
                setNbrCocon(websiteList[0].nbrCocons);
                setNbrFaq(websiteList[0].nbrFaq);
                setNbrGmb(websiteList[0].nbrGmb);
                setDetailPrestationContenu(websiteList[0].detailPrestation);
                setSelectedWebsite(websiteList[0])
            } catch {
                history.push('/Consultant/MainMenu')
            }

        }
        document.title = "Typix - Fiche client";
    }, [isLoading])
    const AddClient = () => {
        setLoading(true)
        axios.post("/Consultant/UpdateClient", {
            id: selectedNavigation.id,
            clientNavigation: {
                id: selectedClient.id,
                mail: mail,
                motDePasse: selectedClient.motDePasse,
                nomPrenom: clientName,
                numTelephone: telephone,
                compte: selectedClient.compte,
            },
            websiteNavigation: {
                id: selectedWebsite.id,
                urlSite: selectedWebsite.urlSite,
                nbrCocons: nbrCocon,
                nbrTextCocons: nbrTextCocon,
                nbrFaq: nbrFaq,
                consultant: selectedWebsite.consultant,
                nbrGmb: nbrGmb,
                nbrTextOptimiser: nbrTextOpt,
                detailPrestation: detailPrestationContenu
            }
        }).then(() => {
            setLoading(false);
            setSuccess(true)
        }).catch(e => {
            setLoading(false);
            setError(true)
        })

    }
    if (isLoading) {
        return (
            <Container>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </Container>
        )
    }

    if (isError) {
        history.push('/Consultant/MainMenu')
    }

    return (
        <Container>
            <Jumbotron>
                <CommandeClient visible={showCommande}
                                Close={() => setShowCommande(false)} id={selectedNavigation && selectedNavigation.id}/>

                <div style={{margin: 25}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ArrowBackIcon color={"primary"}
                                           style={{fontSize: 40, cursor: 'pointer'}}
                                           onClick={() => history.goBack()}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'h5'}>Fiche client</Typography>
                        </Grid>

                        {
                            clientForm && <Grid item xs={6}>
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button onClick={() => setShowCommande(true)} variant="contained" color={'primary'}
                                            style={{marginBottom: 25}}>Voir les commandes</Button>
                                </div>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField id="select" label="Site" InputLabelProps={{shrink : true}}
                                       value={selectedNavigation}
                                       onChange={e => {
                                           const website = e.target.value.websiteNavigation;
                                           const client = e.target.value.clientNavigation;
                                           setNbrTextCocon(website.nbrTextCocons);
                                           setNbrCocon(website.nbrTextCocons);
                                           setNbrFaq(website.nbrFaq);
                                           setNbrGmb(website.nbrGmb);
                                           setNbrTextOpt(website.nbrTextOptimiser);
                                           setDetailPrestationContenu(website.detailPrestation);
                                           setSelectedWebsite(website);
                                           setMail(client.mail);
                                           setClientName(client.nomPrenom);
                                           setTelephone(client.numTelephone);
                                           setSelectedClient(client);
                                           setSelectedNavigation(e.target.value)
                                       }}
                                       select>
                                {
                                    clientForm.map((x, key) => <MenuItem key={key}
                                                                         value={x}>{x.clientNavigation.mail + " > " +x.websiteNavigation.urlSite}</MenuItem>)
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="compte-client-nom-prenom"
                                freeSolo={!readOnly}
                                disableClearable
                                value={mail}
                                options={clientForm.map(x => x.clientNavigation)}
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                helperText={mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}
                                getOptionLabel={(option) => {
                                    if (option.hasOwnProperty('mail')) {
                                        return option.mail;
                                    }
                                    return option;
                                }}
                                onInputChange={(event, value) => {
                                    console.log("InputValueAuto : ",value)
                                    setMail(value)
                                }}
                                onChange={(event, value) => {
                                    console.log("ValueAuto : ",value)
                                    if(!value || value.length === 0){return;}
                                    if (typeof value === 'string') {
                                        setMail(value)
                                    }else{
                                        if (clientForm.some(x => x.clientNavigation.id === value.id)) {
                                            const form = clientForm.find(x => x.clientNavigation.id === value.id);
                                            const website = form.websiteNavigation;
                                            const client = form.clientNavigation;
                                            setNbrTextCocon(website.nbrTextCocons);
                                            setNbrCocon(website.nbrTextCocons);
                                            setNbrFaq(website.nbrFaq);
                                            setNbrGmb(website.nbrGmb);
                                            setNbrTextOpt(website.nbrTextOptimiser);
                                            setDetailPrestationContenu(website.detailPrestation);
                                            setSelectedWebsite(website);
                                            setMail(client.mail);
                                            setClientName(client.nomPrenom);
                                            setTelephone(client.numTelephone);
                                            setSelectedClient(client);
                                            setSelectedNavigation(form)
                                        }
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Mail"}
                                        margin="normal"
                                        style={{width: 300}}
                                        InputProps={{...params.InputProps, type: 'search'}}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={"Compte client"}
                                value={selectedClient && selectedClient.compte}
                                InputLabelProps={{shrink : true}}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                                style={{width: 300}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nom et prénom"}
                                value={clientName}
                                InputProps={{
                                    readOnly: readOnly
                                }}
                                style={{width: 300}}
                                onChange={e => setClientName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Téléphone"}
                                type={"number"}
                                style={{width: 300}}
                                value={telephone}
                                InputProps={{
                                    readOnly: readOnly
                                }}
                                onChange={e => setTelephone(e.target.value)}
                            />
                        </Grid>
                        {
                            website.length > 0 &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre de cocons"}
                                        value={nbrCocon}
                                        type={'number'}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        style={{width: 300}}
                                        onChange={e => {
                                            setNbrCocon(e.target.value.replace(/[^0-9]/g, ''))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre de texte par cocon"}
                                        value={nbrTextCocon}
                                        type={'number'}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        style={{width: 300}}
                                        onChange={e => {
                                            setNbrTextCocon(e.target.value.replace(/[^0-9]/g, ''))

                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre de FAQ"}
                                        value={nbrFaq}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        style={{width: 300}}
                                        type={'number'}
                                        onChange={e => {
                                            setNbrFaq(e.target.value.replace(/[^0-9]/g, ''))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre de GMB"}
                                        style={{width: 300}}
                                        type={'number'}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        value={nbrGmb}
                                        onChange={e => {
                                            setNbrGmb(e.target.value.replace(/\D/g, ''))
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre de textes à optimiser"}
                                        type={'number'}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        style={{width: 300}}
                                        value={nbrTextOpt}
                                        onChange={e => setNbrTextOpt(e.target.value)}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Détail de la prestation du contenu"}
                                        InputProps={{
                                            readOnly: readOnly
                                        }}
                                        style={{width: 300}}
                                        value={detailPrestationContenu}
                                        onChange={e => setDetailPrestationContenu(e.target.value)}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            readOnly ? <Button variant="contained" color="secondary"
                                               style={{margin: 10}}
                                               onClick={() => setReadOnly(false)}>Modifier la fiche</Button> :
                                loading ? <CircularProgress/> :
                                    <Button variant="contained" color="primary" disabled={CheckValidForm()}
                                            style={{margin: 10}}
                                            onClick={AddClient}>Sauvegarder</Button>
                        }
                    </Grid>
                </div>
                <Modal isOpen={success} toggle={toggleSuccess}>
                    <ModalHeader toggle={toggleSuccess}>Client modifiée avec succès </ModalHeader>
                    <ModalBody>
                        <img src={'/Images/succes.png'} height={"100%"} width={"100%"}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            setReadOnly(true)
                            toggleSuccess();
                        }}>Fermer</Button>{' '}
                        <Button color="secondary" onClick={() => {
                            toggleSuccess()
                            history.goBack()
                        }}>Revenir a l'accueil</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={error} toggle={toggleError}>
                    <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/error.png'} height={"100%"} width={"100%"}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                    </ModalFooter>
                </Modal>
            </Jumbotron>


        </Container>
    )
}
