import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import HelpIcon from '@material-ui/icons/Help';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Select, MenuItem, Grid} from '@material-ui/core';
import '../../../node_modules/react-quill/dist/quill.snow.css'
import './Analyzer.css'
import ReactQuill, {Quill} from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {setKeyWordList} from "../../helpers/PdfFunction";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';

import axios from "axios";
import {withStyles} from '@material-ui/core/styles';
import {Link, useHistory} from "react-router-dom";
import {NumberOfWords} from "../../helpers/Constants";
import {useDispatch, useSelector} from "react-redux";
import {clientAnalyzerSelector} from "../../store/Client/Analyser";
import {fetchClientCommandDisplay} from "../../store/Client/CommandDisplay/features";

import Delta from 'quill-delta'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {fetchClientNavCounter} from "../../store/Client/NavCounter/features";
import {UserSessionSelector} from "../../store/UserSession";


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


const modules = {
    toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ]
}


export default function Analyzer() {

    let {formData, idCommande, revisionDisabled,idCommandeRedacteurRelation} = useSelector(clientAnalyzerSelector);
    let {user} = useSelector(UserSessionSelector);
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const [showError, setShowError] = useState(false);
    const [edited, setEdited] = useState(null);
    const [mailSuccess, setMailSuccess] = useState(false);
    const [valideSuccess, setValideSuccess] = useState(false);
    const [textMail, setTextMail] = useState("");
    const [popoverMail, setPopoverMail] = useState(false);

    const togglePopoverMail = () => setPopoverMail(!popoverMail);
    const [mailError, setMailError] = useState(false);
    const [showMailForm, setShowMailForm] = useState(false);
    const toggleMail = () => setShowMailForm(!showMailForm);
    const [keywords, setKeywords] = useState('');
    const [text, setText] = useState("");
    const [language, setLanguage] = useState('FRENCH');
    const [openLanguage, setOpenLanguage] = useState(false);
    const [result, setResult] = useState('')
    const toggle = () => setPopoverOpen(!popoverOpen);
    const [color, setColor] = useState('black');
    const [convertedText, setConvertedText] = useState({});
    const [ready, setReady] = useState(false);
    const [pdfReady, setPdfShow] = useState(false)

    const [showDifference, setShowDifference] = useState(false);

    const [nbrText, setNbrText] = useState(0);

    const [initialHtmlText, setInitialHtmlText] = useState(null)
    const [initialText, setInitialText] = useState(null);

    const [content, setContent] = useState("");
    const [initialContent, setInitialContent] = useState("");


    const [validateAt, setValidateAt] = useState(null);
    const [revisionSent, setRevisionSent] = useState(null);


    const [activeTab, setActiveTab] = useState('1');

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [success, setSuccess] = useState(false);
    const [pdfTitle, setPdfTitle] = useState('');
    const [htmlText, setHtmlText] = useState('')
    const [baliseMeta, setBaliseMeta] = useState('')
    const [nomText, setNomText] = useState('')
    const [motCle, setMotCle] = useState('')
    const [url, setUrl] = useState('')
    const [baliseTitle, setBaliseTitle] = useState('');
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = () => setEditMode(!editMode);
    useEffect(() => {
        try {
            setText(formData.text1);
            setInitialText(formData.initialText);
            setContent(formData.content);
            setInitialContent(formData.initialContent);
            setValidateAt(formData.validateAt);
            setRevisionSent(formData.revisionSent);
            setEdited(formData.edited);
            setNbrText(formData.text1.length);
            setMotCle(formData.motCle);
            setHtmlText(formData.htmlText && JSON.parse(formData.htmlText));
            setInitialHtmlText(formData.initialHtmlText && JSON.parse(formData.initialHtmlText));
            setUrl(formData.url);
            setBaliseMeta(formData.baliseMetaDescription);
            setResult(formData.result && JSON.parse(formData.result));

            let resultForColor = formData.result && JSON.parse(formData.result);
            if (resultForColor) {
                if (resultForColor.semanticEnrichment <= 29) {
                    setColor('#FF595D');
                }
                if (resultForColor.semanticEnrichment <= 69 && 29 < resultForColor.semanticEnrichment) {
                    setColor('#FFBE64');
                }
                if (resultForColor.semanticEnrichment > 69) {
                    setColor('#3ED774');
                }
            }
            setBaliseTitle(formData.baliseTitle);
            setNomText(formData.nomText);
            setKeywords(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n"));
            setConvertedText(setKeyWordList(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n")));
            setKeywords(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n"));
            setConvertedText(setKeyWordList(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n")));

            document.title = "Typix - Détails du texte";
        } catch {
            history.push("/Client/MainMenu")
        }
    }, [formData])

    const SendEmail = () => {
        toggleMail()
        axios.post("/Client/SendEmail", {
            idCommande: idCommande,
            titreText: nomText,
            idText: formData.id,
            text: textMail
        }).then(() => {
            dispatch(fetchClientCommandDisplay(idCommande))
            setMailSuccess(true);
            setRevisionSent(true);
        }).catch(() => setMailError(true))
    }


    const Update = () => {
        if (keywords === '' || text === '') {
            setShowError(true)
        } else {
            const list = setKeyWordList(keywords);
            axios.post("/Client/UpdateTextCommande", {
                id: formData.id,
                text1: text,
                motCle: motCle,
                htmlText: htmlText && JSON.stringify(htmlText),
                url: url,
                baliseTitle: baliseTitle,
                nomText: nomText,
                baliseMetaDescription: baliseMeta,
                idCommande: idCommande,
                edited: edited,
                delivered: formData.delivered,
                activeInitialText : formData.activeInitialText,
                commandeRedacteurRelation: idCommandeRedacteurRelation,
                validateAt: validateAt,
                revisionSent: revisionSent,
                revisionDate: formData.revisionDate,
                revisionText: formData.revisionText,
                revisionDone: formData.revisionDone,
                createdAt: formData.createdAt,
                result: result && JSON.stringify(result),
                gapSemantique: Object.entries(list).map(([key, value]) => ({mot: key, nbrColumn: value})),
                initialText: formData.initialText,
                initialHtmlText: formData.initialHtmlText,
                content: content,
                initialContent: initialContent,
            }).then(res => {
                dispatch(fetchClientCommandDisplay(idCommande))
                setEditMode(false);
                setEdited(true);
                setSuccess(true);
            }).catch(() => setShowError(true))
        }
    }

    function Valider() {
        axios.get("/Client/ValiderCommande?id=" + formData.id + "&idCommande=" + idCommande).then((res) => {
            dispatch(fetchClientCommandDisplay(idCommande));
            dispatch(fetchClientNavCounter(user.id));
            setValideSuccess(true);
            setValidateAt(res.data);
        }).catch(() => setMailError(true))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                   onClick={() => history.goBack()}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography variant={'h5'} style={{marginBottom: 20}}>Détails du texte</Typography>
                </Col>
            </Row>
            {
                showError ?
                    <Modal isOpen={showError} toggle={() => {
                        setShowError(false);
                    }}>
                        <ModalHeader toggle={() => {
                            setShowError(false);
                        }}>Problème</ModalHeader>
                        <ModalBody>
                            Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard. Si vous ne
                            pouvez toujours pas exécuter
                            votre analyse, veuillez contacter support@pixalione.com.
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                setShowError(false);
                            }}>Ok</Button>
                        </ModalFooter>
                    </Modal> : null
            }

            <Modal isOpen={mailError} toggle={() => {
                setMailError(false);
            }}>
                <ModalHeader toggle={() => {
                    setMailError(false);
                }}>Problème</ModalHeader>
                <ModalBody>
                    Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard. Si vous ne pouvez
                    toujours pas envoyer votre demande de révision, veuillez contacter
                    <Link
                        to='#'
                        onClick={(e) => {
                            window.location = 'mailto:support@pixalione.com';
                            e.preventDefault();
                        }}
                    >
                        support@pixalione.com
                    </Link>.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setMailError(false);
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={valideSuccess} toggle={() => {
                setValideSuccess(false);
            }}>
                <ModalHeader toggle={() => {
                    setValideSuccess(false);
                }}>Succès</ModalHeader>
                <ModalBody>
                    Le texte a bien été validé
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setValideSuccess(false);
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={success} toggle={() => {
                setSuccess(false);
            }}>
                <ModalHeader toggle={() => {
                    setSuccess(false);
                }}>Succès</ModalHeader>
                <ModalBody>
                    Modification effectuée avec succès
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setSuccess(false);
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Détail du formulaire</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nom du texte"}
                                value={nomText}
                                fullWidth
                                InputProps={{
                                    readOnly: !editMode
                                }}
                                color={"primary"}
                                onChange={e => setNomText(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Mot clé"}
                                value={motCle}
                                fullWidth
                                InputProps={{
                                    readOnly: !editMode
                                }}
                                onChange={e => setMotCle(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Url"}
                                fullWidth
                                value={url}
                                InputProps={{
                                    readOnly: !editMode
                                }}
                                onChange={e => setUrl(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Balise titre"}
                                value={baliseTitle}
                                fullWidth
                                InputProps={{
                                    readOnly: !editMode
                                }}
                                onChange={e => setBaliseTitle(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Balise meta-description"}
                                value={baliseMeta}
                                fullWidth
                                InputProps={{
                                    readOnly: !editMode
                                }}
                                onChange={e => setBaliseMeta(e.target.value)}
                            />
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <span style={{margin: 30}}/>
            <Row>
                <Col xs={'6'}>
                    <Row>
                        <h4>Mettez des mots-clés avec fréquence : </h4> <HelpIcon id="Popover"/>
                        <Popover placement="bottom" isOpen={popoverOpen} target="Popover" toggle={toggle}>
                            <PopoverHeader>Instruction</PopoverHeader>
                            <PopoverBody>
                                <Container>
                                    <Row>

                                        Format souhaité :
                                        - mot-clé (fréquence)
                                        Séparez les mots-clés par un " Enter ".


                                    </Row>
                                    <Row>
                                        ---------------------------------
                                    </Row>
                                    <Row>
                                        Exemple :
                                    </Row>
                                    <Row>
                                        mot-clé1 (2)
                                    </Row>
                                    <Row>
                                        mot-clé2 (1)
                                    </Row>
                                    <Row>
                                        mot-clé3 (1)
                                    </Row>
                                    <Row>
                                        mot-clé4 (2)
                                    </Row>
                                </Container>
                            </PopoverBody>
                        </Popover>

                    </Row>

                    <TextField
                        value={keywords}
                        rowsMax="10"
                        onChange={(e) => setKeywords(e.target.value)}
                        inputProps={{
                            style: {
                                display: 'flex',
                                overflow: 'auto'

                            }
                        }}
                        InputProps={{
                            readOnly: !editMode
                        }}
                        placeholder={'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'}
                        className={classes.form}
                        variant={'outlined'}
                        multiline
                        margin={'dense'}
                    />

                    <h4>Mettez le texte :</h4>
                    <div style={{marginBottom: 50}}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    onClick={() => {
                                        toggleTab('1');
                                    }}
                                >
                                    Texte
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={() => {
                                        toggleTab('2');
                                    }}
                                >
                                    Html
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <ReactQuill
                                    id={"new"}
                                    modules={modules}
                                    theme={'snow'}
                                    readOnly={!editMode}
                                    value={content}
                                    preserveWhitespace={true}
                                    style={{
                                        height: 250
                                    }}
                                    onChange={(content, delta, source, editor) => {
                                        setContent(content);
                                        setTimeout(() => {
                                            setHtmlText(editor.getContents());
                                            let textValue = editor.getText().toString();
                                            setText(textValue);
                                            setNbrText(textValue.length);
                                        }, 100);
                                    }}/>


                            </TabPane>
                            <TabPane tabId="2">
                                    <textarea
                                        value={htmlText}
                                        readOnly
                                        style={{
                                            width: "100%",
                                            margin: 0,
                                            background: "rgb(29, 29, 29)",
                                            boxSizing: "border-box",
                                            color: "rgb(204, 204, 204)",
                                            outline: "none",
                                            padding: "12px 15px",
                                            fontFamily: "Consolas, Menlo, Monaco, Courier New, monospace",
                                            height: 250
                                        }}
                                    />
                            </TabPane>
                        </TabContent>
                    </div>
                    <Row>
                        <Col>
                            <h4 style={{margin: 10}}>Langue : </h4>
                        </Col>
                        <Col>
                            <Select
                                labelId="language-label"
                                id="language-select"
                                inputProps={{
                                    style: {
                                        margin: 15
                                    }
                                }}
                                open={openLanguage}
                                onClose={() => setOpenLanguage(false)}
                                onOpen={() => setOpenLanguage(true)}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <MenuItem value={'GERMAN'}>ALLEMAND</MenuItem>
                                <MenuItem value={'ENGLISH'}>ANGLAIS</MenuItem>
                                <MenuItem value={'BULGARIAN'}>BULGARIE</MenuItem>
                                <MenuItem value={'KOREAN'}>CORÉEN</MenuItem>
                                <MenuItem value={'SPANISH'}>ESPAGNOL</MenuItem>
                                <MenuItem value={'ESTONIAN'}>ESTONIEN</MenuItem>
                                <MenuItem value={'FINNISH'}>FINNISH</MenuItem>
                                <MenuItem value={'FRENCH'}>FRANÇAIS</MenuItem>
                                <MenuItem value={'GREEK'}>GREC</MenuItem>
                                <MenuItem value={'ITALIAN'}>ITALIEN</MenuItem>
                                <MenuItem value={'DUTCH'}>NÉERLANDAIS</MenuItem>
                                <MenuItem value={'POLISH'}>POLISH</MenuItem>
                                <MenuItem value={'PORTUGUESE'}>PORTUGAIS</MenuItem>
                                <MenuItem value={'ROMANIAN'}>ROMAIN</MenuItem>
                                <MenuItem value={'RUSSIAN'}>RUSSIE</MenuItem>
                                <MenuItem value={'CZECH'}>TCHÈQUE</MenuItem>
                            </Select>
                        </Col>
                        <Col>
                            <Button color={"primary"} variant={"contained"} style={{
                                marginBottom: 15,
                                width: 150
                            }}
                                    onClick={() => {
                                        try {
                                            setShowDifference(true)
                                            setTimeout(() => {
                                                try {
                                                    let quill_diff = new Quill("#diff");
                                                    const iht = new Delta(JSON.parse(formData.initialHtmlText));
                                                    const ht = new Delta(JSON.parse(formData.htmlText));
                                                    let diff = iht.diff(ht);
                                                    for (let i = 0; i < diff.ops.length; i++) {
                                                        let op = diff.ops[i];
                                                        if (op.hasOwnProperty('insert')) {
                                                            op.attributes = {
                                                                background: "#cce8cc",
                                                                color: "#003700"
                                                            };
                                                        }
                                                        if (op.hasOwnProperty('delete')) {
                                                            op.retain = op.delete;
                                                            delete op.delete;
                                                            op.attributes = {
                                                                background: "#e8cccc",
                                                                color: "#370000"
                                                            };
                                                        }
                                                    }
                                                    let adjusted = iht.compose(diff);
                                                    console.log({
                                                        adjusted,
                                                        iht,
                                                        ht,
                                                        formData
                                                    });
                                                    quill_diff.setContents(adjusted);
                                                } catch (e) {
                                                    console.log(e)
                                                    setShowDifference(false)
                                                }

                                            }, 100);
                                        } catch (e) {
                                            console.log(e)
                                            setShowDifference(false)
                                        }
                                    }}>Comparer</Button>
                        </Col>
                    </Row>
                </Col>


                <Col xs={'6'}>
                    <h3 style={{textAlign: 'center'}}>Résultat</h3>
                    <Col>
                        <b>Nombre de mots : {NumberOfWords(text)}</b>
                    </Col>
                    <div style={{
                        display: 'flex', borderRadius: 15, borderWidth: 1
                    }}>
                        {
                            result &&
                            <Col>
                                <b>Enrichissement sémantique: </b>  <h2
                                style={{color: color, marginLeft: 10}}>{result.semanticEnrichment.toFixed(2)}%</h2>
                                {
                                    (result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) ?
                                        <div>
                                            <b>Added keywords with remaining frequency: </b>
                                            <p>
                                                {Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {

                                                    return (
                                                        <span key={key}>{item}(<span
                                                            style={{textDecorationLine: 'line-through'}}>{convertedText[item]},</span>{result.greenWordsByFreq.greenWordsWithRemainingFreq[item]})<span>  </span></span>
                                                    )
                                                })}
                                            </p>
                                        </div> : null
                                }

                                {
                                    (result.remainingKeywords && Object.keys(result.remainingKeywords).length > 0) ?
                                        <div>
                                            <b>Mots clés restants: </b>
                                            <p>
                                                {Object.keys(result.remainingKeywords).map((item, key) => {
                                                    let word = item + '(' + result.remainingKeywords[item] + ') '
                                                    return (word)
                                                })}
                                            </p>
                                        </div> : null
                                }

                            </Col>
                        }


                    </div>
                    <Modal isOpen={pdfReady} toggle={() => setPdfShow(false)}>
                        <ModalHeader toggle={() => setPdfShow(false)}>Veuillez saisir le nom du mot-clé principal de
                            l'analyse à utiliser pour le nom du pdf: </ModalHeader>
                        <ModalBody>
                            <TextField
                                onChange={e => setPdfTitle(e.target.value)}
                            />

                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                const pdfr = pdfTitle + '-' + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear()
                                setPdfTitle(pdfr)
                                setPdfShow(false);
                                setReady(true)
                            }}>Ok</Button>
                        </ModalFooter>
                    </Modal>


                    <Modal isOpen={mailSuccess} toggle={() => setMailSuccess(false)}>
                        <ModalHeader toggle={() => setMailSuccess(false)}>Email envoyé avec succès !</ModalHeader>
                        <ModalBody>
                            <img src={'/Images/succes.png'} height={"100%"} width={"100%"}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                setMailSuccess(false)
                            }}>Fermer</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showMailForm} toggle={toggleMail} size={'lg'}>
                        <ModalHeader toggle={toggleMail}>
                            <h4>Merci d’écrire ci-dessous votre demande des modifications. Merci de respecter les règles
                                de la révision: <LightTooltip title={<Container>
                                    <Row>Dans le cadre d’une repasse sur des contenus, seuls les éléments suivants
                                        seront pris en charge :
                                    </Row>
                                    <Row>
                                        Si des phrases ne sont pas dans un français correct
                                    </Row>
                                    <Row>
                                        Si le contenu présente des inexactitudes
                                    </Row>
                                    <Row>
                                        Si le contenu ne respecte pas les règles du brief
                                    </Row>
                                </Container>}>
                                    <HelpIcon/>
                                </LightTooltip></h4>

                        </ModalHeader>
                        <ModalBody>
                            <TextField
                                label={"Message"}
                                value={textMail}
                                fullWidth
                                multiline
                                onChange={e => setTextMail(e.target.value)}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={SendEmail}>Envoyer</Button>
                            <Button color="primary" onClick={toggleMail}>Annuler</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showDifference} toggle={() => {
                        setShowDifference(false);
                    }}>
                        <ModalHeader toggle={() => {
                            setShowDifference(false);
                        }}>Différence</ModalHeader>
                        <ModalBody>
                            <ReactQuill id={"diff"}
                                        theme={'bubble'}
                                        readOnly/>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {
                                setShowDifference(false);
                            }}>Ok</Button>
                        </ModalFooter>
                    </Modal>


                    <Col>
                        {
                            (!revisionSent && !revisionDisabled && !validateAt) &&
                            <Row>
                                <Button onClick={toggleMail} color={"primary"} variant={"contained"}
                                        style={{
                                            width: 150,
                                            marginBottom: 15
                                        }}>Demander une
                                    révision</Button>
                            </Row>
                        }
                        <Row>
                            {
                                editMode ?
                                    <Button onClick={Update} color={"primary"} variant={"contained"}
                                            style={{   width: 300, marginBottom: 15
                                            }}>Sauvegarder</Button>
                                    :
                                    <Button onClick={toggleEditMode} color={"primary"} variant={"contained"}
                                            disabled={validateAt || (!formData.revisionDone && revisionSent)}
                                            style={{
                                                backgroundColor: (validateAt || (!formData.revisionDone && revisionSent)) && "#c4c4c4",
                                                color: '#ffffff',
                                                width: 150,
                                                marginBottom: 15
                                            }}>Éditer</Button>
                            }
                        </Row>
                        <Row>
                            <Button onClick={Valider}
                                    disabled={validateAt}
                                    style={{
                                        backgroundColor: !validateAt ? '#188e00' : '#c4c4c4',
                                        color: '#ffffff',
                                        width: 150,
                                        marginBottom: 15
                                    }}>Valider</Button>
                        </Row>
                    </Col>

                </Col>


            </Row>
        </Container>
    )


}


const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        margin: 15
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))

