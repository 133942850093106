import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchClientList = createAsyncThunk(
    'redactorClientList/fetchClientList',
    async (id, thunkAPI) => {
        try {
            const response = await axios.get("/Redactor/GetClients?id="+id)
            if(response.status === 200){
                return response.data
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

