import React, {useEffect, useState} from 'react'
import {Row, Col, Form, FormGroup, Label, Input as Input2} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {Redirect, useHistory} from "react-router-dom";
import {Modal, Jumbotron, ModalHeader, ModalFooter} from "reactstrap";
import Select from "@material-ui/core/Select";

import {makeStyles} from "@material-ui/core/styles";
import {ISOlanguages} from "../../helpers/Constants";
import Grid from "@material-ui/core/Grid";
import ModalBody from "reactstrap/es/ModalBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import {green} from "@material-ui/core/colors";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//TODO  : initialize value in const
//TODO change the vie to grid style from material ui


const useStyles = makeStyles((theme) => ({
    select: {
        minWidth: 150
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        backgroundColor: '#a8a8a8'
    },
    buttonProgress: {
        color: green[500]
    }

}));

export default function NouvelleCommande() {
    const classes = useStyles();
    const history = useHistory();
    const [client, setClient] = useState(null)
    const [nbrText, setNbrText] = useState(0)

    const [thematique, setThematique] = useState([]);
    const [ThématiqueList, setThématiqueList] = useState([]);

    const [listClient, setListClient] = useState([])

    const [consultant, setConsultant] = useState(null);

    const [devis, setDevis] = useState(null);
    const [listDevis, setListDevis] = useState(null);
    const [tache, setTache] = useState(null);
    const [entitled, setEntitled] = useState('');
    const [listWebsite, setListWebsite] = useState(null);


    const [maxNumber, setMaxNumber] = useState(0);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, [])

    const [redacteurAttribuer, setRedacteurAttribuer] = useState([{
        redacteur: null,
        tarif: null,
        deadline: null,
        nbrText: 0,
        nbrTextMin: 0,
        nbrTextMax: 0,
        facturation: false,
        nbrTextManaged: 0
    }])
    useEffect(() => {
        axios.get("/Admin/GetCompteClients").then(res => {
            setListClient(res.data)
        });
        axios.get("/Admin/GetListThematique").then(res => setThématiqueList(res.data))

        document.title = "Typix - Nouvelle commande";
    }, []);

    const [urlSite, setUrlSite] = useState(null)
    const [briefDetaille, setBriefDetaille] = useState('')
    const [documentationClient, setDocumentationClient] = useState('')
    const [gapSemantique, setGapSematique] = useState('')
    const [langue, setLangue] = useState(null)
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const toggleSuccess = () => {
        setSuccess(!success)
        history.push("/Admin/MainMenu")
    };
    const toggleError = () => setError(!error);
    const AddClient = () => {
        setLoading(true)
        axios.post("/Admin/AddCommande", {
            commande: {
                titre: client + " - " + tache.tacheName + " - " + entitled + " - " + nbrText,
                devisName: devis.devisName,
                idDevis: devis.devisId,
                tacheName: tache.tacheName,
                tache: tache.tacheId,
                intitule: entitled,
                coutFinal: 0,
                langue: langue,
                nbrText: nbrText,
                coutAchatMax: tache.prixAchatMax ? tache.prixAchatMax : 0,
                briefDetaille: briefDetaille,
                documentationClient: documentationClient,
                gabSemantique: gapSemantique,
                website: urlSite.id,
                new: true,
                createdAt: new Date(),
                noteDeadline: 0,
                noteConsigne: 0,
                noteQualite: 0,
                type: '',
                finished: false,
                finishedAt: null,
                timerStopped: false,
                allTextValidated: false,
                reminderSent: false

            },
            commandeThematique: thematique,
            listRedacteur: redacteurAttribuer.filter(x => x.redacteur).map(x => ({
                idRedacteur: x.redacteur.id,
                tarif: x.tarif,
                deadline: x.deadline,
                nbrText: x.nbrText,
                nbrMotsMax: x.nbrTextMax,
                nbrMotsMin: x.nbrTextMin,
                nbrMotsManaged: x.facturation ? null : x.nbrTextManaged,
                actualBilling: x.facturation
            })),
        }).then(() => {
            setSuccess(true);
            setLoading(false);
        }).catch((e) => {
            setError(true);
            setErrorMessage(e.response && e.response.status === 409 && "Le titre de la commande existe déjà");

            setLoading(false)
        })
    }
    const initialize = () => {
        setNbrText(0)
        setRedacteurAttribuer([])
        setClient(null)
        setLangue(null)
        setConsultant(null)
        setUrlSite('')
        setBriefDetaille('')
        setDocumentationClient('')
        setGapSematique('')
        setThematique([])
    }
    const CheckValidForm = () => {
        if (!langue || !consultant || !client || !devis || !tache || !entitled
            || !briefDetaille || !gapSemantique) {
            return true
        }
        return (thematique.length === 0 || redacteurAttribuer.length === 0 || maxNumber < 0 || nbrText === 0
            || briefDetaille.length === 0 || gapSemantique.length === 0);
    }

    useEffect(() => {
        try {
            setMaxNumber(nbrText -
                redacteurAttribuer
                    .map(x => x.nbrText).reduce((previousValue, currentValue) => previousValue + currentValue))
        } catch (e) {
            setMaxNumber(0)
        }
    }, [redacteurAttribuer, tache])
    return (
        <div style={{margin: 20}}>
            <Jumbotron>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <ArrowBackIcon color={"primary"}
                                           style={{fontSize: 40, cursor: 'pointer'}}
                                           onClick={() => history.push("/Admin/MainMenu")}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Nouvelle commande</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginTop: 25, color: '#05760b'}} variant={'h5'}>Titre de la
                                commande*</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                id="Nom site"
                                value={client}
                                loading={!listClient}
                                noOptionsText={"Pas d'options"}
                                onChange={(event, newValue) => {
                                    setClient(newValue);
                                    axios.get("/Admin/GetDevis", {
                                        params: {
                                            compte: newValue
                                        }
                                    }).then(res => setListDevis(res.data))

                                    axios.get("/Admin/GetUrls", {
                                        params: {
                                            compte: newValue
                                        }
                                    }).then(res => setListWebsite(res.data))
                                }}
                                options={listClient}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => <TextField {...params} label="Compte client"
                                                                    variant="standard"/>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="ref-devis"
                                disabled={!listDevis}
                                loading={!listDevis}
                                value={devis}
                                noOptionsText={"Pas d'options"}
                                onChange={(event, newValue) => {
                                    setDevis(newValue);
                                }}
                                options={listDevis}
                                getOptionLabel={(option) => {
                                    if (option.hasOwnProperty('devisName')) {
                                        return option.devisName;
                                    }
                                    return option;
                                }}
                                renderInput={(params) => <TextField {...params} label="Réf Devis"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Autocomplete
                                id="tache-devis"
                                disabled={!listDevis || !devis}
                                loading={!listDevis || !devis}
                                noOptionsText={"Pas d'options"}
                                onChange={(event, newValue) => {
                                    setTache(newValue);
                                    setNbrText(newValue.tacheQuantity)
                                }}
                                options={(listDevis && devis && listDevis.filter(x => x.devisName === devis.devisName).length > 0)
                                    ? listDevis.find(x => x.devisName === devis.devisName).taches : []}
                                getOptionLabel={(option) => option.tacheName + " - " + option.tacheId + " - " + option.tacheQuantity}
                                renderInput={(params) => <TextField {...params} label="Tâche - id Tâche*"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                label="Intitulé de la commande" fullWidth variant="standard"
                                value={entitled}
                                onChange={e => setEntitled(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>

                            <FormControl>
                                <TextField
                                    label="Nombre de textes" type={'number'} fullWidth variant="standard"
                                    value={nbrText}
                                    onChange={e => setNbrText(e.target.value)}
                                />
                            </FormControl>
                        </Grid>


                        <Grid item xs={4}>
                            <Autocomplete
                                id="URL du site*"
                                fullWidth
                                value={urlSite}
                                loading={!listWebsite}
                                disabled={!listWebsite}
                                onChange={(event, newValue) => {
                                    setUrlSite(newValue);
                                    setConsultant(newValue.consultantNavigation)
                                }}
                                options={listWebsite}
                                noOptionsText={"Pas d'options"}
                                getOptionLabel={(option) => option.urlSite}
                                renderInput={(params) => <TextField {...params} label="URL du site*"
                                                                    variant="standard"/>}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <InputLabel shrink={consultant}>Nom du consultant</InputLabel>
                                <Input readOnly value={consultant && consultant.nomPrenom}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="langue"
                                value={langue}
                                onChange={(event, newValue) => {
                                    setLangue(newValue);
                                }}
                                options={ISOlanguages.sort(function (a, b) {
                                    if (["Français", "Anglais", "Espagnol", "Italien", "Allemand"].includes(a.name)) {
                                        return 0;
                                    }
                                    if (["Français", "Anglais", "Espagnol", "Italien", "Allemand"].includes(b.name)) {
                                        return 0;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    if (b.name > a.name) {
                                        return -1;
                                    }
                                    return 0;
                                }).map(x => x.name)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => <TextField {...params} label="Langue*" variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Thématiques d’expertise</InputLabel>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={thematique}
                                    onChange={(event) => {
                                        setThematique(event.target.value);
                                    }}
                                    style={{marginTop: 25}}
                                    MenuProps={MenuProps}
                                    input={<Input/>}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Liste des thématiques</em>;
                                        }
                                        return (<div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip}/>
                                            ))}
                                        </div>)
                                    }}
                                >
                                    {
                                        ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                                <Checkbox checked={thematique.indexOf(item) > -1}/>
                                                <ListItemText primary={item}/>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <InputLabel shrink={tache}>Cout d'achat max (au mot)</InputLabel>
                                <Input readOnly value={tache && tache.prixAchatMax}
                                       startAdornment={<InputAdornment position="start">€</InputAdornment>}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}/>


                        {
                            redacteurAttribuer.length > 0 && redacteurAttribuer.map((value, index) =>
                                <RedactorForm key={index} id={index}
                                              Add={() => setRedacteurAttribuer([{
                                                  redacteur: null,
                                                  tarif: null,
                                                  deadline: null,
                                                  nbrText: 0,
                                                  nbrTextMin: 0,
                                                  nbrTextMax: 0,
                                                  facturation: false,
                                                  nbrTextManaged: 0
                                              }, ...redacteurAttribuer])}
                                              maxNbr={maxNumber}
                                              deleteable={redacteurAttribuer.length > 1}
                                              data={value}
                                              Delete={(keyIndex) => {
                                                  let newList = redacteurAttribuer;
                                                  newList.splice(keyIndex, 1);
                                                  setRedacteurAttribuer([...newList])
                                              }}
                                              Update={(type, object) => {
                                                  const newValue = value;
                                                  newValue[type] = object;
                                                  const newList = redacteurAttribuer;
                                                  newList[index] = newValue;
                                                  setRedacteurAttribuer([...newList])
                                              }}/>
                            )
                        }


                        <Grid item xs={6}>
                            <TextField
                                label={"Bref détaillé*"}
                                multiline
                                placeholder={"Lien drive.."}
                                value={briefDetaille}
                                onChange={e => setBriefDetaille(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={"Documentation Client*"}
                                placeholder={"Lien drive.."}
                                value={documentationClient}
                                onChange={e => setDocumentationClient(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={"Gap sémantique*"}
                                placeholder={"Lien drive.."}
                                value={gapSemantique}
                                onChange={e => setGapSematique(e.target.value)}
                            />

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>* Champs obligatoires</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setLoading(true);
                                    AddClient();
                                }}
                                disabled={CheckValidForm() || loading}
                                color="primary"
                                startIcon={loading ? <CircularProgress size={24} className={classes.buttonProgress}/> :
                                    <SaveIcon/>}
                            >Sauvegarder</Button>
                        </Grid>
                    </Grid>
                <Modal isOpen={success} toggle={toggleSuccess}>
                    <ModalHeader
                        toggle={() => setSuccess(null)}>{(redacteurAttribuer.length > 0) ? "Commande ajoutée avec succès !" : "Les rédacteurs spécialisés au(x) thématique(s) de la commande vont être informés par mail pour la nouvelle commande enregistrée (non attribuée). "}</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/succes.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            setSuccess(!success);
                            initialize()
                        }}>Nouvelle Commande</Button>{' '}
                        <Button color="secondary" onClick={toggleSuccess}>Revenir a l'ACCUEIL</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={error} toggle={toggleError}>
                    <ModalHeader
                        toggle={toggleError}>{errorMessage ? errorMessage : "Oups....! Une erreur s'est produite"}</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/error.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                    </ModalFooter>
                </Modal>
            </Jumbotron>


        </div>
    )
}
const RedactorForm = (props) => {
    const [listRedacteur, setListRedacteur] = useState([]);
    const [deadline, setDeadline] = useState(null);
    const [tarif, setTarif] = useState(0);
    const [redacteur, setRedacteur] = useState(null);

    const [nbrText, setNbrText] = useState(0)
    const [nbrTextMin, setNbrTextMin] = useState(0)
    const [nbrTextMax, setNbrTextMax] = useState(0)
    const [nbrTextManaged, setNbrTextManaged] = useState(0);
    const [facturation, setFacturation] = useState(false);
    const [maxNumber, setMaxNumber] = useState(0);
    const formatDate = (date) => {
        console.log(date)
        if (!date) {
            console.log("HERE")
            return null
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        setRedacteur(props.data.redacteur);
        setTarif(props.data.tarif);
        setDeadline(props.data.deadline);
        setNbrText(props.data.nbrText);
        setNbrTextMin(props.data.nbrTextMin);
        setNbrTextMax(props.data.nbrTextMax);
        setNbrTextManaged(props.data.nbrTextManaged);
        setFacturation(props.data.facturation);
    }, [props, props.data])

    useEffect(() => {
        setMaxNumber(props.maxNbr)
    }, [props, props.maxNbr])

    useEffect(() => {
        axios.get("/Admin/GetRedacteur").then(res => {
            setListRedacteur(res.data)
        })
    }, [])
    return (
        <React.Fragment>
            <Grid item xs={2}>
                <Autocomplete
                    id="redacteur"
                    fullWidth
                    disabled={listRedacteur.length === 0}
                    loading={listRedacteur.length === 0}
                    value={redacteur}
                    onChange={(event, newValue) => props.Update('redacteur', newValue)}
                    options={listRedacteur}
                    getOptionLabel={(option) => option.nomPrenom}
                    renderInput={(params) => <TextField {...params} label="Rédacteur attribué"
                                                        variant="standard"/>}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="Tarif au mot"
                    value={tarif}
                    fullWidth
                    type={'number'}
                    onChange={e => props.Update('tarif', e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel shrink>Deadline</InputLabel>
                    <Input type={'date'}
                           value={formatDate(deadline)}
                           onChange={date => props.Update('deadline', new Date(date.target.value))}/>
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="Nombre de textes*" fullWidth type={"number"} variant="standard"
                    value={nbrText}
                    inputProps={{min: 0}}
                    onBlur={() => {
                        if (maxNumber < 0) {
                            props.Update("nbrText", 0)
                        }
                    }}
                    onChange={e => props.Update("nbrText", parseInt(e.target.value))}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="Nombre minimal" fullWidth type={"number"} variant="standard"
                    value={nbrTextMin}
                    inputProps={{min: 0}}
                    onChange={e => props.Update('nbrTextMin', parseInt(e.target.value))}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="Nombre maximal" fullWidth type={"number"} variant="standard"
                    value={nbrTextMax}
                    inputProps={{min: 0}}
                    onChange={e => props.Update('nbrTextMax', parseInt(e.target.value))}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    label="Nombre de mot gérés" fullWidth type={"number"} variant="standard"
                    value={nbrTextManaged}
                    disabled={facturation}
                    inputProps={{min: 0}}
                    onChange={e => props.Update('nbrTextManaged', parseInt(e.target.value))}
                />
            </Grid>
            <Grid item xs={1}>
                <FormControlLabel control={<Checkbox name="checkedC" value={facturation} checked={facturation}
                                                     disabled={nbrTextManaged > 0}
                                                     onChange={(event) => {
                                                         props.Update('facturation', event.target.checked)
                                                     }
                                                     }/>} label="Facturation au réel"/>
            </Grid>

            <Grid item xs={1}>
                {
                    (props.deleteable && props.id > 0) ?
                        <RemoveCircleIcon fontSize={'large'} onClick={() => props.Delete(props.id)}/> : (maxNumber > 0 ?
                        <AddCircleIcon fontSize={'large'} onClick={() => props.Add()}/> : null)

                }
            </Grid>
        </React.Fragment>
    )
}
