import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import {DeliveredTexts, getDateFormat, HowManyDayLeft, ValidatedTexts} from "../../helpers/Constants";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {consultantCommandListSelector} from "../../store/Consultant/CommandList";
import LinearProgress from "@material-ui/core/LinearProgress";
import {fetchConsultantCommandDisplay} from "../../store/Consultant/CommandDisplay/features";
import {fetchConsultantCommandList} from "../../store/Consultant/CommandList/features";
import {UserSessionSelector} from "../../store/UserSession";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {sortListCommand} from "../../store/Consultant/CommandList";
import TextField from "@material-ui/core/TextField";

export default function ListCommande() {
    const history = useHistory();
    let {listCommand, isLoading} = useSelector(consultantCommandListSelector);
    let {user} = useSelector(UserSessionSelector);
    const dispatch = useDispatch()
    const [data, setData] = useState([]);

    const [search, setSearch] = useState('');


    useEffect(() => {
        dispatch(fetchConsultantCommandList(user.id))
    }, [])

    useEffect(() => {
        (() => {
            const columns = [{
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Commande en cours</div>,
                field: 'title',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold', width: 200}}>Détails de la commande</div>,
                field: 'details',
                sort: 'disabled',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date de livraison estimée</div>,
                field: 'date',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes livrés</div>,
                field: 'delivered_texts',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes validés</div>,
                field: 'validated_texts',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Temps restant pour valider la
                    commande</div>,
                field: 'temps_restant',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Validée</div>,
                field: 'validated',
                width: 250,
            }
            ];
            let rows = [];
            if (!isLoading) {
                listCommand.filter(x => {
                    if (search.length === 0) {
                        return true;
                    }
                    return (x.titre.toUpperCase().includes(search.toUpperCase()) || getDateFormat(x.deadline).includes(search));
                }).map(item => rows.push({
                    title: <Typography
                        style={{color: item.newText ? '#32c200' : '#282828'}}>{item.titre}</Typography>,
                    date: <Typography
                        color={(new Date() > new Date(item.deadline)) ? "secondary" : "initial"}> {item.deadline ? getDateFormat(item.deadline) : "non spécifié"}
                    </Typography>,
                    delivered_texts: <DeliveredTexts deliveredText={item.deliveredText}
                                                     total={parseInt(item.nbrText)}/>,
                    validated_texts: <ValidatedTexts validatedText={item.validatedText}
                                                     total={parseInt(item.nbrText)}/>,
                    temps_restant:
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {
                                item.allTextValidated ? <Typography>0 jour</Typography> : (item.remainingTime ?
                                    <HowManyDayLeft id={item.id} finishedAt={item.finishedAt} url={"/Consultant"}/> :
                                    <Typography> - </Typography>)
                            }
                        </div>,
                    validated: <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{
                        item.allTextValidated ? <CheckCircleIcon
                            style={{color: 'green'}}/> : <Typography>-</Typography>
                    }</div>,
                    details: <Button variant="contained" color="primary" onClick={() => {
                        dispatch(fetchConsultantCommandDisplay(item.id))
                        history.push("/Consultant/DétailsDeLaCommande")
                    }
                    }>Voir la commande</Button>

                }));
            }

            setData({columns, rows});
        })();
    }, [listCommand, isLoading,search])

    return (
        <Card>
            <CardContent>
                <CardHeader title={"Liste des commandes"}/>
                {
                    isLoading ? <React.Fragment>
                            <LinearProgress/>
                            <LinearProgress color="secondary"/>
                        </React.Fragment> :
                        <React.Fragment>
                            <TextField
                                label={"Recherche"}
                                style={{float: 'right', position: 'relative'}}
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                            <MDBDataTable striped entriesOptions={[10, 20, 50]} entries={10}
                                          noBottomColumns
                                          infoLabel={['Affichage', 'a', 'de', 'résultats']}
                                          sortable
                                          onSort={({column, direction}) => {
                                              if (data && data.rows && data.rows.length > 0 && column) {
                                                  dispatch(sortListCommand({column, direction}))
                                              }
                                          }}
                                          searching={false}
                                          paginationLabel={['Précédent', 'Suivant']}
                                          searchLabel={'Recherche'}
                                          noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                          info={true}
                                          entriesLabel={'Afficher les résultats'}
                                          data={data}/>

                        </React.Fragment>
                }

            </CardContent>
        </Card>
    )
}



