import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import {BatteryDisplay, DeliveredTexts, getDateFormat, ValidatedTexts} from "../../helpers/Constants";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {redactorCommandListSelector} from "../../store/Redactor/CommandList";
import LinearProgress from "@material-ui/core/LinearProgress";
import {fetchRedactorCommandDisplay} from "../../store/Redactor/CommandDisplay/features";
import {sortListCommand} from "../../store/Redactor/CommandList";
import {UserSessionSelector} from "../../store/UserSession";
import TextField from "@material-ui/core/TextField";

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
export default function ListCommande() {

    const {listCommand, title, isLoading, isError} = useSelector(redactorCommandListSelector);

    let {user} = useSelector(UserSessionSelector)

    const [search, setSearch] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyle();
    const [data, setData] = useState([]);

    const Still24Hours = (item) => {
        const date1 = new Date();
        const date2 = new Date(item);
        const same = date1.getTime() === date2.getTime();
        return same;
    }

    const fetchData = () => {
        const columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>{title}</div>,
                field: 'title',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date de livraison estimée</div>,
                field: 'date',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes livrés</div>,
                field: 'delivered_texts'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes validés</div>,
                field: 'validated_texts'
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Détails de la commande</div>,
                sort: 'disabled',
                field: 'details',
                width: 250,
            }
        ];
        let rows = [];
        if (listCommand.length > 0) {
            listCommand.filter(x => {
                if(search.length === 0) {
                    return true;
                }
                return (x.titre.toUpperCase().includes(search.toUpperCase()) ||  getDateFormat(x.deadline).includes(search));
            }).map(item => rows.push({
                title: <Typography
                    style={{color: Still24Hours(item.createdAt) ? '#32c200' : '#282828'}}>{item.titre}</Typography>,
                date: <Typography
                    color={(new Date() > new Date(item.deadline)) ? "secondary" : "initial"}>  {item.deadline ? getDateFormat(item.deadline) : "non spécifié"} </Typography>,
                delivered_texts: <DeliveredTexts deliveredText={item.deliveredText}
                                                 total={parseInt(item.nbrText)}/>,
                validated_texts: <ValidatedTexts validatedText={item.validatedText}
                                                 total={parseInt(item.nbrText)}/>,
                details: <Button variant="contained" color="primary" onClick={() => {
                    dispatch(fetchRedactorCommandDisplay({
                        id: item.id,
                        idRedacteur: user.id
                    }))
                    history.push("/Rédacteur/DétailsDeLaCommande")
                }
                }>Voir la commande</Button>
            }));
        }
        setData({columns, rows});

        document.title = "Typix - " + title;
    }

    if (isError) {
        history.push("/Rédacteur/MainMenu")
    }

    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [listCommand, isLoading,search])

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader} title={title}
                        action={   <ArrowBackIcon color={"primary"} style={{fontSize: 40,cursor: 'pointer'}}
                                                  onClick={() => history.goBack()}/>}/>
            <CardContent>
                {
                    isLoading ? <React.Fragment>
                            <LinearProgress/>
                            <LinearProgress color="secondary"/>
                        </React.Fragment> :
                        <React.Fragment>
                            <TextField
                                label={"Recherche"}
                                style={{float: 'right', position: 'relative'}}
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                            <MDBDataTable striped entriesOptions={[10, 20, 50]} entries={10}
                                          noBottomColumns
                                          infoLabel={['Affichage', 'a', 'de', 'résultats']}
                                          sortable
                                          searching={false}
                                          paginationLabel={['Précédent', 'Suivant']}
                                          searchLabel={'Recherche'}
                                          onSort={({column, direction}) => {
                                              if (data && data.rows && data.rows.length > 0 && column) {
                                                  dispatch(sortListCommand({column, direction}))
                                              }
                                          }}
                                          noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                          info={true}
                                          entriesLabel={'Afficher les résultats'}
                                          data={data}/>

                        </React.Fragment>

                }

            </CardContent>
        </Card>
    )
}



