import {createSlice} from '@reduxjs/toolkit'


export const redactorAnalyzer = createSlice({
    name: 'redactorAnalyzer',
    initialState: {
        idCommande: null,
        idCommandeRedacteurRelation: null,
        formData: null,
        nbrTextMin : null,
        nbrTextMax : null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateForm: (state, action) => {
            state.formData = action.payload.data;
            state.idCommande = action.payload.idCommande;
            state.idCommandeRedacteurRelation = action.payload.idCommandeRedacteurRelation;
            state.nbrTextMin = action.payload.nbrTextMin;
            state.nbrTextMax = action.payload.nbrTextMax;
        },updateTextForm: (state, action) => {
            state.formData = action.payload;
        }
    }
})


export default redactorAnalyzer.reducer

export const {updateForm,updateTextForm} = redactorAnalyzer.actions
export const redactorAnalyzerSelector = (state) => state.redactorAnalyzer;
