import React, {useEffect, useState} from 'react'
import {Button, Tooltip} from "@material-ui/core";
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ISOlanguages} from "../../helpers/Constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Jumbotron from "reactstrap/es/Jumbotron";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {makeStyles} from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalBody from "reactstrap/es/ModalBody";
import AddIcon from '@material-ui/icons/Add';
import {useDispatch, useSelector} from "react-redux";
import {adminRedactorDisplaySelector} from "../../store/Admin/RedactorDisplay";
import {useHistory} from "react-router-dom";
import {fetchAdminRedactorList} from "../../store/Admin/RedactorList/features";
import Container from "reactstrap/lib/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const useStyles = makeStyles((theme) => ({
    textfield: {
        width: 400
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        backgroundColor: '#a8a8a8'
    },
}));

export default function AddRedacteur() {
    let {redactorForm} = useSelector(adminRedactorDisplaySelector);
    const dispatch = useDispatch()
    const history = useHistory();
    const classes = useStyles();
    const [ThématiqueList, setThématiqueList] = useState([]);
    const [mail, setMail] = useState('');
    const [nomPrenom, setNomPrenom] = useState('');
    const [nomSociete, setNomSociete] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [thematique, setThematique] = useState([]);
    const [otherThematique, setOtherThematique] = useState([]);
    const [thematiqueInput, setThematiqueInput] = useState('');
    const [listLangue, setListLangue] = useState([]);
    const [otherLangue, setOtherLangue] = useState([]);
    const [readOnly, setReadOnly] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [analysis, setAnalysis] = useState(0);
    const [limit, setLimit] = useState(0);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [mailExist, setMailExist] = useState(false)

    const [showAddThematique, setShowAddThematique] = useState(false)

    const toggleSuccess = () => setSuccess(!success);
    const toggleError = () => setError(!error);
    const SaveRedacteur = () => {
        setLoading(true)
        CheckEmailExist().then(value => {
            setMailExist(value);
            if (!value) {
                axios.post("/Admin/AddRedacteur", {
                    redacteur: {
                        mail: mail,
                        nomPrenom: nomPrenom,
                        motDePasse: password,
                        numTelephone: telephone,
                        nomSociete: nomSociete,
                        adresse: adresse,
                        limite: limit,
                        analysis: analysis
                    },
                    langue: listLangue.concat(otherLangue),
                    thematique: thematique
                }).then(() => {
                    dispatch(fetchAdminRedactorList())
                    setSuccess(true)
                    setLoading(false)
                }).catch(() => {
                    setError(true)
                    setLoading(false)
                })
            }
        })
    }
    const DeleteRedacteur = () => {
        setLoading(true)
        axios.get("/Admin/DeleteRedacteur?id=" + redactorForm.id).then(() => {
            dispatch(fetchAdminRedactorList())
            history.goBack()
            setLoading(false)
        }).catch(() => {
            setError(true)
            setLoading(false)
        })
    }
    const UpdateRedacteur = () => {
        setLoading(true)
        axios.post("/Admin/UpdateRedacteur", {
            redacteur: {
                id: redactorForm.id,
                mail: mail,
                nomPrenom: nomPrenom,
                motDePasse: password,
                numTelephone: telephone,
                nomSociete: nomSociete,
                adresse: adresse,
                limite: limit,
                analysis: analysis
            },
            langue: listLangue.concat(otherLangue),
            thematique: thematique,
            passwordChanged: redactorForm.motDePasse !== password
        }).then(() => {
            dispatch(fetchAdminRedactorList())
            setSuccess(true)
        }).catch(() => setError(true))
        setLoading(false)
    }


    const CheckEmailExist = async () => axios.get("/Admin/CheckEmailExist?mail=" + mail).then(res => res.data)

    const Initialize = () => {
        setReadOnly(false)
        setThematique([]);
        setMail('');
        setPassword('');
        setNomPrenom('');
        setTelephone('');
        setNomSociete('');
        setAdresse('');
        setListLangue([]);
        setOtherLangue([]);
    }


    useEffect(() => {
        if (redactorForm) {
            setReadOnly(true)
            setThematique(redactorForm.redacteurThematique.map(x => x.thematique));
            setMail(redactorForm.mail);
            setPassword(redactorForm.motDePasse);
            setNomPrenom(redactorForm.nomPrenom);
            setTelephone(redactorForm.numTelephone);
            setNomSociete(redactorForm.nomSociete);
            setLimit(redactorForm.limite ? redactorForm.limite : 0)
            setAnalysis(redactorForm.analysis ? redactorForm.analysis : 0)
            setAdresse(redactorForm.adresse);
            setListLangue(redactorForm.redacteurLangue.filter(x => x.langue === "Français" || x.langue === "Italien" || x.langue === "Espagnol" || x.langue === "Allemand" || x.langue === "Anglais").map(x => x.langue));
            setOtherLangue(redactorForm.redacteurLangue.filter(x => x.langue !== "Français" && x.langue !== "Italien" && x.langue !== "Espagnol" && x.langue !== "Allemand" && x.langue !== "Anglais").map(x => x.langue));
            document.title = "Typix - Fiche rédacteur";
        }else{
            document.title = "Typix - Ajouter un rédacteur";
        }
    }, [redactorForm])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || password.length === 0 || nomPrenom.length === 0
            || nomSociete.length === 0 || adresse.length === 0 || thematique.length === 0 || (listLangue.length === 0 && otherLangue.length === 0) || mailExist) {
            return true
        }
        if ((!mail.match(emailcheck) && mail.length > 0) || mailExist) {
            return true
        }
        return CheckPasswordValid();

    }
    const ModifyState = redactorForm;

    const CheckPasswordValid = () => {
        if (redactorForm && redactorForm.motDePasse === password) {
            return false;
        }
        return !passwordCheck.test(password) && password.length > 0;

    }

    useEffect(() => {
        axios.get("/Admin/GetListThematique").then(res => setThématiqueList(res.data))
    }, [])
    return (
        <Container>

            <Jumbotron>

                <Grid spacing={1} container>

                    <span >
                        <Tooltip title="Retour" placement="right" >
                            <ArrowBackIcon color={"primary"} style={{ fontSize: 40,  cursor: 'pointer',  marginRight: 5, marginTop: -150, marginBottom: -70 }}
                            onClick={() => history.goBack()} />

                        </Tooltip>
                        </span>

                    <Grid item xs={12}>

                        {
                            ModifyState ? <Box fontWeight="fontWeightBold"  fontSize={30}>
                                Fiche rédacteur


                            </Box> :

                                <Box fontWeight="fontWeightBold"  fontSize={30} style={{ marginTop: -35}}>

                                Ajouter un rédacteur
                            </Box>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={"Mail"}
                            disabled={ModifyState}
                            onFocus={() => mailExist && setMailExist(false)}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            className={classes.textfield}
                            error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                            helperText={mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}
                            value={mail}
                            onChange={e => setMail(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel error={CheckPasswordValid()}>Mot de
                                passe</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                readOnly={readOnly}
                                error={CheckPasswordValid()}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error={CheckPasswordValid()}>Avoir au moins 8 caractères avec
                                une lettre majuscule, un chiffre et ne pas être identique au
                                nom</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl>
                            <InputLabel>Prénom/Nom </InputLabel>
                            <Input placeholder="Prénom/Nom" type={"text"} value={nomPrenom}
                                   onChange={e => setNomPrenom(e.target.value)}
                                   readOnly={readOnly}
                                   inputProps={{'aria-label': 'description'}}/>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={"Nom société"}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            value={nomSociete}
                            onChange={e => setNomSociete(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={"Adresse"}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            value={adresse}
                            onChange={e => setAdresse(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Téléphone"}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            value={telephone}
                            onChange={e => {
                                const re = /^(?=.*\d)[\d ]+$/;
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    setTelephone(e.target.value)
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={"Limite"}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{analysis + "/"}</InputAdornment>,
                                readOnly: readOnly
                            }}
                            value={limit}
                            onChange={e => e.target.value.length === 0 ? setLimit(0) : Number.isInteger(parseInt(e.target.value)) && setLimit(parseInt(e.target.value))}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel>Langues :</InputLabel>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Français")}
                                    disabled={readOnly}
                                    onChange={() => listLangue.includes("Français") ? setListLangue(listLangue.filter(x => x !== "Français")) : setListLangue([...listLangue, "Français"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Français"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Italien")}
                                    disabled={readOnly}
                                    onChange={() => listLangue.includes("Italien") ? setListLangue(listLangue.filter(x => x !== "Italien")) : setListLangue([...listLangue, "Italien"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Italien"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Anglais")}
                                    disabled={readOnly}
                                    onChange={() => listLangue.includes("Anglais") ? setListLangue(listLangue.filter(x => x !== "Anglais")) : setListLangue([...listLangue, "Anglais"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Anglais"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Allemand")}
                                    disabled={readOnly}
                                    onChange={() => listLangue.includes("Allemand") ? setListLangue(listLangue.filter(x => x !== "Allemand")) : setListLangue([...listLangue, "Allemand"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Allemand"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Espagnol")}
                                    disabled={readOnly}
                                    onChange={() => listLangue.includes("Espagnol") ? setListLangue(listLangue.filter(x => x !== "Espagnol")) : setListLangue([...listLangue, "Espagnol"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Espagnol"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <InputLabel shrink>Autre</InputLabel>
                            <Select
                                multiple
                                displayEmpty
                                value={otherLangue}
                                onChange={(event) => {
                                    setOtherLangue(event.target.value);
                                }}
                                inputProps={{
                                    id: 'langue-native-simple',
                                    readOnly: readOnly
                                }}
                                MenuProps={MenuProps}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>Liste des langues</em>;
                                    }
                                    return (<div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip}/>

                                        ))}
                                    </div>)
                                }}
                                input={<Input/>}
                            >
                                {
                                    ISOlanguages.filter(x => !['Français', 'Italien', 'Allemand', 'Espagnol', 'Anglais'].includes(x.name))
                                        .map((item, key) =>
                                        <MenuItem key={key} value={item.name}>
                                            <Checkbox checked={otherLangue.indexOf(item.name) > -1}/>
                                            <ListItemText primary={item.name}/>
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <InputLabel shrink>Thématiques d’expertise</InputLabel>
                            <Select
                                multiple
                                displayEmpty
                                value={thematique}
                                onChange={(event) => {
                                    setThematique(event.target.value);
                                }}
                                inputProps={{
                                    readOnly: readOnly
                                }}
                                style={{marginTop: 25}}
                                MenuProps={MenuProps}
                                input={<Input/>}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>Liste des thématiques</em>;
                                    }
                                    return (<div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip}/>
                                        ))}
                                    </div>)
                                }}
                            >
                                {
                                    ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                            <Checkbox checked={thematique.indexOf(item) > -1}/>
                                            <ListItemText primary={item}/>
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Ajouter une thématique" placement="right">
                            <button style={{backgroundColor: 'transparent', borderColor: 'transparent', marginTop: 25}}
                                    onClick={() => !readOnly && setShowAddThematique(true)}>
                                <AddIcon fontSize={'large'}/>
                            </button>
                        </Tooltip>
                    </Grid>



                    {
                        ModifyState ?
                            <React.Fragment>
                                <Grid item xs={3}>
                                    {
                                        readOnly ? <Button variant="contained" color="primary"
                                                           style={{margin: 10}}
                                                           onClick={() => setReadOnly(false)}>Editer la fiche</Button> :
                                            loading ? <CircularProgress/> :
                                                <Button variant="contained" color="primary" disabled={CheckValidForm()}
                                                        style={{margin: 10}}
                                                        onClick={UpdateRedacteur}>Sauvegarder</Button>


                                    }

                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="default" disabled={loading} style={{margin: 10}}
                                            onClick={DeleteRedacteur}>Supprimer la fiche</Button>
                                </Grid>


                            </React.Fragment> :
                            <Grid item xs={6}>
                                {
                                    loading ? <CircularProgress/> :
                                        <Button variant="contained" color="primary" disabled={CheckValidForm()}
                                                style={{margin: 10}}
                                                onClick={SaveRedacteur}>Sauvegarder</Button>
                                }

                            </Grid>


                    }

                </Grid>

                <Modal isOpen={showAddThematique} toggle={() => setShowAddThematique(!showAddThematique)}>
                    <ModalHeader toggle={() => setShowAddThematique(!showAddThematique)}>Merci d’ajouter une ou
                        plusieurs thématiques (si plusieurs, séparez-les par « entrée ») :</ModalHeader>
                    <ModalBody>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={thematiqueInput}
                                    onKeyPress={event => {
                                        if (event.key === "Enter" && thematiqueInput.length > 0) {
                                            setOtherThematique(prevArray => [...prevArray, thematiqueInput])
                                            setThematique(prevArray => [...prevArray, thematiqueInput])
                                            setThematiqueInput('')
                                        }
                                    }}
                                    onChange={(e) => {
                                        setThematiqueInput(e.target.value)
                                    }}

                                />
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    otherThematique.map((item, key) => <Chip style={{margin: 2}} onDelete={() => {
                                        setOtherThematique(otherThematique.filter(x => x !== item))
                                        setThematique(thematique.filter(x => x !== item))
                                    }} label={item} key={key}/>)
                                }
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            setShowAddThematique(!showAddThematique)
                        }}>Fermer</Button>
                        <Button color="secondary" onClick={() => {
                            setThematique(thematique.filter(x => !otherThematique.includes(x)))
                            setOtherThematique([])
                            setShowAddThematique(!showAddThematique)
                        }}>Réinitialiser</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={success} toggle={toggleSuccess}>
                    <ModalHeader toggle={toggleSuccess}>Rédacteur {ModifyState ? 'modifié' : 'ajouté'} avec succès
                        !</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/succes.png'} height={"100%"} width={"100%"}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            if (!ModifyState) {
                                Initialize();
                            } else {
                                setReadOnly(true)
                            }
                            toggleSuccess();
                        }}>{ModifyState ? 'Fermer' : 'Ajouter un autre rédacteur'}</Button>{' '}
                        <Button color="secondary" onClick={() => {
                            toggleSuccess()
                            history.goBack()
                        }}>Revenir a l'accueil</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={error} toggle={toggleError}>
                    <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/error.png'} height={"100%"} width={"100%"}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                    </ModalFooter>
                </Modal>
            </Jumbotron>


        </Container>
    )
}
