import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader, Input} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {UserSessionSelector} from "../../store/UserSession";
import {fetchRedactorCommandList} from "../../store/Redactor/CommandList/features";
import {redactorCommandListSelector, sortListCommand} from "../../store/Redactor/CommandList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
//TODO : supprimer commande
export default function ListCommandeNonAttribuee() {

    const {listCommand, isLoading, isError, title} = useSelector(redactorCommandListSelector);

    const dispatch = useDispatch()
    let {user} = useSelector(UserSessionSelector)
    const classes = useStyle();

    const history = useHistory();
    const [data, setData] = useState([]);

    const Delete = (id) => {
        axios.get("/Redactor/RemoveCommande?idNouvelleCommande=" + id).then(() => dispatch(fetchRedactorCommandList({
            url: "ordersUnassigned",
            title: "Commandes non-attribuées",
            id: user.id
        }))).catch(e => console.log(e))
    }

    const fetchData = async () => {
        const columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Commandes non-attribuées</div>,
                field: 'titleCommande',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Thématique(s)</div>,
                field: 'thematique',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Postuler</div>,
                sort: 'disabled',
                field: 'postuler',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Supprimer</div>,
                sort: 'disabled',
                field: 'supprimer',
                width: 250,
            }
        ];
        let rows = [];
        if (listCommand.length > 0) {
            listCommand.map(item => {
                rows.push({
                    titleCommande: item.titre,
                    thematique:
                        <div style={{wordBreak: 'break-word'}}>
                            <Typography> {item.thematiques.join(',')} </Typography>
                        </div>,
                    postuler: item.requestSent ?
                        <Typography style={{color: 'green'}}>Postulé</Typography> :
                        <Postuler id={item.id} title={item.titre} nbrTextMax={item.nbrText}/>,
                    supprimer: <Link component="button" onClick={() => Delete(item.idDemande)}>
                        <CloseIcon color={'secondary'}/>
                    </Link>
                })
            });
        }

        document.title = "Typix - " + title;
        setData({columns, rows});
    }


    if (isError) {
        history.push("/Rédacteur/MainMenu")
    }

    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [listCommand, isLoading])


    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader} title={title}
                        action={
                            <Tooltip title="Retour" placement="right">
                                <span>
                                   <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                                  onClick={() => history.goBack()}/>
                                </span>
                            </Tooltip>}/>
            <CardContent>
                {
                    isLoading ? <React.Fragment>
                            <LinearProgress/>
                            <LinearProgress color="secondary"/>
                        </React.Fragment> :
                        <MDBDataTable striped hover entriesOptions={[10, 20, 50]} entries={10}
                                      noBottomColumns
                                      infoLabel={['', '-', 'sur', 'résultats']}
                                      sortable
                                      paging={data && data.rows && data.rows.length > 0}
                                      paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                          <div style={{cursor: 'pointer'}}>Suivant</div>]}
                                      onSort={({column, direction}) => {
                                          if (data && data.rows && data.rows.length > 0 && column) {
                                              dispatch(sortListCommand({column, direction}))
                                          }
                                      }}
                                      searchLabel={'Recherche'}
                                      noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                      info={true}
                                      entriesLabel={'Afficher les résultats'}
                                      data={data}/>
                }

            </CardContent>
        </Card>
    )
}


const Postuler = (props) => {
    let {user} = useSelector(UserSessionSelector);
    const dispatch = useDispatch()
    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [nbrText, setNbrText] = useState(0);
    const [tarif, setTarif] = useState(0);
    const [deadline, setDeadline] = useState(null);

    const formatDate = (date) => {
        console.log(date)
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const Postuler = () => {
        setLoading(true)
        axios.post("/Redactor/PostulerCommande", {
            idRedacteur: user.id,
            idCommande: id,
            tarif: tarif,
            nbrText: nbrText,
            deadline: deadline
        }).then(() => dispatch(fetchRedactorCommandList({
            url: "ordersUnassigned",
            title: "Commandes non-attribuées",
            id: user.id
        }))).then(() => setLoading(false)).catch(() => setLoading(false))
    }
    useEffect(() => {
        setId(props.id)
    }, [props]);

    if (loading || !id) {
        return (<CircularProgress size={24}/>)
    }

    return (
        <React.Fragment>
            <Link component="button" onClick={() => setShowModal(true)}>
                <Typography>Postuler</Typography>
            </Link>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader>{"Postuler à la commande " + props.title}</ModalHeader>
                <ModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre de contenus"
                                value={nbrText}
                                fullWidth
                                type={'number'}
                                inputProps={{min: 0, max: props.nbrTextMax}}
                                onBlur={() => {
                                    if (nbrText > props.nbrTextMax) {
                                        setNbrText(0)
                                    }
                                }}
                                onChange={e => setNbrText(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Tarif"
                                value={tarif}
                                fullWidth
                                type={'number'}
                                onChange={e => setTarif(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Livraison estimée au &#160;
                                <Input type={'date'}
                                       value={formatDate(deadline)}
                                       onChange={date => setDeadline(date.target.value)}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setShowModal(false)}>Annuler</Button>
                    <Button color="primary" onClick={Postuler} disabled={!deadline ||  nbrText === 0 || tarif ===0} >Je postule</Button>
                </ModalFooter>
            </Modal>

        </React.Fragment>
    )
}
