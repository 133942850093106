import React, {useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import axios from "axios";
import FormHelperText from "@material-ui/core/FormHelperText";
import SaveIcon from "@material-ui/icons/Save";
import { Modal, ModalFooter, ModalHeader,Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {updateUser, UserSessionSelector} from "../../store/UserSession";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


export default function MyProfile (){
    let {user} = useSelector(UserSessionSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const[nom,setNom] = useState('');
    const [prenom,setPrenom] = useState('');
    const [telephone,setTelephone] = useState('');
    const[password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const[showPassword,setShowPassword] = useState(false)

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const toggleSuccess = () => setSuccess(!success);
    const toggleError = () => setError(!error);

    const InitializeChanges =() => {
        setNom(user.nom);
        setPrenom(user.prenom);
        setTelephone(user.numTelephone);
        setPassword(user.motDePasse);
        setConfirmPassword(user.motDePasse);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])

    useEffect(() => {
        InitializeChanges();
        document.title = "Typix - Mon profil";
    },[user])

    const Update = () => {
        axios.post("/Admin/UpdateProfile?passwordChanged="+(user.motDePasse !== password),{
            mail : user.mail,
            nom : nom ,
            prenom : prenom,
            numTelephone : telephone,
            motDePasse : password
        }).then(res => dispatch(updateUser(res.data))).then(() => toggleSuccess()).catch(() => toggleError())
    }

    return(
        <Container>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ArrowBackIcon color={"primary"}
                                   style={{fontSize: 40, cursor: 'pointer'}}
                                   onClick={() => history.push("/Admin/MainMenu")}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Mon profil</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={"Nom"}
                        style={{width : 350}}
                        value={nom}
                        onChange={e => setNom(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={"Prénom"}
                        style={{width : 350}}
                        value={prenom}
                        onChange={e => setPrenom(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={"Mail"}
                        style={{width : 350}}
                        value={user.mail}
                        disabled
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={"Téléphone"}
                        style={{width : 350}}
                        value={telephone}
                        onChange={e => {
                            const re = /^(?=.*\d)[\d ]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                                setTelephone(e.target.value)
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl style={{width : 350}}>
                        <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if(user.motDePasse === password){
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText  error={password !== confirmPassword} >Les mots de passe ne sont pas identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl style={{width : 350}}>
                        <InputLabel htmlFor="standard-adornment-password">Confirmer le mot de passe </InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if(user.motDePasse === confirmPassword){
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            onChange={e => setConfirmPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText  error={password !== confirmPassword} >Les mots de passe ne sont pas identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Button variant={'contained'} color={'primary'} disabled={password !== confirmPassword || password.length === 0 }
                            startIcon={<SaveIcon/>} onClick={Update}>Sauvegarder</Button>
                </Grid>
            </Grid>
            <Modal isOpen={success} toggle={toggleSuccess}>
                <ModalHeader toggle={toggleSuccess}>Profil mis à jour avec succès  !</ModalHeader>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        toggleSuccess()
                    }}>Fermer</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={error} toggle={toggleError}>
                <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                </ModalFooter>
            </Modal>
        </Container>

    )
}
