import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchClientNavCounter = createAsyncThunk(
    'clientNavCounter/fetchClientNavCounter',
    async (id, thunkAPI) => {
        try {
            if (!id)
                return thunkAPI.rejectWithValue(404);
            const newText = await axios.get("/Client/CountNewText?clientId=" + id,);

            const newCommand = await axios.get("/Client/CountNewCommande?clientId=" + id)
            if (newCommand.status === 200 && newText.status ===200) {
                return {
                    newCommand : newCommand.data,
                    newText : newText.data
                }
            }
            return thunkAPI.rejectWithValue(500);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

