import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchRedactorCommandDisplay = createAsyncThunk(
    'redactorCommandDisplay/fetchRedactorCommandDisplay',
    async (object, thunkAPI) => {
        try {
            if (!object)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Redactor/CommandDetails",{
                params :{
                    id : object.id,
                    idRedacteur : object.idRedacteur
                }
            })
            console.log(response.data);
            if (response.status === 200) {
                return response.data
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

