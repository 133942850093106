import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import {Button, Select, MenuItem, Grid} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import './Analyzer.css'
import ReactQuill from 'react-quill';
import {NumberOfWords} from "../../helpers/Constants";
import {setKeyWordList} from "../../helpers/PdfFunction";
import {useSelector} from "react-redux";
import {consultantAnalyzerSelector} from "../../store/Consultant/Analyser";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function Analyzer() {
    let {formData} = useSelector(consultantAnalyzerSelector);

    const history = useHistory();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [text, setText] = useState("");
    const [language, setLanguage] = useState('FRENCH');
    const [openLanguage, setOpenLanguage] = useState(false);
    const [htmlText, setHtmlText] = useState('')
    const [result, setResult] = useState('')
    const toggle = () => setPopoverOpen(!popoverOpen);
    const [color, setColor] = useState('black');
    const [convertedText, setConvertedText] = useState({});



    const [activeTab, setActiveTab] = useState('1');

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [baliseMeta, setBaliseMeta] = useState('')
    const [nomText, setNomText] = useState('')
    const [motCle, setMotCle] = useState('')
    const [url, setUrl] = useState('')
    const [baliseTitle, setBaliseTitle] = useState('');

    const Initialize = () => {
        try{
            setText(formData.text1);
            setMotCle(formData.motCle);
            setHtmlText(formData.htmlText);
            setUrl(formData.url);
            setBaliseTitle(formData.baliseTitle);
            setBaliseMeta(formData.baliseMetaDescription);
            setNomText(formData.nomText);
            setKeywords(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n"));
            setResult(formData.result && JSON.parse(formData.result));
            let resultForColor = formData.result && JSON.parse(formData.result);
            if(resultForColor){
                if (resultForColor.semanticEnrichment <= 29) {
                    setColor('#FF595D');
                }
                if (resultForColor.semanticEnrichment <= 69 && 29 < resultForColor.semanticEnrichment) {
                    setColor('#FFBE64');
                }
                if (resultForColor.semanticEnrichment > 69) {
                    setColor('#3ED774');
                }
            }
            setConvertedText(setKeyWordList(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n")));
        }catch{
            history.push("/Consultant/MainMenu")
        }

    }

    useEffect(() => {
        Initialize();
        document.title = "Typix - Détails du texte";
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                   onClick={() => history.goBack()}/>
                </Col>
            </Row> <Row>
                <Col>
                    <Typography variant={'h5'} style={{marginBottom : 20}} >Détails du texte</Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Détail du formulaire</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nom du texte"}
                                        value={nomText}
                                        fullWidth
                                        color={"secondary"}
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Mot clé"}
                                        value={motCle}
                                        fullWidth
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Url"}
                                        value={url}
                                        fullWidth
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Titre de la balise"}
                                        value={baliseTitle}
                                        fullWidth
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Balise meta-description"}
                                        value={baliseMeta}
                                        fullWidth
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Col>
            </Row>
            <Row>
                <span style={{marginTop: 20 , marginBottom : 20}}/>
            </Row>
            <Row>
                <Col xs={'6'}>
                    <Row>


                        <h4>Mettez des mots-clés avec fréquence :</h4> <HelpIcon id="Popover"/>
                        <Popover placement="bottom" isOpen={popoverOpen} target="Popover" toggle={toggle}>
                            <PopoverHeader>Instruction</PopoverHeader>
                            <PopoverBody>
                                <Container>
                                    <Row>
                                        Format souhaité :
                                        - mot-clé (fréquence)
                                        Séparez les mots-clés par un " Enter ".

                                    </Row>
                                    <Row>
                                        ---------------------------------
                                    </Row>
                                    <Row>
                                        Exemple:
                                    </Row>
                                    <Row>
                                        mot-clé1 (2)
                                    </Row>
                                    <Row>
                                        mot-clé2 (1)
                                    </Row>
                                    <Row>
                                        mot-clé3 (1)
                                    </Row>
                                    <Row>
                                        mot-clé4 (2)
                                    </Row>
                                </Container>
                            </PopoverBody>
                        </Popover>

                    </Row>
                    <div style={{display : 'flex'}}>
                        <Box component="span" display="block" p={1} m={1} bgcolor="background.paper" style={{ overflow: 'auto' }}>

                            {keywords}
                        </Box>
                    </div>

                    <h4>Mettez le texte :</h4>
                    <div style={{marginBottom: 50}}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    onClick={() => {
                                        toggleTab('1');
                                    }}
                                >
                                    Texte
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    onClick={() => {
                                        toggleTab('2');
                                    }}
                                >
                                    Html
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <ReactQuill value={(formData && formData.content) || ""}
                                            readOnly
                                            style={{
                                                height: 250
                                            }} />
                            </TabPane>
                            <TabPane tabId="2">
                                    <textarea
                                        value={formData && formData.content}
                                        readOnly
                                        style={{
                                            width: "100%",
                                            margin: 0,
                                            background: "rgb(29, 29, 29)",
                                            boxSizing: "border-box",
                                            color: "rgb(204, 204, 204)",
                                            outline: "none",
                                            padding: "12px 15px",
                                            fontFamily: "Consolas, Menlo, Monaco, Courier New, monospace",
                                            height: 250
                                        }}
                                    />
                            </TabPane>
                        </TabContent>
                    </div>
                    <Row>
                        <h4 style={{margin: 10}}>Langue : </h4>
                        <Select
                            labelId="language-label"
                            id="language-select"
                            inputProps={{
                                style: {
                                    margin: 15
                                }
                            }}
                            open={openLanguage}
                            value={language}
                        >
                            <MenuItem value={'GERMAN'}>ALLEMAND</MenuItem>
                            <MenuItem value={'ENGLISH'}>ANGLAIS</MenuItem>
                            <MenuItem value={'BULGARIAN'}>BULGARIE</MenuItem>
                            <MenuItem value={'KOREAN'}>CORÉEN</MenuItem>
                            <MenuItem value={'SPANISH'}>ESPAGNOL</MenuItem>
                            <MenuItem value={'ESTONIAN'}>ESTONIEN</MenuItem>
                            <MenuItem value={'FINNISH'}>FINNISH</MenuItem>
                            <MenuItem value={'FRENCH'}>FRANÇAIS</MenuItem>
                            <MenuItem value={'GREEK'}>GREC</MenuItem>
                            <MenuItem value={'ITALIAN'}>ITALIEN</MenuItem>
                            <MenuItem value={'DUTCH'}>NÉERLANDAIS</MenuItem>
                            <MenuItem value={'POLISH'}>POLISH</MenuItem>
                            <MenuItem value={'PORTUGUESE'}>PORTUGAIS</MenuItem>
                            <MenuItem value={'ROMANIAN'}>ROMAIN</MenuItem>
                            <MenuItem value={'RUSSIAN'}>RUSSIE</MenuItem>
                            <MenuItem value={'CZECH'}>TCHÈQUE</MenuItem>
                        </Select>
                    </Row>
                </Col>


                <Col xs={'6'}>
                    <h3 style={{textAlign: 'center'}}>Résultat</h3>

                    <Col>
                        <b>Nombre de mots : {NumberOfWords(text)}</b>
                    </Col>
                    <div style={{
                        display: 'flex', borderRadius: 15, borderWidth: 1
                    }}>
                        {
                            result &&
                            <Col>
                                <b>Enrichissement sémantique: </b>  <h2
                                style={{color: color, marginLeft: 10}}>{result.semanticEnrichment.toFixed(2)}%</h2>
                                {
                                    (result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) ?
                                        <div>
                                            <b>Ajout de mots-clés avec la fréquence restante : </b>
                                            <p>
                                                {Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {

                                                    return (
                                                        <span key={key}>{item}(<span
                                                            style={{textDecorationLine: 'line-through'}}>{convertedText[item]},</span>{result.greenWordsByFreq.greenWordsWithRemainingFreq[item]})<span>  </span></span>
                                                    )
                                                })}
                                            </p>
                                        </div> : null
                                }

                                {
                                    (result.remainingKeywords && Object.keys(result.remainingKeywords).length > 0) &&
                                    <div>
                                        <b>Mots clés restants: </b>
                                        <p>
                                            {Object.keys(result.remainingKeywords).map((item, key) => {
                                                let word = item + '(' + result.remainingKeywords[item] + ') '
                                                return (word)
                                            })}
                                        </p>
                                    </div>
                                }

                            </Col>
                        }


                    </div>

                </Col>


            </Row>
        </Container>
    )


}
