import React, {useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Button} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateUser, UserSessionSelector} from "../../store/UserSession";
import Container from "reactstrap/lib/Container";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function MyProfile() {

    let {user} = useSelector(UserSessionSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [compte, setCompte] = useState('')
    const [clientName, setClientName] = useState('')
    const [mail, setMail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [website, setWebsite] = useState([])

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [index, setIndex] = useState(0)
    const InitializeChanges = () => {
        setMail(user.mail);
        setClientName(user.nomPrenom);
        setTelephone(user.numTelephone);
        setPassword(user.motDePasse);
        setConfirmPassword(user.motDePasse);
        setCompte(user.compte);
        setWebsite(user.clientWebsiteRelation.length > 0 ? user.clientWebsiteRelation.map(x => x.websiteNavigation) : [])
    }

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, [])

    useEffect(() => {
        InitializeChanges();
        document.title = "Typix - Mon profil";
    }, [user])

    const Update = () => {
        axios.post("/Client/UpdateProfile?passwordChanged=" + (user.motDePasse !== password), {
            id: user.id,
            mail: user.mail,
            motDePasse: password,
            nomPrenom: clientName,
            numTelephone: telephone,
            compte: user.compte
        }).then(res => dispatch(updateUser(res.data)))
    }
    console.log(website[index])
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ArrowBackIcon color={"primary"}
                                   style={{fontSize: 40, cursor: 'pointer'}}
                                   onClick={() => history.push("/Client/MainMenu")}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Mon profil</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={"Nom et prénom"}
                        value={clientName}
                        style={{width: 400}}
                        onChange={e => setClientName(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={"Mail"}
                        disabled
                        value={mail}
                        style={{width: 400}}
                        InputProps={{
                            readOnly: true,
                        }}
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={"Téléphone"}
                        value={telephone}
                        type={'number'}
                        style={{width: 400}}
                        onChange={e => setTelephone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{width: 400}}>
                        <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if (user.motDePasse === confirmPassword) {
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText error={password !== confirmPassword}>Les mots de passe ne sont pas
                                identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl style={{width: 400}}>
                        <InputLabel htmlFor="standard-adornment-password">Confirmer le mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if (user.motDePasse === confirmPassword) {
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            onChange={e => setConfirmPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText error={password !== confirmPassword}>Les mots de passe ne sont pas
                                identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={"Compte"}
                        value={compte}
                        style={{width: 400}}
                        InputProps={{
                            readOnly: true,
                        }}
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant={'contained'} color={'primary'}
                            disabled={password !== confirmPassword || password.length === 0}
                            style={{margin: 30}}
                            startIcon={<SaveIcon/>} onClick={Update}>Sauvegarder</Button>
                </Grid>
            </Grid>
            <Divider style={{width: 400, marginTop: 20, marginBottom: 20}}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField id="select" disabled={website.length === 0} label="Site"
                               value={website.length > 0 ? 0 : "Aucun site associée"}
                               onChange={e => setIndex(parseInt(e.target.value))}
                               select={website.length > 0}>
                        {
                            website.length > 0 &&
                            website.map((x, key) => <MenuItem key={key} value={key}>{x.urlSite}</MenuItem>)
                        }
                    </TextField>
                </Grid>
                {
                    website.length > 0 && <React.Fragment>
                        <Grid item xs={12}>
                            <TextField
                                style={{width: 400}}
                                label={"Nom du consultant"}
                                value={website[index].consultantNavigation ? website[index].consultantNavigation.nomPrenom : 'Non spécifié'}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Détail de la prestation du contenu"}
                                InputProps={{
                                    readOnly: true
                                }}
                                style={{width: 400}}
                                value={website[index].detailPrestation}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={"Nombre de cocons"}
                                value={website[index].nbrCocons}
                                style={{width: 400}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nombre de texte par cocon"}
                                value={website[index].nbrTextCocons}
                                style={{width: 400}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nombre de FAQ"}
                                value={website[index].nbrFaq}
                                style={{width: 400}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nombre de GMB"}
                                value={website[index].nbrGmb}
                                style={{width: 400}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Nombre de textes à optimiser"}
                                value={website[index].nbrTextOptimiser}
                                style={{width: 400}}
                                InputProps={{
                                    readOnly: true,
                                }}
                                contentEditable={false}
                                suppressContentEditableWarning={true}/>
                        </Grid>
                    </React.Fragment>
                }

            </Grid>
        </Container>


    )
}


const ConsultantProfile = (props) => {
    const [mail, setMail] = useState('');
    const [nomPrenom, setNomPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    const InitializeChanges = () => {
        setMail(props.selected.mail);
        setPassword(props.selected.motDePasse);
        setNomPrenom(props.selected.nomPrenom);
        setTelephone(props.selected.numTelephone);

    }
    useEffect(() => {
        InitializeChanges();
    }, [])

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography>Consultant</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={'Email'}
                    value={mail}
                    fullWidth
                    InputProps={{
                        readOnly: true,

                    }}
                    contentEditable={false}
                    suppressContentEditableWarning={true}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel htmlFor="standard-adornment-password">Nom/Prénom</InputLabel>
                    <Input placeholder="Nom Prénom" type={"text"} value={nomPrenom}
                           readOnly={true}
                           onChange={e => setNomPrenom(e.target.value)}
                           inputProps={{'aria-label': 'description'}}/>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Numéro de telephone"}
                    value={telephone}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>


        </Grid>
    )
}
