import React, { useEffect, useState } from 'react'
import { Modal, ModalFooter, ModalHeader,Container } from "reactstrap";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import axios from "axios";
import {Redirect, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateUser, UserSessionSelector} from "../../store/UserSession";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


export default function MyProfile() {
    let {user} = useSelector(UserSessionSelector);

    const history = useHistory();
    const dispatch = useDispatch()
    const [nomPrenom, setNomPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const InitializeChanges = () => {
        setPassword(user.motDePasse);
        setConfirmPassword(user.motDePasse);
        setNomPrenom(user.nomPrenom);
        setTelephone(user.numTelephone);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])


    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const toggleSuccess = () => {
        setSuccess(!success);
    };

    const toggleError = () => setError(!error);

    useEffect(() => {
        InitializeChanges();
        document.title = "Typix - Mon profil";
    }, [user])

    const Update = () => {
        axios.post("/Consultant/UpdateProfile?passwordChanged=" + (user.motDePasse !== password), {
            id: user.id,
            motDePasse: password,
            nomPrenom: nomPrenom,
            numTelephone: telephone,
            mail: user.mail
        } ).then((res) => dispatch(updateUser(res.data))).then(() => setSuccess(true)).catch(() => toggleError())
    }
    return (
        <Container>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <ArrowBackIcon color={"primary"}
                                   style={{fontSize: 40, cursor: 'pointer' }}
                                   onClick={() => history.push("/Consultant/MainMenu")}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Mon profil</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl>
                        <TextField
                            label={'Mail'}
                            value={user.mail}
                            style={{ width: 350 }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{ width: 350 }}>
                        <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if (user.motDePasse === password) {
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText error={password !== confirmPassword} >Les mots de passe ne sont pas identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl style={{ width: 350 }}>
                        <InputLabel htmlFor="standard-adornment-password">Confirmer le mot de passe </InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            error={password !== confirmPassword}
                            onFocus={() => {
                                if (user.motDePasse === confirmPassword) {
                                    setPassword('')
                                    setConfirmPassword('')
                                }
                            }}
                            onChange={e => setConfirmPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {
                            (password !== confirmPassword) &&
                            <FormHelperText error={password !== confirmPassword} >Les mots de passe ne sont pas identiques</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl  style={{ width: 350 }}>
                        <InputLabel htmlFor="standard-adornment-password">Nom/Prénom</InputLabel>
                        <Input placeholder="Nom Prénom" type={"text"} value={nomPrenom}
                            onChange={e => setNomPrenom(e.target.value)}
                            inputProps={{ 'aria-label': 'description' }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={"Numéro de téléphone"}
                        value={telephone}
                        style={{ width: 350 }}
                        onChange={e => {
                            setTelephone(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" disabled={password !== confirmPassword || password.length === 0} style={{ margin: 10 }}

                        onClick={Update}>Sauvegarder</Button>
                </Grid>

            </Grid>
            <Modal isOpen={success} toggle={toggleSuccess}>
                <ModalHeader toggle={toggleSuccess}>Profil mis à jour avec succès  !</ModalHeader>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        toggleSuccess()
                    }}>Fermer</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={error} toggle={toggleError}>
                <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}
