import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchAdminCommandList = createAsyncThunk(
    'adminCommandList/fetchAdminCommandList',
    async (input, thunkAPI) => {
        try {
            if (!input)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Admin/" + input.url)
            if (response.status === 200) {
                return {
                    data: response.data,
                    title: input.title,
                    url: input.url,
                }
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

