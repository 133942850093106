import {configureStore} from '@reduxjs/toolkit'
import userSessionReducer from './UserSession'
import adminCommandSummary from './Admin/CommandSummary'
import adminClientList from './Admin/ClientList'
import adminCommandList from './Admin/CommandList'
import adminCommandDisplay from './Admin/CommandDisplay'
import adminAnalyzer from "./Admin/Analyser";
import adminClientDisplay from "./Admin/ClientDisplay";
import adminRedactorList from "./Admin/RedactorList";
import adminRedactorDisplay from "./Admin/RedactorDisplay";
import adminUserManagement from "./Admin/UserManagement";
import redactorCommandSummary from "./Redactor/CommandSummary";
import redactorClientList from "./Redactor/ClientList";
import redactorCommandList from "./Redactor/CommandList";
import redactorAnalyzer from "./Redactor/Analyser";
import redactorCommandDisplay from "./Redactor/CommandDisplay";
import clientCommandList from "./Client/CommandList";
import clientCommandDisplay from "./Client/CommandDisplay";
import clientNavCounter from "./Client/NavCounter";
import clientAnalyzer from "./Client/Analyser";
import consultantCommandList from "./Consultant/CommandList";
import consultantCommandDisplay from "./Consultant/CommandDisplay";
import consultantAnalyzer from "./Consultant/Analyser";
import consultantClientList from "./Consultant/ClientList";
import consultantClientDisplay from "./Consultant/ClientDisplay";
import adminWebsiteDetails from "./Admin/WebsiteDisplay";
import adminListDesDemandes from "./Admin/ListeDesDemandes";
import consultantClientsDetails from "./Consultant/ClientsDetails";


export default configureStore({
    reducer: {
        userSession : userSessionReducer,

        //Admin
        adminCommandSummary : adminCommandSummary,
        adminClientList : adminClientList,
        adminCommandList : adminCommandList,
        adminCommandDisplay : adminCommandDisplay,
        adminAnalyzer : adminAnalyzer,
        adminClientDisplay : adminClientDisplay,
        adminRedactorList : adminRedactorList,
        adminRedactorDisplay : adminRedactorDisplay,
        adminUserManagement : adminUserManagement,
        adminWebsiteDetails : adminWebsiteDetails,
        adminListDesDemandes : adminListDesDemandes,

        //Redactor
        redactorCommandSummary : redactorCommandSummary,
        redactorClientList : redactorClientList,
        redactorCommandList : redactorCommandList,
        redactorAnalyzer : redactorAnalyzer,
        redactorCommandDisplay : redactorCommandDisplay,

        //Client
        clientCommandList : clientCommandList,
        clientCommandDisplay : clientCommandDisplay,
        clientNavCounter : clientNavCounter,
        clientAnalyzer : clientAnalyzer,


        //Consultant
        consultantCommandList : consultantCommandList,
        consultantCommandDisplay : consultantCommandDisplay,
        consultantAnalyzer : consultantAnalyzer,
        consultantClientList : consultantClientList,
        consultantClientDisplay : consultantClientDisplay,
        consultantClientsDetails : consultantClientsDetails

    }, middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
