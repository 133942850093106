import {createSlice} from '@reduxjs/toolkit'
import {fetchAdminRedactorList} from "./features";


export const adminRedactorList = createSlice({
    name: 'adminRedactorList',
    initialState: {
        redactorList: null,
        isLoadingRedactor: true,
        isError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAdminRedactorList.fulfilled, (state, {payload}) => {
            state.redactorList = payload;
            state.isLoadingRedactor = false;
            state.isError = false;
        }).addCase(fetchAdminRedactorList.pending, (state, {payload}) => {
            state.isLoadingRedactor = true;
            state.isError = false;
        }).addCase(fetchAdminRedactorList.rejected, (state, {payload}) => {
            state.isLoadingRedactor = false;
            state.isError = true;
        })
    }
})


export default adminRedactorList.reducer


export const adminRedactorListSelector = (state) => state.adminRedactorList;
