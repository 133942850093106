import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader, TextField, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {MDBDataTable} from "mdbreact";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {Modal, ModalFooter, ModalHeader} from "reactstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Checkbox from '@material-ui/core/Checkbox';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    adminListDesDemandesSelector,
    updateChecked, updateData,
    sortListCommand,
    updateUnChecked
} from "../../store/Admin/ListeDesDemandes";
import {COULEUR_BLEUE, getDateFormat, GREEN} from "../../helpers/Constants";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import ModalBody from "reactstrap/es/ModalBody";
import {fetchAdminCommandList} from "../../store/Admin/CommandList/features";
import {adminCommandListSelector} from "../../store/Admin/CommandList";

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px',
        maxWidth: '100%',
        overflow: 'auto'
    },
    cardBody: {
        maxWidth: '100%',
        overflow: 'auto'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
export default function ListDesDemandes() {

    let {listDemande, isLoading, isError, title, checked, id, nbrRemaining} = useSelector(adminListDesDemandesSelector);
    let {url} = useSelector(adminCommandListSelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyle();
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [nbrTextAdded, setNbrTextAdded] = useState(0);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        const columns = [
            {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Nom de la commande</div>,
                field: 'title',
                sort: 'disabled'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>nom du rédacteur</div>,
                field: 'nomPrenom'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Nombre de textes</div>,
                field: 'nbrText'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold', width: 200}}>Nombre de mots (total
                    gérés)</div>,
                field: 'nbrMotARediger'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold', width: 200}}>Tarif(/mot)</div>,
                field: 'tarif',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold', width: 200}}>Deadline estimée</div>,
                field: 'deadline',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Nombre de mots min</div>,
                field: 'nbrMotsMin'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Nombre de mots max</div>,
                field: 'nbrMotsMax'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Facturation en réel</div>,
                field: 'facturation',
                sort: 'disabled'
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold', width: 200}}>Réponse admin</div>,
                field: 'response',
                sort: 'disabled'
            }
        ];
        let rows = [];
        if (checked && listDemande && listDemande.length > 0) {
            listDemande.map((item, key) => {
                rows.push({
                    title: title,
                    nomPrenom: item.nomRedacteur,
                    nbrText: <TextField
                        value={item.nbrText}
                        type={'number'}
                        inputProps={{min: 0}}
                        onChange={(e) => dispatch(updateData({
                            key: key,
                            column: "nbrText",
                            data: parseInt(e.target.value)
                        }))}
                    />,
                    nbrMotARediger: <TextField
                        value={item.nbrMotARediger}
                        type={'number'}
                        inputProps={{min: 0}}
                        onChange={(e) => dispatch(updateData({
                            key: key,
                            column: "nbrMotARediger",
                            data: parseInt(e.target.value)
                        }))}
                    />,
                    tarif: item.tarif,
                    deadline: getDateFormat(item.deadline),
                    nbrMotsMin: <TextField
                        value={item.nbrMotsMin}
                        type={'number'}
                        inputProps={{min: 0}}
                        onChange={(e) => dispatch(updateData({
                            key: key,
                            column: "nbrMotsMin",
                            data: parseInt(e.target.value)
                        }))}
                    />,
                    nbrMotsMax: <TextField
                        value={item.nbrMotsMax}
                        type={'number'}
                        inputProps={{min: 0}}
                        onChange={(e) => dispatch(updateData({
                            key: key,
                            column: "nbrMotsMax",
                            data: parseInt(e.target.value)
                        }))}
                    />,
                    facturation: <Checkbox
                        checked={item.actualBilling}
                        onChange={(e) => dispatch(updateData({
                            key: key,
                            column: "actualBilling",
                            data: !item.actualBilling
                        }))}
                    />,
                    response: <div>
                        <Checkbox icon={<CheckCircleIcon/>}
                                  checkedIcon={<CheckCircleOutlineIcon style={{color: GREEN}}/>}
                                  checked={checked[key]} onChange={() => {
                            dispatch(updateChecked(key))
                        }}/>
                        <Checkbox icon={<CancelIcon/>} checkedIcon={<HighlightOffIcon/>} checked={!checked[key]}
                                  onChange={() => {
                                      dispatch(updateUnChecked(key))
                                  }}/>
                    </div>
                })
            });
        }
        document.title = "Typix - " + title;
        setData({columns, rows});
    }

    const toggleError = () => setErrorMessage(null)


    const Send = () => {
        setLoading(true);
        axios.post("/Admin/AcceptDemande", {
            idCommande: id,
            accepted: listDemande.filter((item, key) => checked[key]),
            rejected: listDemande.filter((item, key) => !checked[key])
        }).then(() => {
            setLoading(false)
            dispatch(fetchAdminCommandList({
                url,
                title
            }))
        }).catch(e => {
            setLoading(false)
            switch (e.response.status) {
                case 422 :
                    setErrorMessage('Nombre de textes proposé, ne correspond pas à la commande');
                    break;
                default :
                    setErrorMessage('Oups....! Une erreur s\'est produite')
            }
        })
    }

    if (isError) {
        history.push("/Admin/MainMenu")
    }

    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [listDemande, isLoading, checked])


    useEffect(() => {
        try {
            if (listDemande && listDemande.length > 0) {
                setNbrTextAdded(listDemande.filter((item, key) => checked[key])
                    .map(x => x.nbrText)
                    .reduce((prev, next) => prev + next))
            }
        } catch {
            setNbrTextAdded(0)
        }
    }, [listDemande, checked])
    console.log("NBR : ", nbrTextAdded)
    return (
        <div style={{margin: 30}}>
            <Card className={classes.cardContainer}>
                <CardHeader className={classes.cardHeader} title={"Liste des demandes"}
                            action={
                                <Tooltip title="Retour" placement="right">
                                <span>
                                        <ArrowBackIcon style={{fontSize: 40, color: COULEUR_BLEUE, cursor: 'pointer'}}
                                                       onClick={() => history.goBack()}/>
                                </span>
                                </Tooltip>}/>
                <CardContent className={classes.cardBody}>
                    {
                        isLoading ? <React.Fragment>
                                <LinearProgress/>
                                <LinearProgress color="secondary"/>
                            </React.Fragment> :
                            <MDBDataTable striped hoverentriesOptions={[10, 20, 50]} entries={10}
                                          noBottomColumns
                                          className={classes.cardBody}
                                          infoLabel={['', '-', 'sur', 'résultats']}
                                          sortable
                                          disableRetreatAfterSorting
                                          paging={data && data.rows && data.rows.length > 0}
                                          paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                              <div style={{cursor: 'pointer'}}>Suivant</div>]}
                                          onSort={({column, direction}) => {
                                              if (data && data.rows && data.rows.length > 0 && column) {
                                                  dispatch(sortListCommand({column, direction}))
                                              }
                                          }}
                                          searchLabel={'Recherche'}
                                          noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                          info={true}
                                          entriesLabel={'Afficher les résultats'}
                                          data={data}/>
                    }

                </CardContent>
                <CardActions>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography>Nombre de textes restants : {nbrRemaining}</Typography>
                            <Typography>Nombre de textes ajoutées :{nbrTextAdded}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={Send}>
                                Envoyer
                            </Button>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
            <Modal isOpen={errorMessage} toggle={toggleError}>
                <ModalHeader
                    toggle={toggleError}>{errorMessage ? errorMessage : "Oups....! Une erreur s'est produite"}</ModalHeader>
                <ModalBody>
                    <img src={'/Images/error.png'} height={"60%"} width={"60%"}
                         style={{display: 'block', margin: 'auto'}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
