import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from "reactstrap";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Homepage.css"
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Scrollbars} from "react-custom-scrollbars";
import List from "@material-ui/core/List";
import {useDispatch, useSelector} from "react-redux";
import {redactorCommandSummarySelector} from "../../store/Redactor/CommandSummary";
import {redactorClientListSelector} from "../../store/Redactor/ClientList";
import {useHistory} from "react-router-dom";
import {fetchRedactorCommandList} from "../../store/Redactor/CommandList/features";
import {UserSessionSelector} from "../../store/UserSession";
import {fetchCommandSummary} from "../../store/Redactor/CommandSummary/features";
import {fetchClientList} from "../../store/Redactor/ClientList/features";
import ListCommandeClient from "./ListCommandeClient";
import {useTranslation} from "react-i18next";


//TODO finish the commande state
export default function HomePage() {

    const { t } = useTranslation();
    let {user} = useSelector(UserSessionSelector)
    let {
        ordersInProgress,
        ordersDeadlineExceeded,
        ordersCompleted,
        ordersUnassigned,
        isLoading
    } = useSelector(redactorCommandSummarySelector);

    let {
        clients,
        isLoadingClients
    } = useSelector(redactorClientListSelector);


    const dispatch = useDispatch();
    const history = useHistory();

    const [nameSearch, setNameSearch] = useState(null);
    const [showClientCommande, setShowClientCommande] = useState(false);

    const[selectedCommands,setSelectedCommands] = useState(null);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])

    const SelectThis = (input, title) => {
        dispatch(fetchRedactorCommandList({
            url: input,
            title: title,
            id: user.id
        }))
        if (title === "Commandes non-attribuées") {
            history.push("/Rédacteur/AffichageDesCommandesNonAttribuée")
        } else {
            history.push("/Rédacteur/AffichageDesCommandes")
        }
    }


    useEffect(() => {
        dispatch(fetchCommandSummary(user.id));
        dispatch(fetchClientList(user.id));

        document.title = "Typix - Mon tableau de bord";
    }, [])


    return (
        <div>
            <ListCommandeClient visible={showClientCommande}
                                selectedCommands={selectedCommands}
                                Close={() => setShowClientCommande(false)}/>
            <Row>
                <Col>
                    <Typography variant={'h5'} style={{marginBottom : 20}} >Mon tableau de bord</Typography>
                </Col>
            </Row>
            <Row>
                <Col className={"right-form"} xs={5}>
                    <form className={"form"}>
                        <Typography>Mes projets : </Typography>
                        {isLoadingClients ? <CircularProgress color="secondary"/>
                            :
                            (clients && Object.keys(clients).length > 0) ?
                                <div>

                                    <Autocomplete
                                        id="free-solo-2-demo"
                                        noOptionsText={'Aucun résultat'}
                                        freeSolo
                                        options={Object.keys(clients)}
                                        onInputChange={((event, value) => setNameSearch(value))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recherche"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{...params.InputProps, type: 'search'}}
                                            />
                                        )}
                                    />

                                    <Scrollbars autoHeight autoHeightMin={400}>

                                        <List component="nav" aria-label="secondary mailbox folders">
                                            {
                                              Object.keys(clients).filter(x => {
                                                    if (nameSearch && nameSearch.length > 0) {
                                                        return x.includes(nameSearch)
                                                    }
                                                    return true;
                                                }).map((item, key) => <div key={key}>
                                                    <ListItem button onClick={() => {
                                                        setSelectedCommands(clients[item])
                                                        setShowClientCommande(true)
                                                    }}>
                                                        <ListItemText primary={item}/>
                                                    </ListItem>
                                                </div>)
                                            }

                                        </List>

                                    </Scrollbars>

                                </div> : <Typography>Votre liste est vide</Typography>
                        }

                    </form>
                </Col>
                <Col xs={7}>
                    <Row>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        onClick={() => SelectThis("OrdersUnassigned", t('redactor.homepage.ordersUnassigned'))}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                                    <h5>{t('redactor.homepage.ordersUnassigned')}</h5>
                                    <h5>{!isLoading ? ordersUnassigned : "XXX"}</h5>
                                </button>
                            </div>

                        </Col>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersInProgress", t('redactor.homepage.ordersInProgress'))}>
                                    <h5>{t('admin.homepage.ordersInProgress')}</h5>
                                    <h5>{!isLoading ? ordersInProgress : "XXX"}</h5>
                                </button>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersCompleted", t('redactor.homepage.ordersCompleted'))}>
                                    <h5>{t('admin.homepage.ordersCompleted')}</h5>
                                    <h5>{!isLoading ? ordersCompleted : "XXX"}</h5>
                                </button>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersDeadlineExceeded", t('redactor.homepage.ordersDeadlineExceeded'))}>
                                    <h5>{t('redactor.homepage.ordersDeadlineExceeded')}</h5>
                                    <h5>{!isLoading ? ordersDeadlineExceeded : "XXX"}</h5>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </div>
    )
}
