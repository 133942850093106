import React, {Component} from 'react';
import {Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import './NavMenu.css';
import {Badge, Button} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from "@material-ui/core/Tooltip";
import {connect} from "react-redux";
import {fetchClientNavCounter} from "../../store/Client/NavCounter/features";
import {fetchUserSession} from "../../store/UserSession/features";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";


class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            currentPage : window.location.pathname
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchClientNavCounter(this.props.user.id))
        window.addEventListener("locationchange", () => {
            this.setState({currentPage: window.location.pathname})
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let {isLoading, newCommand, newText} = this.props;

        console.log(window.location.pathname)
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/Client/MainMenu"
                                     style={{flexDirection: 'row', display: 'contents'}}><img
                            src={'/Images/NavLogo.png'} height={90} width={400}
                            style={{marginRight: 40, position: 'relative'}}/> </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed}
                                  navbar>
                            <ul className="navbar-nav flex-grow">
                                {
                                    (!isLoading && newCommand > 0) && <NavItem>
                                            <Badge style={{
                                                padding : 10,
                                                backgroundColor: '#009e23',
                                                borderRadius : 10,
                                                fontWeight : "bold",
                                                color: '#ffffff',
                                                marginRight : 15
                                            }}>{newCommand + ((newCommand && newCommand > 1) ? " Nouvelles commandes disponibles" : " Nouvelle commande disponible")}</Badge>
                                    </NavItem>
                                }

                                {
                                    (!isLoading && newText > 0) && <NavItem>
                                            <Badge style={{
                                                padding : 10,
                                                backgroundColor: '#009e23',
                                                borderRadius : 10,
                                                fontWeight : "bold",
                                                color: '#ffffff'
                                            }}>{newText + ((newText && newText > 1) ? " Nouveaux textes disponibles" : " Nouveau texte disponible")}</Badge>
                                    </NavItem>
                                }

                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Client/MonProfile">
                                        <Tooltip title="Mon Profil" placement="right">
                                            <PersonIcon fontSize={'large'}/>
                                        </Tooltip>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">
                                        <Tooltip title="Déconnexion" placement="right">
                                            <ExitToAppIcon fontSize={'large'} onClick={() => {
                                                localStorage.removeItem("User");
                                                this.props.dispatch(fetchUserSession(null))
                                            }}/>
                                        </Tooltip>
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );


    }
}

const mapStateToProps = state => {
    return {
        newCommand: state.clientNavCounter.newCommand,
        isLoading: state.clientNavCounter.isLoading,
        newText: state.clientNavCounter.newText,
        user: state.userSession.user,
    }
}


export default connect(mapStateToProps)(NavMenu)
