import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import {MDBDataTable} from "mdbreact";
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import axios from "axios";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ExpandLess, ExpandMore, Visibility, VisibilityOff} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button as Button2,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    PopoverBody,
    UncontrolledPopover
} from "reactstrap";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import {ISOlanguages} from "../../helpers/Constants";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useDispatch, useSelector} from "react-redux";
import {adminUserManagementSelector} from "../../store/Admin/UserManagement";
import {fetchAdminUserManagement} from "../../store/Admin/UserManagement/features";
import Container from "reactstrap/lib/Container";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {sortListUser} from "../../store/Admin/UserManagement";

//TODO fix the details
const useStyle = makeStyles((theme) => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        backgroundColor: '#e5e4e4'
    },
}))

export default function GestionUtilisateur() {
    let {userList, isLoading} = useSelector(adminUserManagementSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyle();
    const [dataTable, setData] = useState(null);
    const [selected, setSelected] = useState(null);

    const [addShow, setAddShow] = useState(false);
    const [showUpdateRedacteur, setShowUpdateRedacteur] = useState(false);
    const [showUpdateConsultant, setShowUpdateConsultant] = useState(false);
    const [showUpdateClient, setShowUpdateClient] = useState(false);
    const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [editMode, setEditMode] = useState(false);


    const [filterOn, setFilterOn] = useState(false);
    const [role, setRole] = useState('Tous les rôles');
    const [mail, setMail] = useState(null);
    const [nomPrenom, setNomPrenom] = useState(null);
    const [site, setSite] = useState(null);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, [])

    useEffect(() => {
        dispatch(fetchAdminUserManagement())
        document.title = "Typix - Gestion des utilisateurs";
    }, [])

    const ReturnLimit = (item) => {
        const data = JSON.parse(item);
        if (!data.Analysis) {
            data.Analysis = 0
        }
        if (!data.Limite) {
            data.Limite = 0
        }
        return (data.Analysis + "/" + data.Limite)
    }

    const FormatUrlSite = (url) => {
        try {
            let hostname;
            if (url.indexOf("//") > -1) {
                hostname = url.split('/')[2];
            } else {
                hostname = url.split('/')[0];
            }
            hostname = hostname.split(':')[0];
            hostname = hostname.split('?')[0];

            return hostname.replace('www.', '');
        } catch {
            return url;
        }
    }

    const filterTable = (item) => {
        const data = item.rows.filter(x => {
            if (!filterOn) {
                return true;
            }
            if (x.role !== role && role !== 'Tous les rôles') {
                return false;
            }
            if (nomPrenom && nomPrenom.length > 0 && !x.nomPrenom.includes(nomPrenom)) {
                return false;
            }
            if (mail && mail.length > 0 && x.mail && !x.mail.includes(mail)) {
                return false;
            }
            if (site && site.length > 0 && (!x.site || !x.site.includes(site))) {
                return false;
            }
            return true;
        });
        return ({columns: item.columns, rows: data});
    }

    const fetchData = () => {
        const columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Prénom/Nom</div>,
                field: 'nomPrenom',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Mail</div>,
                field: 'mail',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Rôle</div>,
                field: 'role',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Site</div>,
                field: 'site',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Analyse/Limite</div>,
                field: 'analyse',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Modifier</div>,
                field: 'modifier',
                sort: 'disabled',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Supprimer</div>,
                field: 'delete',
                sort: 'disabled',
                width: 250,
            }
        ];
        let rows = [];
        userList.map(item => rows.push({
            nomPrenom: item.nomPrenom,
            mail: item.mail,
            role: item.role === 'Redacteur' ? "Rédacteur" : item.role,
            site: item.role === 'Client' && <TextField id="select" disabled={item.urlSite.length === 0} label="Site"
                                                       value={item.urlSite.length > 0 ? item.urlSite[0] : "Aucun site associée"}
                                                       select={item.urlSite.length > 0}>
                {
                    item.urlSite.length > 0 &&
                    item.urlSite.map((x, key) => <MenuItem key={key} value={x}>{FormatUrlSite(x)}</MenuItem>)
                }
            </TextField>,
            analyse: item.role === "Redacteur" && ReturnLimit(item.data),
            modifier:
                <div style={{cursor: 'pointer'}}>

                    <EditIcon onClick={() => {
                        setEditMode(true)
                        setSelected(JSON.parse(item.data));
                        // eslint-disable-next-line default-case
                        switch (item.role) {
                            case "Redacteur":
                                setShowUpdateRedacteur(true);
                                break;
                            case "Consultant":
                                setShowUpdateConsultant(true);
                                break;
                            case "Client":
                                setShowUpdateClient(true);
                                break;
                            case "Admin":
                                setShowUpdateAdmin(true);
                                break;
                        }
                    }
                    }/></div>,
            delete: <div style={{cursor: 'pointer'}}><DeleteSweepIcon onClick={() => {
                const data = JSON.parse(item.data)
                setSelected({
                    id: data.Id,
                    mail: data.Mail,
                    role: item.role
                })
                setShowDelete(true)
            }}/></div>
        }));

        setData({columns, rows});
    }
    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [userList, isLoading])

    if (!dataTable) {
        return (
            <Container>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </Container>
        )
    }
    //<ArrowBackIosIcon/>
    return (
        <Container>
            <Card className={classes.cardContainer}>
                <CardHeader className={classes.cardHeader} title={"Gestion des utilisateurs"}
                            action={
                                <React.Fragment>

                                    <Tooltip title="Retour">
                                        <ArrowBackIosIcon style={{cursor: 'pointer'}}
                                                          onClick={() => history.push("/Admin/MainMenu")}/>
                                    </Tooltip>
                                    <div style={{display: '-webkit-inline-box',}}>
                                        <Button2 color="info" id={"PopoverFocus"} outline type="button">
                                            <img src={'/Images/filtre.png'} height={25} width={25} alt={''}/>
                                        </Button2>
                                        <List>
                                            <div style={{display: 'block'}}>
                                                <ListItem button onClick={() => setAddShow(!addShow)}>
                                                    <ListItemText primary="Ajouter un utilisateur"/>
                                                    {addShow ? <ExpandLess/> : <ExpandMore/>}
                                                </ListItem>
                                                <Collapse in={addShow} timeout="auto" unmountOnExit>
                                                    <List component="div" style={{align: 'right'}}>
                                                        <ListItem button className={classes.nested} onClick={() => {
                                                            setEditMode(false)
                                                            setShowUpdateRedacteur(true);
                                                        }}>
                                                            <ListItemText primary="Ajouter un rédacteur"/>
                                                        </ListItem>
                                                        <ListItem button className={classes.nested} onClick={() => {
                                                            setEditMode(false)
                                                            setShowUpdateClient(true);
                                                        }}>
                                                            <ListItemText primary="Ajouter un client"/>
                                                        </ListItem>
                                                        <ListItem button className={classes.nested} onClick={() => {
                                                            setEditMode(false)
                                                            setShowUpdateConsultant(true);
                                                        }}>
                                                            <ListItemText primary="Ajouter un consultant"/>
                                                        </ListItem>
                                                        <ListItem button className={classes.nested} onClick={() => {
                                                            setEditMode(false)
                                                            setShowUpdateAdmin(true);
                                                        }}>
                                                            <ListItemText primary="Ajouter un admin"/>
                                                        </ListItem>
                                                    </List>
                                                </Collapse>
                                            </div>


                                            <UncontrolledPopover trigger="click" placement="right"
                                                                 target={"PopoverFocus"}>
                                                <PopoverBody>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <h3>FILTRE</h3>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Select
                                                                labelId="language-label"
                                                                id="language-select"
                                                                inputProps={{
                                                                    style: {
                                                                        margin: 15
                                                                    }
                                                                }}
                                                                value={role}
                                                                onChange={(e) => setRole(e.target.value)}
                                                            >
                                                                <MenuItem value={'Tous les rôles'}>Tous les
                                                                    rôles</MenuItem>
                                                                <MenuItem value={'Client'}>Client</MenuItem>
                                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                                <MenuItem value={'Rédacteur'}>Rédacteur</MenuItem>
                                                                <MenuItem value={'Consultant'}>Consultant</MenuItem>
                                                            </Select>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label={"Recherche par 'mail'"}
                                                                value={mail}
                                                                onChange={e => setMail(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label={"Recherche par 'prénom/Nom'"}
                                                                fullWidth
                                                                value={nomPrenom}
                                                                onChange={e => setNomPrenom(e.target.value)}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label={"Recherche par 'site'"}
                                                                value={site}
                                                                onChange={e => setSite(e.target.value)}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>

                                                            <Button mode={'contained'}
                                                                    onClick={() => setFilterOn(!filterOn)}>
                                                                {
                                                                    !filterOn ? "Appliquer" : "Annuler"
                                                                }
                                                            </Button>
                                                        </Grid>

                                                    </Grid>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </List>
                                    </div>
                                </React.Fragment>}/>
                <CardContent>
                    <MDBDataTable striped hover entriesOptions={[10, 20, 50]} entries={10}
                                  noBottomColumns
                                  btn={true}
                                  infoLabel={['', '-', 'sur', 'résultats']}
                                  paginationLabel={["Précédent", "Suivant"]}
                                  sortable
                                  onSort={({column, direction}) => {
                                      if (dataTable && dataTable.rows && dataTable.rows.length > 0 && column) {
                                          dispatch(sortListUser({column, direction}))
                                      }
                                  }}
                                  paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
                                  searchLabel={'Recherche'}
                                  noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                  info={true}
                                  entriesLabel={'Afficher les résultats'}
                                  data={filterTable(dataTable)}/>

                </CardContent>
            </Card>


            {showUpdateRedacteur &&
            <ReacteurForm show={showUpdateRedacteur} selected={selected} editMode={editMode}
                          dispatch={dispatch}
                          Close={() => {
                              setShowUpdateRedacteur(false)
                          }}/>}
            {showUpdateClient &&
            <ClientForm show={showUpdateClient} selected={selected} Close={() => {
                setShowUpdateClient(false)
            }}
                        editMode={editMode}/>}
            {showUpdateConsultant &&
            <ConsultantForm show={showUpdateConsultant} selected={selected} editMode={editMode}
                            Close={() => {
                                setShowUpdateConsultant(false)
                            }}/>}
            {showUpdateAdmin &&
            <AdminForm show={showUpdateAdmin} selected={selected} editMode={editMode}
                       Close={() => {
                           setShowUpdateAdmin(false)
                       }}/>}
            {showDelete &&
            <DeleteUser show={showDelete} selected={selected}
                        Close={() => {
                            setShowDelete(false)
                        }}/>}
        </Container>
    )
}

const DeleteUser = (props) => {
    const dispatch = useDispatch();
    const Delete = () => axios.get("/Admin/DeleteUser?id=" + props.selected.id + "&role=" + props.selected.role + "&mail=" + props.selected.mail)
        .then(() => {
            dispatch(fetchAdminUserManagement())
            props.Close()
        })
    return (
        <Modal isOpen={props.show} toggle={props.Close}>
            <ModalHeader>
                Voulez-vous supprimer cet utilisateur ?
            </ModalHeader>
            <ModalFooter>
                <Button variant={"contained"} style={{margin: 15}} color={"primary"} onClick={Delete}>Oui</Button>
                <Button variant={"contained"} style={{margin: 15}} color={"primary"}
                        onClick={props.Close}>Annuler</Button>
            </ModalFooter>
        </Modal>
    )
}

const ReacteurForm = (props) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [nomPrenom, setNomPrenom] = useState('');
    const [nomSociete, setNomSociete] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [thematique, setThematique] = useState([]);
    const [listLangue, setListLangue] = useState([]);
    const [otherLangue, setOtherLangue] = useState([]);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [analysis, setAnalysis] = useState(0);
    const [limit, setLimit] = useState(0);

    const [loading, setLoading] = useState(false);

    const [mailExist, setMailExist] = useState(false)
    const [ThématiqueList, setThématiqueList] = useState([]);
    useEffect(() => {
        axios.get("/Admin/GetListThematique").then(res => setThématiqueList(res.data))
    }, [])
    const ModifyState = (props && props.selected && props.editMode);
    const CheckEmailExist = async () => axios.get("/Admin/CheckEmailExist?mail=" + mail).then(res => res.data);

    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || password.length === 0 || nomPrenom.length === 0
            || nomSociete.length === 0 || adresse.length === 0 || thematique.length === 0 || (listLangue.length === 0 && otherLangue.length === 0)) {
            return true
        }
        if (loading) {
            return true
        }
        if ((!mail.match(emailcheck) && mail.length > 0) || mailExist) {
            return true
        }
        return CheckPasswordValid();

    }

    const CheckPasswordValid = () => {
        if (props && props.selected && props.selected.MotDePasse === password) {
            return false;
        }
        return !passwordCheck.test(password) && password.length > 0;

    }
    const Add = () => {
        setLoading(true)
        CheckEmailExist().then(value => {
            setMailExist(value)
            if (!value) {
                axios.post("/Admin/AddRedacteur", {
                    redacteur: {
                        mail: mail,
                        nomPrenom: nomPrenom,
                        motDePasse: password,
                        numTelephone: telephone,
                        nomSociete: nomSociete,
                        adresse: adresse,
                        limite: limit,
                        analysis: analysis
                    },
                    langue: listLangue.concat(otherLangue),
                    thematique: thematique
                }).then(() => {
                    dispatch(fetchAdminUserManagement())
                    setLoading(false)
                    props.Close()
                })
            }
        })
    }

    const Update = () => {
        setLoading(true)
        axios.post("/Admin/UpdateRedacteur", {
            redacteur: {
                id: props.selected.Id,
                mail: mail,
                nomPrenom: nomPrenom,
                motDePasse: password,
                numTelephone: telephone,
                nomSociete: nomSociete,
                adresse: adresse,
                limite: limit,
                analysis: analysis
            },
            langue: listLangue.concat(otherLangue),
            thematique: thematique,
            passwordChanged: (props.selected.MotDePasse !== password)
        }).then(() => {
            dispatch(fetchAdminUserManagement())
            setLoading(false)
            props.Close()
        })
    }
    useEffect(() => {
        if (props && props.selected && props.editMode) {
            setThematique(props.selected.RedacteurThematique.map(x => x.Thematique));
            setMail(props.selected.Mail);
            setPassword(props.selected.MotDePasse);
            setNomPrenom(props.selected.NomPrenom);
            setLimit(props.selected.Limite ? props.selected.Limite : 0)
            setAnalysis(props.selected.Analysis ? props.selected.Analysis : 0)
            setTelephone(props.selected.NumTelephone);
            setNomSociete(props.selected.NomSociete);
            setAdresse(props.selected.Adresse);
            setListLangue(props.selected.RedacteurLangue.filter(x => x.Langue === "Français" || x.Langue === "Italien" || x.Langue === "Espagnol" || x.Langue === "Allemand" || x.Langue === "Anglais").map(x => x.Langue));
            setOtherLangue(props.selected.RedacteurLangue.filter(x => x.Langue !== "Français" && x.Langue !== "Italien" && x.Langue !== "Espagnol" && x.Langue !== "Allemand" && x.Langue !== "Anglais").map(x => x.Langue));
        }
    }, [])
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return (
        <Modal isOpen={props.show} toggle={props.Close}>
            <ModalHeader>{props.editMode ? "Modifier Rédacteur" : "Ajouter Rédacteur"}</ModalHeader>
            <ModalBody>
                <div style={{margin: 5}}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                            htmlFor="standard-adornment-password">Mail</InputLabel>
                                <Input
                                    value={mail}
                                    onChange={e => setMail(e.target.value)}
                                    onFocus={() => mailExist && setMailExist(false)}
                                    disabled={ModifyState}
                                    error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                />
                                {((!mail.match(emailcheck) && mail.length > 0) || mailExist) &&
                                <FormHelperText
                                    error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}>{mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel error={CheckPasswordValid()}>Mot de passe</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={CheckPasswordValid()}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText error={CheckPasswordValid()}>-Avoir au moins 8 caractères avec une
                                    lettre majuscule, un chiffre et ne pas être identique au
                                    nom</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-password">Prénom/Nom</InputLabel>
                                <Input placeholder="Prénom/Nom" type={"text"} value={nomPrenom}
                                       onChange={e => setNomPrenom(e.target.value)}
                                       inputProps={{'aria-label': 'description'}}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                label={"Nom société"}
                                value={nomSociete}
                                onChange={e => setNomSociete(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Adresse"}
                                value={adresse}
                                onChange={e => setAdresse(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel>Numéro de téléphone</InputLabel>
                                <Input
                                    value={telephone}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setTelephone(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel style={{marginTop: 20, marginBottom: 20}}>Langues :</InputLabel>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Français")}
                                        onChange={() => listLangue.includes("Français") ? setListLangue(listLangue.filter(x => x !== "Français")) : setListLangue([...listLangue, "Français"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Français"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Italien")}
                                        onChange={() => listLangue.includes("Italien") ? setListLangue(listLangue.filter(x => x !== "Italien")) : setListLangue([...listLangue, "Italien"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Italien"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Anglais")}
                                        onChange={() => listLangue.includes("Anglais") ? setListLangue(listLangue.filter(x => x !== "Anglais")) : setListLangue([...listLangue, "Anglais"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Anglais"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Allemand")}
                                        onChange={() => listLangue.includes("Allemand") ? setListLangue(listLangue.filter(x => x !== "Allemand")) : setListLangue([...listLangue, "Allemand"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Allemand"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Espagnol")}
                                        onChange={() => listLangue.includes("Espagnol") ? setListLangue(listLangue.filter(x => x !== "Espagnol")) : setListLangue([...listLangue, "Espagnol"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Espagnol"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Autre</InputLabel>
                                <Select
                                    label={"Autre"}
                                    multiple
                                    value={otherLangue}
                                    onChange={(event) => {
                                        setOtherLangue(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'name',
                                        id: 'langue-native-simple',
                                    }}
                                    MenuProps={MenuProps}
                                    input={<Input/>}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em> Menu déroulant </em>;
                                        }
                                        return (<div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip}/>
                                            ))}
                                        </div>)
                                    }}
                                >
                                    {
                                        ISOlanguages.filter(x => !['Français', 'Italien', 'Allemand', 'Espagnol', 'Anglais'].includes(x.name)).sort(function (a, b) {
                                            if (a.name > b.name) {
                                                return 1;
                                            }
                                            if (b.name > a.name) {
                                                return -1;
                                            }
                                            return 0;
                                        }).map((item, key) =>
                                            <MenuItem key={key} value={item.name}>
                                                <Checkbox checked={otherLangue.indexOf(item.name) > -1}/>
                                                <ListItemText primary={item.name}/>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth style={{marginTop: 20, marginBottom: 20}}>
                                <InputLabel shrink>Thématiques d’expertise </InputLabel>
                                <Select
                                    multiple
                                    value={thematique}
                                    onChange={(event) => {
                                        setThematique(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'thematique',
                                        id: 'thematique-native-simple',
                                    }}
                                    MenuProps={MenuProps}
                                    input={<Input/>}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em> Menu déroulant </em>;
                                        }
                                        return (<div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip}/>
                                            ))}
                                        </div>)
                                    }}
                                >
                                    <option aria-label="None" value={0}/>
                                    {
                                        ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                                <Checkbox checked={thematique.indexOf(item) > -1}/>
                                                <ListItemText primary={item}/>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Limite"}
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{analysis + "/"}</InputAdornment>,
                                }}
                                value={limit}
                                onChange={e => e.target.value.length === 0 ? setLimit(0) : Number.isInteger(parseInt(e.target.value)) && setLimit(parseInt(e.target.value))}
                            />
                        </Grid>


                    </Grid>

                </div>
            </ModalBody>
            <ModalFooter>
                {
                    props.editMode ?
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Update}>Sauvegarder</Button> :
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Add}>Ajouter</Button>
                }

            </ModalFooter>
        </Modal>

    )
}

const ConsultantForm = (props) => {
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [nomPrenom, setNomPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    useEffect(() => {
        if (props && props.selected && props.editMode) {
            setMail(props.selected.Mail);
            setPassword(props.selected.MotDePasse);
            setNomPrenom(props.selected.NomPrenom);
            setTelephone(props.selected.NumTelephone);
        }
    }, [])

    const [mailExist, setMailExist] = useState(false)

    const ModifyState = (props && props.selected && props.editMode);
    const CheckEmailExist = async () => axios.get("/Admin/CheckEmailExist?mail=" + mail).then(res => res.data);
    const Update = () => {
        setLoading(true);
        axios.post("/Admin/UpdateConsultant?passwordChanged=" + (props.selected.MotDePasse !== password), {
            id: props.selected.Id,
            motDePasse: password,
            nomPrenom: nomPrenom,
            numTelephone: telephone,
            mail: mail
        }).then(() => {
            dispatch(fetchAdminUserManagement())
            setLoading(false)
            props.Close()
        })
    }

    const Add = () => {
        setLoading(true);
        CheckEmailExist().then(value => {
            setMailExist(value)
            if (!value) {
                axios.post("/Admin/AddConsultant", {
                    motDePasse: password,
                    nomPrenom: nomPrenom,
                    numTelephone: telephone,
                    mail: mail
                }).then(() => {
                    dispatch(fetchAdminUserManagement())
                    setLoading(false)
                    props.Close()
                })
            }
        })
    }

    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || password.length === 0 || nomPrenom.length === 0) {
            return true
        }
        if (loading) {
            return true
        }
        if ((!mail.match(emailcheck) && mail.length > 0) || mailExist) {
            return true
        }
        return CheckPasswordValid();

    }

    const CheckPasswordValid = () => {
        if (props && props.selected && props.selected.MotDePasse === password) {
            return false;
        }
        return !passwordCheck.test(password) && password.length > 0;

    }
    return (
        <Modal isOpen={props.show} toggle={props.Close}>
            <ModalHeader>{props.editMode ? "Modifier Consultant" : "Ajouter Consultant"}</ModalHeader>
            <ModalBody>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                        htmlFor="standard-adornment-password">Mail</InputLabel>
                            <Input
                                value={mail}
                                onChange={e => setMail(e.target.value)}
                                onFocus={() => mailExist && setMailExist(false)}
                                disabled={ModifyState}
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                            />
                            {((!mail.match(emailcheck) && mail.length > 0) || mailExist) &&
                            <FormHelperText
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}>{mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel error={CheckPasswordValid()}>Mot de passe</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                error={CheckPasswordValid()}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error={CheckPasswordValid()}>-Avoir au moins 8 caractères avec une lettre
                                majuscule, un chiffre et ne pas être identique au
                                nom</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="standard-adornment-password">Prénom/Nom</InputLabel>
                            <Input placeholder="Prénom/Nom" type={"text"} value={nomPrenom}
                                   onChange={e => setNomPrenom(e.target.value)}
                                   inputProps={{'aria-label': 'description'}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Numéro de téléphone"}
                            value={telephone}
                            onChange={e => {
                                const re = /^(?=.*\d)[\d ]+$/;
                                if (e.target.value === '' || re.test(e.target.value)) {
                                    setTelephone(e.target.value)
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalBody>
            <ModalFooter>
                {
                    props.editMode ?
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Update}>Sauvegarder</Button> :
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Add}>Ajouter</Button>
                }

            </ModalFooter>
        </Modal>
    )
}


const ClientForm = (props) => {
    const dispatch = useDispatch()

    const [clientName, setClientName] = useState('')

    const [mail, setMail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const [mailExist, setMailExist] = useState(false)
    const [compte, setCompte] = useState('');
    const [pixOpData, setPixOpData] = useState([]);
    const ModifyState = (props && props.selected && props.editMode);
    const CheckEmailExist = async () => axios.get("/Admin/CheckEmailExist?mail=" + mail).then(res => res.data)
    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || password.length === 0 || clientName.length === 0 || !compte) {
            return true
        }
        if (loading) {
            return true
        }
        if ((!mail.match(emailcheck) && mail.length > 0) || mailExist) {
            return true
        }
        return CheckPasswordValid();

    }

    const CheckPasswordValid = () => {
        if (props && props.selected && props.selected.MotDePasse === password) {
            return false;
        }
        return !passwordCheck.test(password) && password.length > 0;

    }
    useEffect(() => {
        if (props && props.selected && props.editMode) {
            setMail(props.selected.Mail);
            setClientName(props.selected.NomPrenom);
            setTelephone(props.selected.NumTelephone);
            setPassword(props.selected.MotDePasse);
        }
        axios.get("/Admin/PixOpsClient").then(res => {
            setPixOpData(res.data)
            if (props && props.selected && props.editMode) {
                setCompte(res.data[0].clientName)
            }
        });
    }, [])

    const Add = () => {
        setLoading(true)
        CheckEmailExist().then(value => {
            setMailExist(value);
            if (!value) {
                axios.post("/Admin/AddClientWithoutWebsite", {
                    mail: mail,
                    motDePasse: password,
                    nomPrenom: clientName,
                    numTelephone: telephone,
                    compte: props.editMode ? props.selected.Compte : compte,
                }).then(() => {
                    dispatch(fetchAdminUserManagement())
                    setLoading(false)
                    props.Close()
                })
            } else {
                setLoading(false)
            }
        })
    }
    const Update = () => {
        setLoading(true)
        axios.post("/Admin/UpdateClientWithoutWebsite?passwordChanged=" + (props.selected.MotDePasse !== password), {
            id: props.selected.Id,
            mail: mail,
            motDePasse: password,
            nomPrenom: clientName,
            numTelephone: telephone,
            compte: compte,
        }).then(() => {
            dispatch(fetchAdminUserManagement())
            setLoading(false)
            props.Close()
        })
    }
    return (
        <Modal isOpen={props.show} toggle={props.Close} size={'lg'}>
            <ModalHeader>{props.editMode ? "Modifier Client" : "Ajouter Client"}</ModalHeader>
            <ModalBody>
                <div style={{margin: 15}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label={"Prénom/Nom"}
                                fullWidth
                                value={clientName}
                                onChange={e => setClientName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Téléphone</InputLabel>
                                <Input
                                    value={telephone}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setTelephone(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel error={CheckPasswordValid()}>Mot de passe</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={CheckPasswordValid()}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText error={CheckPasswordValid()}>Avoir au moins 8 caractères avec
                                    une lettre majuscule, un chiffre et ne pas être identique au
                                    nom</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={"Mail"}
                                fullWidth
                                disabled={ModifyState}
                                onFocus={() => mailExist && setMailExist(false)}
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                helperText={mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}
                                value={mail}
                                onChange={e => setMail(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {
                                props.editMode ?
                                    <TextField
                                        label={"Compte client"}
                                        disabled
                                        value={props.selected.Compte}
                                        contentEditable={false}
                                        suppressContentEditableWarning={true}
                                    /> :
                                    (pixOpData.length > 0 ?
                                        <Autocomplete
                                            id="compte-client"
                                            disableClearable
                                            options={pixOpData.map(x => x.clientName)}
                                            value={compte}
                                            onChange={((event, value) => {
                                                setCompte(value)
                                            })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={"Compte client"}
                                                    margin="normal"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search'
                                                    }}
                                                />
                                            )}
                                        /> : <LinearProgress/>)


                            }


                        </Grid>
                    </Grid>
                </div>

            </ModalBody>
            <ModalFooter>
                {
                    props.editMode ?
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Update}>Sauvegarder</Button> :
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Add}>Ajouter</Button>
                }

            </ModalFooter>
        </Modal>
    )
}


const AdminForm = (props) => {
    const dispatch = useDispatch();
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [mail, setMail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false);
    const emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const [mailExist, setMailExist] = useState(false)

    const ModifyState = (props && props.selected && props.editMode);
    const CheckEmailExist = async () => axios.get("/Admin/CheckEmailExist?mail=" + mail).then(res => res.data);

    useEffect(() => {
        if (props && props.selected && props.editMode) {
            setMail(props.selected.Mail);
            setPassword(props.selected.MotDePasse);
            setNom(props.selected.Nom);
            setPrenom(props.selected.Prenom);
            setTelephone(props.selected.NumTelephone);
        }
    }, [])

    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const CheckValidForm = () => {
        if (mail.length === 0 || telephone.length === 0 || password.length === 0 || nom.length === 0 || prenom.length === 0
            || telephone.length === 0) {
            return true
        }
        if (loading) {
            return true
        }
        if ((!mail.match(emailcheck) && mail.length > 0) || mailExist) {
            return true
        }
        return CheckPasswordValid();

    }

    const CheckPasswordValid = () => {
        if (props && props.selected && props.selected.MotDePasse === password) {
            return false;
        }
        return !passwordCheck.test(password) && password.length > 0;

    }
    const Update = () => {
        setLoading(true);
        axios.post("/Admin/UpdateAdmin?passwordChanged=" + (props.selected.MotDePasse !== password), {
            id: props.selected.Id,
            motDePasse: password,
            nom: nom,
            prenom: prenom,
            numTelephone: telephone,
            mail: mail
        }).then(() => {
            dispatch(fetchAdminUserManagement())
            setLoading(false)
            props.Close()
        })
    }

    const Add = () => {
        setLoading(true);
        CheckEmailExist().then(value => {
            setMailExist(value);
            if (!value) {
                axios.post("/Admin/AddAdmin", {
                    motDePasse: password,
                    nom: nom,
                    prenom: prenom,
                    numTelephone: telephone,
                    mail: mail
                }).then(() => {
                    dispatch(fetchAdminUserManagement())
                    setLoading(false)
                    props.Close()
                })
            }
        })
    }
    return (
        <Modal isOpen={props.show} toggle={props.Close}>
            <ModalHeader>{props.editMode ? "Modifier Admin" : "Ajouter Admin"}</ModalHeader>
            <ModalBody>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                                        htmlFor="standard-adornment-password">Mail</InputLabel>
                            <Input
                                value={mail}
                                onChange={e => setMail(e.target.value)}
                                onFocus={() => mailExist && setMailExist(false)}
                                disabled={ModifyState}
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}
                            />
                            {((!mail.match(emailcheck) && mail.length > 0) || mailExist) &&
                            <FormHelperText
                                error={(!mail.match(emailcheck) && mail.length > 0) || mailExist}>{mailExist ? 'Email déjà existant' : !mail.match(emailcheck) && mail.length > 0 ? "Email non valide" : ''}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel error={CheckPasswordValid()}>Mot de passe</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                error={CheckPasswordValid()}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error={CheckPasswordValid()}>-Avoir au moins 8 caractères avec une lettre
                                majuscule, un chiffre et ne pas être identique au
                                nom</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">Nom</InputLabel>
                            <Input placeholder="Nom" type={"text"} value={nom}
                                   onChange={e => setNom(e.target.value)}
                                   inputProps={{'aria-label': 'description'}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">Prénom</InputLabel>
                            <Input placeholder="Prénom" type={"text"} value={prenom}
                                   onChange={e => setPrenom(e.target.value)}
                                   inputProps={{'aria-label': 'description'}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Numéro de téléphone</InputLabel>
                            <Input
                                value={telephone}
                                onChange={e => {
                                    const re = /^(?=.*\d)[\d ]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        setTelephone(e.target.value)
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </ModalBody>
            <ModalFooter>
                {
                    props.editMode ?
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Update}>Sauvegarder</Button> :
                        <Button variant="contained" color="primary" disabled={CheckValidForm()} style={{margin: 10}}
                                onClick={Add}>Ajouter</Button>
                }

            </ModalFooter>
        </Modal>
    )
}
