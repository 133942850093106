import {createSlice} from '@reduxjs/toolkit'
import {fetchAdminListDesDemandes} from "./features";


export const adminListDesDemandes = createSlice({
    name: 'adminListDesDemandes',
    initialState: {
        listDemande: null,
        checked : null,
        nbrRemaining : 0,
        title: null,
        id: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateChecked: (state, action) => {
            let value = state.checked;
            value[action.payload] = true;
            state.checked = value
        },
        updateData: (state, {payload}) => {
            let value = state.listDemande;
            value[payload.key][payload.column] = payload.data;
            state.listDemande = value
        },
        updateUnChecked: (state, action) => {
            let value = state.checked;
            value[action.payload] = false;
            state.checked = value
        },
        sortListCommand: (state, {payload}) => {
            state.listDemande = state.listDemande.sort((a, b) => {
                if (payload.direction === "asc") {
                    return a[payload.column] - b[payload.column];
                } else {
                    return b[payload.column] - a[payload.column];
                }
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAdminListDesDemandes.fulfilled, (state, {payload}) => {
            state.listDemande = payload.data;
            state.checked = Array.from(payload.data,(_) => false)
            state.title = payload.title;
            state.nbrRemaining = payload.nbrRemaining;
            state.id = payload.id;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchAdminListDesDemandes.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchAdminListDesDemandes.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminListDesDemandes.reducer


export const {updateChecked, updateUnChecked,updateData,sortListCommand} = adminListDesDemandes.actions

export const adminListDesDemandesSelector = (state) => state.adminListDesDemandes;
