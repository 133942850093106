import {Document, Page, pdf, PDFDownloadLink, StyleSheet, Text, View, Image} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import "./PdfContent.css"
import {saveAs} from 'file-saver';
import {renderToString} from 'react-dom/server'
import CircularProgress from "@material-ui/core/CircularProgress";

import jsPDF from "jspdf";
import {NumberOfWords} from "./Constants";
import {Col} from "reactstrap";
import {useSelector} from "react-redux";
import {UserSessionSelector} from "../store/UserSession";

const styles = StyleSheet.create({
    title: {
        fontSize: 20, fontWeight: 'bold', fontStyle: 'normal', textAlign: 'center'
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    underlined: {
        fontSize: 14.1, fontWeight: 'bold', fontStyle: 'normal', textDecoration: 'underline'
    },
    boldText: {
        fontSize: 14.1, fontWeight: 'bold', fontStyle: 'normal', marginTop: 20, marginBottom: 20
    },
    normalText: {
        fontSize: 14.1, fontWeight: 'normal', fontStyle: 'normal'
    },
    row: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 15, marginTop: 15
    },
    image: {
        height: 200,
        width: 200
    }
});


export const MyDoc = (props) => {
    return (<Document>
        <Page>
            <View style={{display: 'flex', justifyContent: 'space-evenly', margin: 70}}>
                <View style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 25}}>
                    <Text style={{fontSize: 20, fontWeight: 'bold', fontStyle: 'normal'}}>
                        Comparaison hors ligne
                    </Text>
                </View>


                <View style={styles.row}><Text style={styles.underlined}>Mail</Text><Text
                    style={styles.normalText}>: {props.user.mail}</Text></View>
                <View style={styles.row}><Text style={styles.underlined}>Date</Text><Text
                    style={styles.normalText}>: {new Date().getDate()}/{(new Date().getMonth() + 1)}/{new Date().getFullYear()}</Text><View
                    style={{marginLeft: 25}}/><Text style={styles.underlined}>Temps</Text><Text
                    style={styles.normalText}>: {new Date().getHours()}:{new Date().getMinutes()}</Text></View>
                <View><Text style={styles.boldText}>Mots-clés avec fréquence:</Text></View>
                <View><Text style={styles.normalText}>{props.keywords}</Text></View>
                <View><Text style={styles.boldText}>Texte:</Text></View>

                <View><Text style={styles.normalText}>{props.text}</Text></View>
                <View style={{
                    marginTop: 25,
                    marginBottom: 25
                }}><Text>--------------------------------------------------------------------------</Text></View>
                {
                    (props.result !== null) && <View>
                        <Text style={styles.boldText}>Résultats</Text>
                        {
                            (props.result.semanticEnrichment !== null) && <View style={styles.row}>
                                <Text style={styles.normalText}>Enrichissement sémantique : </Text>
                                <Text style={{color: props.color}}>{parseInt(props.result.semanticEnrichment)}%</Text>
                            </View>
                        }

                    </View>
                }
                {
                    (props.result && props.result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) &&
                    <View>
                        <View><Text style={styles.boldText && {marginBottom: 20, marginTop: 20}}>Ajout de mots-clés avec
                            la
                            fréquence restante : </Text></View>
                        <View>
                            <Text>
                                {Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {
                                    return (
                                        <Text style={styles.normalText} key={key}>{item}(
                                            <Text
                                                style={{textDecoration: 'line-through'}}>{props.convertedText[item]},</Text>
                                            <Text>{props.result.greenWordsByFreq.greenWordsWithRemainingFreq[item]}) </Text>
                                        </Text>
                                    )
                                })}
                            </Text>
                        </View>
                    </View>
                }
                {
                    (props.result && props.result.remainingKeywords && Object.keys(props.result.remainingKeywords).length > 0) &&
                    <View>
                        <View><Text style={styles.boldText && {marginBottom: 20, marginTop: 20}}>Mots clés
                            restants:</Text></View>
                        <View>
                            <Text style={styles.normalText}>

                                {Object.keys(props.result.remainingKeywords).map((item, key) => {
                                    let word = item + '(' + props.result.remainingKeywords[item] + ') '
                                    return (word)
                                })}

                            </Text>
                        </View>
                    </View>
                }
            </View>

        </Page>
    </Document>)
}
let render = require('quill-render');

export const PdfView = (props) => {
    let content = null;
    if (props.htmlText) {
        if (typeof props.htmlText === "string") {
            content = JSON.parse(props.htmlText);
        } else {
            content = props.htmlText
        }
    }
    return (
        <React.Fragment>
            <div style={{marginBottom: 25}}>
                <span style={styles.title}>
                    Comparaison &nbsp; hors &nbsp; ligne
                </span>
            </div>

            <div>
                <span style={styles.underlined}>Mail</span>
                <span
                    style={styles.normalText}>: {props.user.mail}
                </span></div>
            <div><span style={styles.underlined}>Date</span><span
                style={styles.normalText}>: {new Date().getDate()}/{(new Date().getMonth() + 1)}/{new Date().getFullYear()}</span>
                <div
                    style={{marginLeft: 25}}/>
                <span style={styles.underlined}>Heures  &nbsp;</span><span
                    style={styles.normalText}>: {new Date().getHours()}:{new Date().getMinutes()}</span></div>
            <div><span style={styles.boldText}>Mots &nbsp; - &nbsp; clés &nbsp; avec &nbsp; fréquence:</span></div>
            <div><span style={styles.normalText}>{props.keywords}</span></div>
            {
                props.text && <div><span style={styles.underlined}>Nombre de mots</span><span
                    style={styles.normalText}>: {NumberOfWords(props.text)}</span></div>
            }

            <div><span style={styles.boldText}>Texte:  &nbsp;</span></div>
            {
                (props.htmlText || props.text) &&
                props.htmlText ?
                    <div dangerouslySetInnerHTML={{__html: render(content['ops'])}}/>
                    : <div><span style={styles.normalText}>{props.text}</span></div>
            }


            <div style={{
                marginTop: 25,
                marginBottom: 25
            }}><span>--------------------------------------------------------------------------</span></div>
            {
                props.result && <div>
                    <span style={styles.boldText}>Résultats</span>
                    {
                        (props.result.semanticEnrichment !== null) && <div style={styles.row}>
                            <span style={styles.normalText}>Enrichissement sémantique : </span>
                            <span
                                style={{color: props.color}}>{parseInt(props.result.semanticEnrichment)}%</span>
                        </div>
                    }
                </div>
            }
            {
                (props.result && props.result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) &&
                <div>
                    <div><span style={styles.boldText && {
                        marginBottom: 20,
                        marginTop: 20
                    }}>{"Ajout de mots-clés avec la fréquence restante :"}</span></div>
                    <div>
                        <span>
                            {Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {
                                return (
                                    <span style={styles.normalText} key={key}>{item}(
                                        <span
                                            style={{textDecoration: 'line-through'}}>{props.convertedText[item]},</span>
                                        <span>{props.result.greenWordsByFreq.greenWordsWithRemainingFreq[item]}) </span>
                                    </span>
                                )
                            })}
                        </span>
                    </div>
                </div>
            }
            {
                (props.result && props.result.remainingKeywords && Object.keys(props.result.remainingKeywords).length > 0) &&
                <div>
                    <div><span
                        style={styles.boldText && {
                            marginBottom: 20,
                            marginTop: 20
                        }}>{"Mots clés restants:"}</span>
                    </div>
                    <div>
                        <span style={styles.normalText}>

                            {Object.keys(props.result.remainingKeywords).map((item, key) => {
                                let word = item + '(' + props.result.remainingKeywords[item] + ') '
                                return (word)
                            })}

                        </span>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export const setKeyWordList = (input) => {
    let list = {};
    let keywords = input;
    if (keywords[keywords.indexOf(')') + 1] === ',') {
        const lines = keywords.split(',');
        const firstItem = lines[0];
        if (firstItem.length > 0) {
            const number = parseInt(firstItem.substring(
                firstItem.lastIndexOf("(") + 1,
                firstItem.lastIndexOf(")")));
            const value = firstItem.substring(0, firstItem.indexOf('('))
            list = {
                ...list,
                [value.trim()]: number
            };
            console.log(list)

        }
        lines.slice(1).map(item => {
            if (item.length > 0) {
                const number = parseInt(item.substring(
                    item.lastIndexOf("(") + 1,
                    item.lastIndexOf(")")));
                const value = item.substring(1, item.indexOf('('))
                list = {
                    ...list,
                    [value.trim()]: number
                };

            }
        });
    } else {
        const lines = keywords.split('\n');
        lines.map(item => {
            if (item.length > 0) {
                const number = parseInt(item.substring(
                    item.lastIndexOf("(") + 1,
                    item.lastIndexOf(")")));
                const value = item.substring(0, item.indexOf('('))
                list = {
                    ...list,
                    [value.trim()]: number
                };

            }
        });
    }
    return list;
}

export const FetchDataForPdq = (props) => {

    const {user} = useSelector(UserSessionSelector)
    const [data, setData] = useState(null);
    const [color, setColor] = useState('#ffffff');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const result = props.result && JSON.parse(props.result);
        if (result) {
            setData(result);
            if (result.semanticEnrichment <= 29) {
                setColor('#ff0000')
            }
            if (result.semanticEnrichment <= 69 && 29 < result.semanticEnrichment) {
                setColor('#ff9933')
            }
            if (result.semanticEnrichment > 69) {
                setColor('#ff9933')
            }
        } else {
            setData(null);
        }
        setLoading(false);

    }, [props, props.result])

    if (loading) {
        return (<CircularProgress/>)
    }
    try {
        return (
            <Button variant="contained" color="primary" onClick={() => {

                const pdf = new jsPDF('landscape', 'px', [1100, 1100]);
                const margins = {
                    top: 5,
                    bottom: 5,
                    left: 40,
                    right: 10
                };
                let specialElementHandlers = {
                    '#editor': function (element, renderer) {
                        return true;
                    }
                };
                const options = {
                    'pagesplit': true,
                    'width': 1050, 'elementHandlers': specialElementHandlers
                };
                pdf.fromHTML(renderToString(
                    <PdfView user={user}
                             keywords={props.keywords}
                             htmlText={props.htmlText}
                             text={props.text}
                             result={data}
                             color={color}
                             convertedText={props.list}/>
                ), 5, 5, options, function () {
                    pdf.save(props.nomText + '.pdf')
                }, margins);
            }}>Télécharger </Button>
        )
    } catch {
        return (<Button disabled variant={'contained'} color={'secondary'}>Erreur</Button>)
    }

}


export const DownloadAll = (props) => {

    const {user} = useSelector(UserSessionSelector)
    const fetchData = async () => {
        const data = props.data;
        const zip = require('jszip')();
        let files = await data.map(async item => {
            const keywords = item.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n");
            const convertedText = setKeyWordList(keywords);
            let color = '#ffffff';
            const result = item.result && JSON.parse(item.result)
            if (result) {
                if (result.semanticEnrichment <= 29) {
                    color = '#ff0000'
                }
                if (result.semanticEnrichment <= 69 && 29 < result.semanticEnrichment) {
                    color = '#ff9933'
                }
                if (result.semanticEnrichment > 69) {
                    color = '#ff9933'
                }
            }

            const pdf = new jsPDF('landscape', 'px', [1100, 1100]);

            const margins = {
                top: 5,
                bottom: 5,
                left: 40,
                right: 10
            };
            let specialElementHandlers = {
                '#editor': function (element, renderer) {
                    return true;
                }
            };
            const options = {
                'pagesplit': true,
                'width': 1050, 'elementHandlers': specialElementHandlers
            };

            pdf.fromHTML(renderToString(
                <PdfView user={user}
                         keywords={keywords}
                         htmlText={item.htmlText}
                         text={item.text1}
                         result={result}
                         color={color}
                         convertedText={convertedText}/>
            ), 5, 5, options, null, margins);
            return {
                name: item.nomText + ".pdf",
                data: pdf.output('blob')
            }

        });
        const result = await Promise.all(files);
        result.map(x => zip.file(x.name, x.data))
        zip.generateAsync({type: "blob"}).then(content => {
            console.log(content)
            saveAs(content, "allFiles.zip");
        });
    }
    return (
        <Button variant="contained" color="primary"
                onClick={fetchData}
                disabled={props.disabled}>
            Télécharger tout
        </Button>
    )
}
