import {createSlice} from '@reduxjs/toolkit'
import {fetchClientsDetails} from "./features";


export const consultantClientsDetails = createSlice({
    name: 'consultantClientsDetails',
    initialState: {
        clientForm: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientsDetails.fulfilled, (state, {payload}) => {
            state.clientForm = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchClientsDetails.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchClientsDetails.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default consultantClientsDetails.reducer


export const consultantClientsDetailsSelector = (state) => state.consultantClientsDetails;
