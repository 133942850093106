import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import {adminCommandListSelector, sortListCommand} from "../../store/Admin/CommandList";
import {useHistory} from "react-router-dom";
import {fetchAdminListDesDemandes} from "../../store/Admin/ListeDesDemandes/features";
import {fetchAdminCommandDisplay} from "../../store/Admin/CommandDisplay/features";

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
export default function ListCommandeNonAttribuee() {

    const {listCommand, isLoading, isError, title} = useSelector(adminCommandListSelector)
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyle();
    const [data, setData] = useState([]);
    const fetchData = async () => {
        const columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Commandes non-attribuées</div>,
                field: 'title',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Thématique(s)</div>,
                field: 'thematique',
                width: 300,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Rédacteur(s) intéressées</div>,
                field: 'redacteur',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold', width: 200}}>Détails de la commande</div>,
                field: 'details',
                sort: 'disabled',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold', width: 200}}>Détails des demandes</div>,
                field: 'demande',
                sort: 'disabled',
                width: 250,
            }
        ];
        let rows = [];
        if (listCommand.length > 0) {
            listCommand.map(item => {
                rows.push({
                    title: item.titre,
                    thematique: <div style={{wordBreak: 'break-word'}}>
                        <Typography> {item.thematiques.join(',')} </Typography>
                    </div>,
                    redacteur: <Typography> {item.nbrDemand} </Typography>,
                    details: <Button variant="contained" color="primary" onClick={() => {
                        dispatch(fetchAdminCommandDisplay(item.id))
                        history.push("/Admin/DétailsDeLaCommande")
                    }
                    }>Voir la commande</Button> ,
                    demande: <Button variant="contained" color="primary" onClick={() => {
                        dispatch(fetchAdminListDesDemandes({id: item.id, title: item.titre}))
                        history.push("/Admin/ListeDesDemandes")
                    }
                    }>Voir les demandes</Button>
                })
            });
        }
        document.title = "Typix - " + title;
        setData({columns, rows});
    }


    if (isError) {
        history.push("/Admin/MainMenu")
    }

    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [listCommand, isLoading])

    return (
        <div style={{margin : 50}}>
            <Card className={classes.cardContainer}>
                <CardHeader className={classes.cardHeader} title={"Commandes non-attribuées"}
                            action={
                                <Tooltip title="Retour" placement="right">
                                <span>
                                        <ArrowBackIcon style={{fontSize: 40, color: '#ff6600', cursor: 'pointer'}}
                                                       onClick={() => history.goBack()}/>
                                </span>
                                </Tooltip>}/>
                <CardContent>
                    {
                        isLoading ? <React.Fragment>
                                <LinearProgress/>
                                <LinearProgress color="secondary"/>
                            </React.Fragment> :
                            <MDBDataTable striped hoverentriesOptions={[10, 20, 50]} entries={10}
                                          noBottomColumns
                                          infoLabel={['', '-', 'sur', 'résultats']}
                                          sortable
                                          paging={data && data.rows && data.rows.length > 0}
                                          paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                              <div style={{cursor: 'pointer'}}>Suivant</div>]}
                                          onSort={({column, direction}) => {
                                              if (data && data.rows && data.rows.length > 0 && column) {
                                                  dispatch(sortListCommand({column, direction}))
                                              }
                                          }}
                                          searchLabel={'Recherche'}
                                          noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                          info={true}
                                          entriesLabel={'Afficher les résultats'}
                                          data={data}/>
                    }

                </CardContent>
            </Card>


        </div>
    )
}
