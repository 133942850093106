import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchAdminUserManagement = createAsyncThunk(
    'adminUserManagement/fetchAdminUserManagement',
    async (arg, thunkAPI) => {
        try {
            const response = await axios.get("/Admin/GetAllUsers")
            console.log(response.data);
            if (response.status === 200) {
                return response.data
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

