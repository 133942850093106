import React, {useState, useEffect} from 'react'
import {MDBDataTable} from "mdbreact";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import {DeliveredTexts, formatDate, NumberOfWords, ValidatedTexts} from "../../helpers/Constants";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Button, Input} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    addLineDataTable,
    adminCommandDisplaySelector, removeLineDataTable,
    updateData,
    updateLineDataTable
} from "../../store/Admin/CommandDisplay";
import {useDispatch, useSelector} from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Checkbox from "@material-ui/core/Checkbox";
import {fetchAdminCommandList} from "../../store/Admin/CommandList/features";
import {adminCommandListSelector} from "../../store/Admin/CommandList";

export default function CommandLineDetail(props) {
    let {lineData, nbrRemaining, command} = useSelector(adminCommandDisplaySelector);
    let {url, title} = useSelector(adminCommandListSelector);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listRedacteur, setListRedacteur] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [nbrTextAdded, setNbrTextAdded] = useState(0);

    useEffect(() => {
        axios.get("/Admin/GetRedacteurWithNoTracking").then(res => {
            setListRedacteur(res.data);
        }).catch(e => console.log(e.response.data))
    }, [props]);

    useEffect(() => {
        try {
            if (lineData && lineData.length > 0) {
                setNbrTextAdded(lineData
                    .map(x => x.nbrText)
                    .reduce((prev, next) => prev + next, 0))
            }
        } catch {
            setNbrTextAdded(0)
        }
    }, [lineData, nbrRemaining])

    const Sauvegarder = () => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);
        axios.post("/Admin/UpdateLineCommande", {
            addCommandeRedacteurRelation: lineData.filter(x => !x.hasOwnProperty("id")),
            updateCommandeRedacteurRelation: lineData.filter(x => x.hasOwnProperty("id")),
            idCommande: command.id
        }).then((res) => {
            setLoading(false);
            dispatch(fetchAdminCommandList({
                url,
                title
            }))
            dispatch(updateLineDataTable(res.data));
            setSuccessMessage("Commande modifiée avec succès");
            setErrorMessage(null);
        }).catch(e => {
            setLoading(false);
            console.log(e)
            switch (e.response.status) {
                case 411 :
                    setErrorMessage('Veuillez choisir au moins un rédacteur');
                    break;
                case 422 :
                    setErrorMessage('Nombre de textes proposé, ne correspond pas à la commande');
                    break;
                default :
                    setErrorMessage('Oups....! Une erreur s\'est produite')
            }
        })
    }
    useEffect(() => {
        let columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Contrat</div>,
                field: 'contrat',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Rédacteur</div>,
                field: 'redactor',
                sort: 'asc'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date limite</div>,
                field: 'deadline',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Tarif (€/mot)</div>,
                field: 'tarif',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Min/Max mot</div>,
                field: 'min_max',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Nb texte</div>,
                field: 'nb_text',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Nb mot achetés</div>,
                field: 'nb_text_purchased',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Nb textes livrés</div>,
                field: 'nb_text_delivered',
                sort: 'asc',
            }, , {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Nb textes validés</div>,
                field: 'nb_text_valid',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Coût d’achat</div>,
                field: 'cout_achat',
                sort: 'asc',
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Total facturé</div>,
                field: 'total',
                sort: 'asc',
            },
            , {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Facturation en réel</div>,
                field: 'facturation',
                sort: 'disabled'
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Supprimer</div>,
                field: 'delete',
                sort: 'asc',
            }
        ];
        let rows = [];
        if (lineData.length > 0) {

            if (lineData.filter(x => !x.hasOwnProperty("id")).length === 0) {
                columns = columns.filter(x => x.field !== "facturation");
            }
            lineData.map((item, key) => rows.push({
                contrat: props.titre,
                redactor:
                    <Autocomplete
                        id="redacteur"
                        fullWidth
                        style={{width: 250}}
                        disabled={item.text.filter(x => x.delivered).length > 0}
                        disableClearable
                        value={item.idRedacteurNavigation}
                        onChange={(event, newValue) => {
                            dispatch(updateData({
                                key: key,
                                column: "idRedacteurNavigation",
                                data: newValue
                            }));
                            dispatch(updateData({
                                key: key,
                                column: "idRedacteur",
                                data: newValue.id
                            }))
                        }}
                        options={listRedacteur}
                        getOptionLabel={(option) => option.mail}
                        renderInput={(params) => <TextField {...params}
                                                            variant="standard"/>}
                    />,
                deadline:
                    <TextField
                        type={"date"}
                        onChange={e => dispatch(updateData({
                            key: key,
                            column: "deadline",
                            data: new Date(e.target.value)
                        }))}
                        value={formatDate(item.deadline)}
                    />,
                tarif:
                    <Input type={'number'}
                           disabled={item.text.filter(x => x.delivered).length > 0 || nbrRemaining === 0}
                           value={item.tarif}
                           inputProps={{min: 0}} onChange={e => dispatch(updateData({
                        key: key,
                        column: "tarif",
                        data: parseFloat(e.target.value)
                    }))
                    }
                           startAdornment={<InputAdornment position="start">€</InputAdornment>}
                    />,
                min_max:
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <Input type={'number'} value={item.nbrMotsMin}
                               inputProps={{min: 0}}
                               onChange={e => dispatch(updateData({
                                   key: key,
                                   column: "nbrMotsMin",
                                   data: parseInt(e.target.value)
                               }))
                               }/><Typography>/</Typography>
                        <Input type={'number'} value={item.nbrMotsMax}
                               inputProps={{min: 0}}
                               onChange={e => dispatch(updateData({
                                   key: key,
                                   column: "nbrMotsMax",
                                   data: parseInt(e.target.value)
                               }))
                               }/></div>,
                nb_text:
                    <Input type={'number'} value={item.nbrText}
                           inputProps={{min: item.text.filter(x => x.delivered).length}}
                           onBlur={() => {
                               if(item.nbrText < item.text.filter(x => x.delivered).length){
                                   dispatch(updateData({
                                       key: key,
                                       column: "nbrText",
                                       data: parseInt(item.text.filter(x => x.delivered).length)
                                   }))
                               }
                           }
                           }
                           onChange={e => dispatch(updateData({
                               key: key,
                               column: "nbrText",
                               data: parseInt(e.target.value)
                           }))
                           }/>,
                nb_text_purchased:
                    item.actualBilling ? "Au réel" :
                        <Input type={'number'} value={item.nbrMotsManaged}
                               disabled={item.text.filter(x => x.delivered).length > 0} inputProps={{min: 0}}
                               onChange={e => dispatch(updateData({
                                   key: key,
                                   column: "nbrMotsManaged",
                                   data: parseInt(e.target.value)
                               }))}/>,
                nb_text_valid:
                    <ValidatedTexts
                        validatedText={item.text.filter(x => x.delivered && x.validateAt).length}
                        text asValue
                        total={item.nbrText}/>,
                nb_text_delivered:
                    <DeliveredTexts
                        deliveredText={item.text.filter(x => x.delivered).length}
                        text asValue
                        total={item.nbrText}/>,
                cout_achat:
                    item.actualBilling ?
                        ((item.text.filter(x => x.delivered).length > 0) ?
                            (item.text.filter(x => x.delivered).map(x => NumberOfWords(x.text1))
                                .reduce((partial_sum, a) => partial_sum + a, 0) * (item.tarif ? item.tarif : 1)) : "0") + "€" :
                        (item.nbrMotsManaged * (item.tarif ? item.tarif : 1) * item.text.filter(x => x.delivered).length) +
                        "/" + (item.nbrMotsManaged * (item.tarif ? item.tarif : 1) * item.nbrText) + "€",
                facturation: !item.id && <Checkbox
                    checked={item.actualBilling}
                    onChange={(e) => dispatch(updateData({
                        key: key,
                        column: "actualBilling",
                        data: !item.actualBilling
                    }))}
                />,
                total:
                    item.actualBilling ? (
                        (item.text.filter(x => x.delivered && x.validateAt).length > 0)
                            ?
                            (item.text.filter(x => x.delivered && x.validateAt).map(x => NumberOfWords(x.text1))
                                .reduce((partial_sum, a) => partial_sum + a, 0) * (item.tarif ? item.tarif : 1))
                            : "0") + "€"
                        :
                        (item.nbrMotsManaged * (item.tarif ? item.tarif : 1) * (item.text.filter(x => x.delivered && x.validateAt).length)) + "€",
                delete:
                    (item.text.filter(x => x.delivered).length === 0) &&
                    <div style={{cursor: 'pointer'}}><DeleteSweepIcon onClick={() => {
                        dispatch(removeLineDataTable(key));
                    }}/></div>

            }))
            ;
        }
        setList({columns, rows});
    }, [lineData, listRedacteur]);


    return (
        <Dialog open={props.visible} onClose={props.Close} fullWidth maxWidth={'xl'}>
            <DialogTitle disableTypography style={{minWidth: 800}}>
                <div style={{display: 'flex'}}>
                    <Typography style={{width: '80%'}} variant="h6">Lignes de commandes</Typography>
                    <IconButton onClick={props.Close} style={{display: 'contents', justifyContent: 'flex-end'}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{minWidth: 800}}>
                <h3>{"Commande : " + props.titre}</h3>
                <MDBDataTable hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4}
                              noBottomColumns
                              infoLabel={['', '-', 'sur', 'résultats']}
                              sortable
                              disableRetreatAfterSorting
                              paging={list && list.rows && list.rows.length > 0}
                              paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                              searchLabel={'Recherche'}
                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                              info={true}
                              entriesLabel={'Afficher les résultats'}
                              data={list}/>
            </DialogContent>
            <DialogActions>
                <div style={{flexDirection: 'row', display: 'flex-end'}}>
                    {
                        nbrRemaining >= 0 &&
                        <Typography>Nombre de textes restants : {nbrRemaining}</Typography>
                    }
                    <Typography>Nombre de textes ajoutés :{nbrTextAdded}</Typography>
                </div>
                {
                    errorMessage && <Alert severity="error">{errorMessage}</Alert>
                }
                {
                    successMessage && <Alert severity="success">{successMessage}</Alert>
                }
                <Button variant="contained"
                        color="primary"
                        size="large"
                        disabled={loading || (nbrRemaining < 0)}
                        startIcon={<SaveIcon/>}
                        onClick={Sauvegarder}>
                    Sauvegarder
                </Button>
                {
                    (nbrRemaining > 0) && <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => dispatch(addLineDataTable())}
                        startIcon={<AddIcon/>}
                    >
                        Ajouter un rédacteur
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
}
