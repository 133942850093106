import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Modal, Jumbotron, ModalHeader, ModalFooter} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalBody from "reactstrap/es/ModalBody";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {fetchClientList} from "../../store/Admin/ClientList/features";
import Container from "reactstrap/lib/Container" ;
import CommandeClient from "./CommandeClient";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import {adminWebsiteDetailsSelector} from "../../store/Admin/WebsiteDisplay";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function UpdateClient() {
    let {formData, isLoading, isError} = useSelector(adminWebsiteDetailsSelector);

    const dispatch = useDispatch();
    const history = useHistory();
    const [pixOpData, setPixOpData] = useState([]);
    const [url, setUrl] = useState('')
    const [clientList, setClientList] = useState([]);
    const [index, setIndex] = useState(0);
    const [detailPrestationContenu, setDetailPrestationContenu] = useState('')
    const [consultant, setConsultant] = useState('')
    const [nbrCocon, setNbrCocon] = useState('')
    const [nbrTextCocon, setNbrTextCocon] = useState('')
    const [nbrFaq, setNbrFaq] = useState('')
    const [nbrGmb, setNbrGmb] = useState('')
    const [nbrTextOpt, setNbrTextOpt] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);
    const [readOnly, setReadOnly] = useState(false);

    const [showCommande, setShowCommande] = useState(false);
    const toggleSuccess = () => {
        setSuccess(null);
        history.goBack();
    };
    const toggleError = () => setError(!error);


    const [listConsultant, setListConsultant] = useState([]);

    const CheckValidForm = () => {
        if (detailPrestationContenu.length === 0 || nbrCocon.length === 0 || nbrTextCocon.length === 0 || nbrFaq.length === 0
            || nbrGmb.length === 0 || setNbrTextOpt.length === 0 || url.length === 0) {
            return true
        }
        return loading;

    }
    const handleChange = (event) => {
        setIndex(event.target.value)
    }
    useEffect(() => {
        if (!isLoading) {
            setReadOnly(true)
            setUrl(formData.urlSite)
            setClientList(formData.clientWebsiteRelation.length > 0 && formData.clientWebsiteRelation.map(x => x.clientNavigation));
            console.log("FM ", formData)
            setNbrTextOpt(formData.nbrTextOptimiser);
            setNbrTextCocon(formData.nbrTextCocons);
            setNbrCocon(formData.nbrCocons);
            setNbrFaq(formData.nbrFaq);
            setNbrGmb(formData.nbrGmb);
            setDetailPrestationContenu(formData.detailPrestation);
            document.title = "Typix - Fiche site web";
            axios.get("/Admin/GetConsultant").then(res => {
                setListConsultant(res.data)
                if (res.data.length > 0) {
                    if (formData && formData.consultantNavigation) {
                        setConsultant(res.data.find(x => x.id === formData.consultantNavigation.id))
                    } else {
                        setConsultant(res.data[0])
                    }

                }
            });
        }
        axios.get("/Admin/PixOpsClient").then(res => {
            setPixOpData(res.data)
        });
    }, [isLoading])
    const AddClient = () => {
        axios.post("/Admin/UpdateWebsite", {
            id: formData.id,
            urlSite: url,
            nbrCocons: nbrCocon,
            nbrTextCocons: nbrTextCocon,
            nbrFaq: nbrFaq,
            consultant: consultant.id,
            nbrGmb: nbrGmb,
            nbrTextOptimiser: nbrTextOpt,
            detailPrestation: detailPrestationContenu
        }).then(() => {
            dispatch(fetchClientList())
            setSuccess("Fiche modifiée avec succès ! ")
            setLoading(false)
        }).catch(() => {
            setError(true)
            setLoading(false)
        })
    }

    const DeleteClient = () => {
        axios.post("/Admin/DeleteWebsite?id=" + formData.id).then(() => {
            dispatch(fetchClientList())
            setSuccess("Fiche supprimée avec succès !");
            setLoading(false)
        }).catch((e) => {
            setError(true);
            setLoading(false);
        })
    }

    if (clientList.length === 0 || isLoading) {
        return (
            <Container>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
            </Container>
        )
    }

    if (isError) {
        history.push('/Admin/MainMenu')
    }
    return (
        <Container>
            <Jumbotron>
                <CommandeClient visible={showCommande}
                                Close={() => setShowCommande(false)}
                                id={formData && formData.id}/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ArrowBackIcon color={"primary"}
                                       style={{fontSize: 40, cursor: 'pointer'}}
                                       onClick={() => history.goBack()}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'h5'}>{
                            formData ? "Fiche site web" : "Ajouter un site web"
                        }</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => setShowCommande(true)} variant="contained" color={'primary'}
                                    style={{marginBottom: 25}}>Voir les commandes</Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Compte client"}
                                    fullWidth
                                    style={{width: 300}}
                                    contentEditable={false}
                                    suppressContentEditableWarning={true}
                                    value={clientList[index].compte}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="url-client"
                                    freeSolo
                                    fullWidth
                                    disabled={readOnly}
                                    value={url}
                                    options={pixOpData.length > 0 && pixOpData.find(x => x.clientName === clientList[index].compte).site}
                                    onInputChange={((event, value) => setUrl(value))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Url"}
                                            margin="normal"
                                            InputProps={{...params.InputProps, type: 'search', readOnly: readOnly}}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="Nom du consultant"
                                    value={consultant}
                                    onChange={(event, newValue) => {
                                        setConsultant(newValue);
                                    }}
                                    disabled={readOnly}
                                    options={listConsultant}
                                    getOptionLabel={(option) => option.nomPrenom}
                                    style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} label="Nom du consultant"/>}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de texte par cocon"}
                                    value={nbrTextCocon || ''}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrTextCocon(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de FAQ"}
                                    value={nbrFaq}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrFaq(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de GMB"}
                                    style={{width: 300}}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    value={nbrGmb}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrGmb(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de cocons"}
                                    value={nbrCocon}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrCocon(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {
                                    readOnly ? <Button variant="contained" color="secondary"
                                                       style={{margin: 10, width: 160}}
                                                       onClick={() => setReadOnly(false)}>Editer la
                                            fiche</Button> :
                                        loading ? <CircularProgress/> :
                                            <Button variant="contained" color="primary"
                                                    disabled={CheckValidForm()}
                                                    style={{margin: 10, width: 150}}
                                                    onClick={() => {
                                                        setLoading(true);
                                                        AddClient();
                                                    }
                                                    }>Sauvegarder</Button>


                                }

                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="default" disabled={loading}
                                        style={{margin: 10, width: 190}}
                                        onClick={() => {
                                            setLoading(true);
                                            DeleteClient();
                                        }
                                        }>Supprimer la fiche</Button>
                            </Grid>

                            <Grid item xs={12}>

                                <FormControl style={{width: 300}}>
                                    <InputLabel>Nom et prénom du client</InputLabel>
                                    <Select
                                        value={index}
                                        onChange={handleChange}
                                    >
                                        {
                                            clientList.map((value, index) =>
                                                <MenuItem value={index}>{value.nomPrenom}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl style={{width: 300}}>
                                    <InputLabel id="demo-simple-select-label">Mail</InputLabel>
                                    <Select
                                        id="demo-simple-select-label"
                                        value={index}
                                        label="Compte"
                                        onChange={handleChange}
                                    >
                                        {
                                            clientList.map((value, index) =>
                                                <MenuItem value={index}>{value.mail}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}/>

                            <Grid item xs={12}>
                                <FormControl style={{width: 300}}>
                                    <InputLabel>Téléphone</InputLabel>
                                    <Select
                                        value={index}
                                        label="Téléphone"
                                        onChange={handleChange}
                                    >
                                        {
                                            clientList.map((value, index) =>
                                                <MenuItem value={index}>{value.numTelephone}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de textes à optimiser"}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    style={{width: 300}}
                                    value={nbrTextOpt}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrTextOpt(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Détail de la prestation du contenu"}
                                    InputProps={{
                                        readOnly: readOnly
                                    }}
                                    style={{width: 300}}
                                    value={detailPrestationContenu}
                                    onChange={e => setDetailPrestationContenu(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal isOpen={success} toggle={toggleSuccess}>
                    <ModalHeader
                        toggle={() => setSuccess(false)}>{success}</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/succes.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => {
                            setReadOnly(true)
                            setSuccess(false)
                        }}>Fermer</Button>{' '}
                        <Button color="secondary" onClick={toggleSuccess}>Revenir a l'accueil</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={error} toggle={toggleError}>
                    <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                    <ModalBody>
                        <img src={'/Images/error.png'} height={"60%"} width={"60%"}
                             style={{display: 'block', margin: 'auto'}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                    </ModalFooter>
                </Modal>
            </Jumbotron>

        </Container>
    )


}
