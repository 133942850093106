import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './Layout';
import HomePage from "./Homepage";
import MyProfile from "./MyProfile";
import CommandeDisplay from "./CommandeDisplay";
import Analyzer from "./Analyzer";

export default class MainMenu extends React.Component {

    render() {
        return (
            <Layout>
                <Route path='/Client/MainMenu' render={(props) => <HomePage {...props} reload={true}  />} />
                <Route path='/Client/MonProfile' component={MyProfile} />

                <Route path='/Client/DétailsDeLaCommande' component={CommandeDisplay} />
                <Route path='/Client/TextDetails' component={Analyzer} />
            </Layout>
        )
    }

}
