import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchClientCommandList = createAsyncThunk(
    'clientCommandList/fetchClientCommandList',
    async (id, thunkAPI) => {
        try {
            if (!id)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Client/GetCommands?id=" +id)
            console.log("clientCommandList",response)
            if (response.status === 200) {
                return  response.data;
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

