import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Modal, Jumbotron, ModalHeader, ModalFooter} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalBody from "reactstrap/es/ModalBody";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {fetchClientList} from "../../store/Admin/ClientList/features";
import Container from "reactstrap/lib/Container" ;
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function AddClient() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [pixOpData, setPixOpData] = useState([]);
    const [url, setUrl] = useState('')
    const [compte, setCompte] = useState('');
    const [matchingClient, setMatchingClient] = useState([]);
    const [detailPrestationContenu, setDetailPrestationContenu] = useState('')
    const [clientName, setClientName] = useState('')
    const [mail, setMail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [consultant, setConsultant] = useState('')
    const [nbrCocon, setNbrCocon] = useState(0)
    const [nbrTextCocon, setNbrTextCocon] = useState(0)
    const [nbrFaq, setNbrFaq] = useState(0)
    const [nbrGmb, setNbrGmb] = useState(0)
    const [nbrTextOpt, setNbrTextOpt] = useState(0)
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [readOnly, setReadOnly] = useState(false);


    const [selectionFromCompte, setSelectionFromCompte] = useState(false);

    const [mailFocused, setMailFocused] = useState(false);
    const toggleSuccess = () => {
        setSuccess(!success);
        history.goBack();
    };
    const toggleError = () => setError(!error);


    function emailIsValid(x) {
        let atposition = x.indexOf("@");
        let dotposition = x.lastIndexOf(".");
        return !(atposition < 1 || dotposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= x.length);

    }

    const [listConsultant, setListConsultant] = useState([]);

    const passwordCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const CheckValidForm = () => {
        if (detailPrestationContenu.length === 0 || nbrTextOpt.length === 0 || url.length === 0) {
            return true
        }
        if ((mail.length === 0 || telephone.length === 0 || password.length === 0 || clientName.length === 0) && !selectionFromCompte)
            return true
        if ((!emailIsValid(mail) && mail.length > 0)) {
            return true
        }
        return CheckPasswordValid();

    }

    const CheckPasswordValid = () => {
        if (selectionFromCompte)
            return false;
        return !passwordCheck.test(password) && password.length > 0;
    }

    useEffect(() => {
        document.title = "Typix - Ajouter un client";
        axios.get("/Admin/GetConsultant").then(res => {
            setListConsultant(res.data)
            if (res.data.length > 0) {
                setConsultant(res.data[0])
            }
        });
        axios.get("/Admin/PixOpsClient").then(res => {
            setPixOpData(res.data)
        });
    }, [])
    const AddClient = () => {
        axios.post("/Admin/AddClient", {
            clientNavigation: {
                mail: mail,
                motDePasse: password,
                nomPrenom: clientName,
                numTelephone: telephone,
                compte: compte,
            },
            websiteNavigation: {
                urlSite: url,
                nbrCocons: nbrCocon,
                nbrTextCocons: nbrTextCocon,
                nbrFaq: nbrFaq,
                consultant: consultant.id,
                nbrGmb: nbrGmb,
                nbrTextOptimiser: nbrTextOpt,
                detailPrestation: detailPrestationContenu
            }
        }).then(() => {
            dispatch(fetchClientList())
            setSuccess(true)
            setLoading(false);
            setError(null)
        }).catch(e => {
            switch (e.response.status) {
                case 403 :
                    setErrorMessage('Contrat déjà existant, veuillez modifier les informations');
                    break;
                default :
                    setErrorMessage('Oups....! Une erreur s\'est produite');
                    break;
            }
            setError(true)
            setLoading(false)
        })
    }


    return (
        <Container>
            <Jumbotron>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                       onClick={() => history.goBack()}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'h5'}>Ajouter un site web</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {
                                    pixOpData.length > 0 ?
                                        <Autocomplete
                                            id="compte-client"
                                            options={pixOpData.map(x => x.clientName)}
                                            value={compte}
                                            disableClearable
                                            onChange={((event, value) => {
                                                console.log(value)
                                                axios.get("/Admin/GetClientForm", {
                                                    params: {
                                                        compte: value
                                                    }
                                                }).then(res => setMatchingClient(res.data))
                                                setCompte(value)
                                            })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={"Compte client"}
                                                    margin="normal"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        readOnly: readOnly
                                                    }}
                                                />
                                            )}
                                        /> : <LinearProgress/>
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="url-client"
                                    fullWidth
                                    freeSolo={true}
                                    disabled={!compte}
                                    value={url}
                                    options={(compte && pixOpData.length > 0 &&
                                        pixOpData.some(x => x.clientName === compte)) && pixOpData.find(x => x.clientName === compte).site}
                                    onInputChange={((event, value) => setUrl(value))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Url"}
                                            margin="normal"
                                            InputProps={{...params.InputProps, type: 'search', readOnly: readOnly}}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="Nom du consultant"
                                    value={consultant}
                                    onChange={(event, newValue) => {
                                        setConsultant(newValue);
                                    }}
                                    disabled={readOnly}
                                    options={listConsultant}
                                    getOptionLabel={(option) => option.nomPrenom}
                                    style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} label="Nom du consultant"/>}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de texte par cocon"}
                                    value={nbrTextCocon}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrTextCocon(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de FAQ"}
                                    value={nbrFaq}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrFaq(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de GMB"}
                                    style={{width: 300}}
                                    value={nbrGmb}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrGmb(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de cocons"}
                                    value={nbrCocon}
                                    style={{width: 300}}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrCocon(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {
                                    loading ? <CircularProgress/> :
                                        <Button variant="contained" color="primary" disabled={CheckValidForm()}
                                                style={{margin: 10}}
                                                onClick={() => {
                                                    setLoading(true);
                                                    AddClient();
                                                }
                                                }>Sauvegarder</Button>
                                }
                            </Grid>


                            <Grid item xs={12}>
                                <Autocomplete
                                    id="compte-client-nom-prenom"
                                    freeSolo={true}
                                    value={clientName}
                                    clearText={"Réinitialiser"}
                                    options={matchingClient}
                                    getOptionLabel={(option) => {
                                        if (option.hasOwnProperty('nomPrenom')) {
                                            return option.nomPrenom;
                                        }
                                        return option;
                                    }}
                                    onInputChange={(event, value) => {
                                        setClientName(value)
                                    }}
                                    onChange={(event, value) => {
                                        if (typeof value === 'string') {
                                            setSelectionFromCompte(false);
                                            setMail('');
                                            setTelephone('');
                                        } else {
                                            if (value && value.id && matchingClient.length > 0 && matchingClient.some(x => x.id === value.id)) {
                                                const client = matchingClient.find(x => x.id === value.id);
                                                setMail(client.mail);
                                                setTelephone(client.numTelephone);
                                                setSelectionFromCompte(true)
                                            } else {
                                                setMail('');
                                                setTelephone('');
                                                setSelectionFromCompte(false)
                                            }
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Nom et prénom du client"}
                                            margin="normal"
                                            style={{width: 300}}
                                            InputProps={{...params.InputProps, type: 'search'}}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Mail"}
                                    onFocus={() => setMailFocused(true)}
                                    onBlur={() => {
                                        setMailFocused(false);
                                    }}
                                    disabled={selectionFromCompte}
                                    style={{width: 300}}
                                    error={!mailFocused && ((!emailIsValid(mail) && mail.length > 0))}
                                    helperText={!mailFocused ? (!emailIsValid(mail) && mail.length > 0) && "Email non valide" : ''}
                                    value={mail}
                                    onChange={e => setMail(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    !selectionFromCompte &&
                                    <FormControl style={{width: 300}}>
                                        <InputLabel error={CheckPasswordValid()}>Mot de passe</InputLabel>
                                        <Input
                                            id="standard-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            error={CheckPasswordValid()}
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText error={CheckPasswordValid()} style={{position: 'relative'}}>-Avoir
                                            au moins 8 caractères avec une lettre
                                            majuscule,
                                            un chiffre et ne pas être identique au nom </FormHelperText>
                                    </FormControl>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl disabled={selectionFromCompte} style={{width: 300}}>
                                    <InputLabel>Téléphone</InputLabel>
                                    <Input
                                        value={telephone}
                                        onChange={e => {
                                            const re = /^(?=.*\d)[\d ]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setTelephone(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Nombre de textes à optimiser"}
                                    style={{width: 300}}
                                    value={nbrTextOpt}
                                    onChange={e => {
                                        const re = /^(?=.*\d)[\d ]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setNbrTextOpt(e.target.value)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Détail de la prestation du contenu"}
                                    style={{width: 300}}
                                    value={detailPrestationContenu}
                                    onChange={e => setDetailPrestationContenu(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Jumbotron>
            <Modal isOpen={success} toggle={toggleSuccess}>
                <ModalHeader
                    toggle={toggleSuccess}>Client ajouté avec succès !</ModalHeader>
                <ModalBody>
                    <img src={'/Images/succes.png'} height={"60%"} width={"60%"}
                         style={{display: 'block', margin: 'auto'}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleSuccess}>Revenir a l'accueil</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={error} toggle={toggleError}>
                <ModalHeader toggle={toggleError}>{errorMessage}</ModalHeader>
                <ModalBody>
                    <img src={'/Images/error.png'} height={"60%"} width={"60%"}
                         style={{display: 'block', margin: 'auto'}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                </ModalFooter>
            </Modal>

        </Container>
    )


}
