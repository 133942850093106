import React, {useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {Button} from "@material-ui/core";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import {ISOlanguages} from "../../helpers/Constants";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Jumbotron from "reactstrap/lib/Jumbotron";

import { useHistory } from 'react-router-dom';
import {Modal, ModalFooter, ModalHeader,ModalBody} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import {useDispatch, useSelector} from "react-redux";
import {updateUser, UserSessionSelector} from "../../store/UserSession";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function MyProfile() {
    let {user} = useSelector(UserSessionSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [mail, setMail] = useState('');
    const [nomPrenom, setNomPrenom] = useState('');
    const [nomSociete, setNomSociete] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [thematique, setThematique] = useState([]);
    const [listLangue, setListLangue] = useState([]);
    const [otherLangue, setOtherLangue] = useState([]);
    const [password, setPassword] = useState('');

    const[showHistorique,setShowHistorique] = useState(false);
    const[listHistorique,setListHistorique] = useState([]);
    const toggleHistorique = () => setShowHistorique(!showHistorique);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])


    const toggleSuccess = () => {
        setSuccess(!success);
    };
    const toggleError = () => setError(!error);

    const [ThématiqueList, setThématiqueList] = useState([]);
    const FetchHistorique =() => {
        axios.get( "/Redactor/GetHistoriqueClient?id="+user.id).then(res => {
            const columns = [
                {
                    label: <div style={{cursor: 'pointer'}}>Url des clients</div>,
                    field: 'url',
                    sort: 'asc',
                    width: 250,
                }
            ];
            let rows = [];
            res.data.map(item =>  item && rows.push({
                url: item
            }));
            setListHistorique({columns, rows});
        })
    }

    const InitializeChanges = () => {
        setThematique(user.redacteurThematique.map(x => x.thematique));
        setMail(user.mail);
        setPassword(user.motDePasse);
        setNomPrenom(user.nomPrenom);
        setTelephone(parseInt(user.numTelephone));
        setNomSociete(user.nomSociete);
        setAdresse(user.adresse);
        setListLangue(user.redacteurLangue.filter(x => x.langue === "Français" || x.langue === "Italien" || x.langue === "Espagnol" || x.langue === "Allemand" || x.langue === "Anglais").map(x => x.langue));
        setOtherLangue(user.redacteurLangue.filter(x => x.langue !== "Français" && x.langue !== "Italien" && x.langue !== "Espagnol" && x.langue !== "Allemand" && x.langue !== "Anglais").map(x => x.langue));
    }
    useEffect(() => {
        InitializeChanges();
        FetchHistorique();
        document.title = "Typix - Mon profil";
    }, [user])
    useEffect(() => {
        axios.get(  "/Redactor/GetListThematique").then(res => setThématiqueList(res.data))
    }, [])
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const Update = () => {
        axios.post( "/Redactor/UpdateProfile", {
            redacteur: {
                id: user.id,
                mail: mail,
                nomPrenom: nomPrenom,
                motDePasse: user.motDePasse,
                numTelephone: telephone,
                nomSociete: nomSociete,
                adresse: adresse
            },
            langue: listLangue.concat(otherLangue),
            thematique: thematique,
            passwordChanged: false
        }).then(res =>dispatch(updateUser(res.data))).then(() => setSuccess(true)).catch(() => toggleError())
    }


    return (
        <div>
            <Jumbotron>
                <div style={{margin: 15}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ArrowBackIcon color={"primary"}
                                           style={{fontSize: 40, cursor: 'pointer'}}
                                           onClick={() => history.push("/Rédacteur/MainMenu")}/>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={'h5'}>Mon profil</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant={'contained'} color={"primary"} onClick={toggleHistorique}>
                                Historique des clients
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"ID Rédacteur "}
                                disabled
                                value={user.id}
                                fullWidth
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Mail"}
                                value={user.mail}
                                fullWidth
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                            />
                        </Grid>
                        <Grid item xs={12} direction={"row"}>
                            <FormControl style={{marginRight: 10}}>
                                <InputLabel htmlFor="standard-adornment-password">Nom/Prénom</InputLabel>
                                <Input placeholder="Nom/Prénom" type={"text"} value={nomPrenom}
                                       onChange={e => setNomPrenom(e.target.value)}
                                       inputProps={{'aria-label': 'description'}}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            <TextField
                                label={"Nom de la société"}
                                value={nomSociete}
                                onChange={e => setNomSociete(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Adresse"}
                                value={adresse}
                                onChange={e => setAdresse(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={"Numéro de téléphone"}
                                value={telephone}
                                type={'number'}
                                onChange={e => {
                                    setTelephone(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel style={{marginTop: 20, marginBottom: 20}}>Langues :</InputLabel>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Français")}
                                        onChange={() => listLangue.includes("Français") ? setListLangue(listLangue.filter(x => x !== "Français")) : setListLangue([...listLangue, "Français"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Français"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Italien")}
                                        onChange={() => listLangue.includes("Italien") ? setListLangue(listLangue.filter(x => x !== "Italien")) : setListLangue([...listLangue, "Italien"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Italien"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Anglais")}
                                        onChange={() => listLangue.includes("Anglais") ? setListLangue(listLangue.filter(x => x !== "Anglais")) : setListLangue([...listLangue, "Anglais"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Anglais"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Allemand")}
                                        onChange={() => listLangue.includes("Allemand") ? setListLangue(listLangue.filter(x => x !== "Allemand")) : setListLangue([...listLangue, "Allemand"])}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Allemand"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listLangue.includes("Espagnol")}
                                        onChange={() => listLangue.includes("Espagnol") ? setListLangue(listLangue.filter(x => x !== "Espagnol")) : setListLangue([...listLangue, "Espagnol"])}
                                        name="checkedB"
                                        color="Espagnol"
                                    />
                                }
                                label="Espagnol"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Autre : </InputLabel>
                                <Select
                                    multiple
                                    value={otherLangue}
                                    onChange={(event) => {

                                        setOtherLangue(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'name',
                                        id: 'langue-native-simple',
                                    }}
                                    MenuProps={MenuProps}
                                    input={<Input/>}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {
                                        ISOlanguages.filter(x => !['Français', 'Italien', 'Allemand', 'Espagnol','Anglais'].includes(x.name)).sort(function (a, b) {
                                            if (a.name > b.name) {
                                                return 1;
                                            }
                                            if (b.name > a.name) {
                                                return -1;
                                            }
                                            return 0;
                                        }).map((item, key) =>
                                            <MenuItem key={key} value={item.name}>
                                                <Checkbox checked={otherLangue.indexOf(item.name) > -1}/>
                                                <ListItemText primary={item.name}/>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Thématique : </InputLabel>
                                <Select
                                    multiple
                                    autoWidth
                                    value={thematique}
                                    onChange={(event) => {
                                        setThematique(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'thematique',
                                        id: 'thematique-native-simple',
                                    }}
                                    MenuProps={MenuProps}
                                    input={<Input/>}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    <option aria-label="None" value={0}/>
                                    {
                                        ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                                <Checkbox checked={thematique.indexOf(item) > -1}/>
                                                <ListItemText primary={item}/>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} />
                        <Grid item xs={2}>
                            <Button variant={'contained'} color={"primary"} onClick={Update}>
                                Sauvegarder
                            </Button>
                        </Grid>
                    </Grid>


                </div>
            </Jumbotron>
            <Modal isOpen={success} toggle={toggleSuccess}>
                <ModalHeader toggle={toggleSuccess}>Profil mis à jour avec succès !</ModalHeader>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        toggleSuccess()
                    }}>Fermer</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showHistorique} toggle={toggleHistorique}>
                <ModalHeader toggle={toggleHistorique}>Historique des clients </ModalHeader>
                <ModalBody>
                    <MDBDataTable striped hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4}
                                  noBottomColumns
                                  infoLabel={['', '-', 'sur', 'résultats']}
                                  sortable
                                  paging={listHistorique && listHistorique.rows && listHistorique.rows.length > 0}
                                  paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                      <div style={{cursor: 'pointer'}}>Suivant</div>]}
                                  searchLabel={'Recherche'}
                                  noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                  info={true}
                                  entriesLabel={'Afficher les résultats'}
                                  data={listHistorique}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleHistorique}>Fermer</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={error} toggle={toggleError}>
                <ModalHeader toggle={toggleError}>Oups....! Une erreur s'est produite</ModalHeader>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Modifier le formulaire</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
/*

                        <Grid item xs={12}>
                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
 */
