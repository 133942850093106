import React, {Component} from 'react';
import {Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import './NavMenu.css';
import {Button} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from "@material-ui/core/Tooltip";
import {fetchUserSession} from "../../store/UserSession/features";
import {connect} from "react-redux";
import PersonIcon from "@material-ui/icons/Person";


class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            currentPage : window.location.pathname
        };
    }

  componentDidMount() {
      window.addEventListener("locationchange",  () => {
          this.setState({currentPage : window.location.pathname})
      });
  }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }


    render() {
        console.log(this.state.currentPage)
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/Consultant/MainMenu"
                                     style={{flexDirection: 'row', display: 'contents'}}><img
                            src={'/Images/NavLogo.png'} height={90} width={400} style={{marginRight : 40,position : 'relative'}}/> </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed}
                                  navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Consultant/MonProfile">
                                        <Tooltip title="Mon Profil" placement="right">
                                            <PersonIcon fontSize={'large'}/>
                                        </Tooltip>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">
                                        <Tooltip title="Déconnexion" placement="right">
                                            <ExitToAppIcon fontSize={'large'} onClick={() => {
                                                localStorage.removeItem("User");
                                                this.props.dispatch(fetchUserSession(null))
                                            }}/>
                                        </Tooltip>
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );


    }
}

export default connect()(NavMenu)
