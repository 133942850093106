import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import HelpIcon from '@material-ui/icons/Help';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Select, MenuItem, Grid} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import {PdfView, setKeyWordList} from "../../helpers/PdfFunction";

import './Analyzer.css'
import ReactQuill, {Quill} from 'react-quill';
import "react-quill/dist/quill.bubble.css";
import jsPDF from "jspdf";

import {renderToString} from 'react-dom/server'
import {Link} from "react-router-dom";
import {NbrTextColor, NumberOfWords} from "../../helpers/Constants";
import {useDispatch, useSelector} from "react-redux";
import {adminAnalyzerSelector, updateTextForm} from "../../store/Admin/Analyser";

import {useHistory} from 'react-router-dom';
import {fetchAdminCommandDisplay} from "../../store/Admin/CommandDisplay/features";
import {UserSessionSelector} from "../../store/UserSession";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const modules = {
    toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ]
}

export default function Analyzer() {
    let {formData, idCommande, nbrTextMin, nbrTextMax ,idCommandeRedacteurRelation} = useSelector(adminAnalyzerSelector);
    let {user} = useSelector(UserSessionSelector);
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [text, setText] = useState("");
    const [language, setLanguage] = useState('FRENCH');
    const [openLanguage, setOpenLanguage] = useState(false);
    const [result, setResult] = useState('')
    const toggle = () => setPopoverOpen(!popoverOpen);
    const [color, setColor] = useState('black');
    const [convertedText, setConvertedText] = useState({});
    const [showError, setShowError] = useState(false);
    const [ready, setReady] = useState(false);
    const [pdfReady, setPdfShow] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pdfTitle, setPdfTitle] = useState('');
    const [baliseMeta, setBaliseMeta] = useState('')
    const [htmlText, setHtmlText] = useState(null);

    const [nbrText, setNbrText] = useState(0);

    const [activeInitialText, setActiveInitialText] = useState(false);
    const [initialHtmlText, setInitialHtmlText] = useState(null)
    const [initialText, setInitialText] = useState(null);

    const [content, setContent] = useState("");
    const [initialContent, setInitialContent] = useState("");

    const [showDifference, setShowDifference] = useState(false);
    const [nomText, setNomText] = useState('')
    const [motCle, setMotCle] = useState('')
    const [url, setUrl] = useState('')
    const [baliseTitle, setBaliseTitle] = useState('');
    const [editShow, setEditShow] = useState(false);
    const [success, setSuccess] = useState(false);


    const [activeTab, setActiveTab] = useState('1');


    const [validNbrText, setValidNbrtText] = useState(false);


    const [showAddCopiedText, setShowAddCopiedText] = useState(false);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const Update = () => {
        if (keywords === '' || text === '') {
            setShowError(true)
        } else {
            const list = setKeyWordList(keywords);
            axios.post("/Admin/UpdateTextCommande", {
                id: formData.id,
                text1: text,
                htmlText: htmlText && JSON.stringify(htmlText),
                motCle: motCle,
                url: url,
                baliseTitle: baliseTitle,
                nomText: nomText,
                result: result && JSON.stringify(result),
                baliseMetaDescription: baliseMeta,
                commandeRedacteurRelation: idCommandeRedacteurRelation,
                delivered: formData.delivered,
                activeInitialText : (formData.initialHtmlText === JSON.stringify(initialHtmlText)) ? activeInitialText : (formData.initialHtmlText === initialHtmlText),
                idCommande: idCommande,
                edited: formData.edited,
                validateAt: formData.validateAt,
                revisionDone: formData.revisionDone,
                revisionSent: formData.revisionSent,
                revisionDate: formData.revisionDate,
                revisionText: formData.revisionText,
                initialText: initialText,
                initialHtmlText: initialHtmlText && JSON.stringify(initialHtmlText),
                content: content,
                initialContent: initialContent,
                createdAt: formData.createdAt,
                gapSemantique: Object.entries(list).map(([key, value]) => ({mot: key, nbrColumn: value}))
            }).then(res => {
                setEditShow(true);
                setSuccess(true);
                dispatch(fetchAdminCommandDisplay(idCommande));
                dispatch(updateTextForm(res.data));
            }).catch(() => setShowError(true))
        }
    }


    const AddCopiedText = () => {
        const list = setKeyWordList(keywords);
        const url =  "/Admin/UpdateTextCommande";
        let data = {
            id: formData.id,
            text1: initialText,
            htmlText: initialHtmlText && JSON.stringify(initialHtmlText),
            motCle: motCle,
            url: url,
            baliseTitle: baliseTitle,
            nomText: nomText,
            result: result && JSON.stringify(result),
            commandeRedacteurRelation: idCommandeRedacteurRelation,
            baliseMetaDescription: baliseMeta,
            idCommande: idCommande,
            initialText: initialText,
            delivered: formData.delivered,
            activeInitialText : false,
            initialHtmlText: initialHtmlText && JSON.stringify(initialHtmlText),
            edited: false,
            validateAt: null,
            revisionDone: false,
            revisionSent: false,
            revisionDate: null,
            revisionText: false,
            content: initialContent,
            initialContent: initialContent,
            gapSemantique: Object.entries(list).map(([key, value]) => ({mot: key, nbrColumn: value})),
            createdAt: new Date()
        };
        axios.post(url, data).then(res => {
            setEditShow(true);
            setSuccess(true);
            dispatch(fetchAdminCommandDisplay(idCommande));
            dispatch(updateTextForm(res.data));
            setHtmlText(initialHtmlText);
            setActiveInitialText(res.data.activeInitialText);
            setContent(initialContent);
            setText(initialText);
            setShowAddCopiedText(false);
        }).catch(() => setShowError(true))
    }
    useEffect(() => {
        setEditShow(formData !== null);
        if (formData) {
            setText(formData.text1);
            setInitialText(formData.initialText);
            setContent(formData.content);
            setInitialContent(formData.initialContent);
            setNbrText(formData.text1.length)
            setMotCle(formData.motCle);
            setHtmlText(formData.htmlText && JSON.parse(formData.htmlText));
            setInitialHtmlText(formData.initialHtmlText && JSON.parse(formData.initialHtmlText));
            setUrl(formData.url);
            setBaliseMeta(formData.baliseMetaDescription);
            setResult(formData.result && JSON.parse(formData.result));

            setActiveInitialText(formData.activeInitialText);
            let resultForColor = formData.result && JSON.parse(formData.result);
            if (resultForColor) {
                if (resultForColor.semanticEnrichment <= 29) {
                    setColor('#FF595D');
                }
                if (resultForColor.semanticEnrichment <= 69 && 29 < resultForColor.semanticEnrichment) {
                    setColor('#FFBE64');
                }
                if (resultForColor.semanticEnrichment > 69) {
                    setColor('#3ED774');
                }
            }
            setBaliseTitle(formData.baliseTitle);
            setNomText(formData.nomText);
            setKeywords(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n"));
            setConvertedText(setKeyWordList(formData.gapSemantique.map(x => x.mot + "(" + x.nbrColumn + ")").join(",\n")));
            document.title = "Typix - Détails du texte";
        } else {
            document.title = "Typix - Ajouter un texte";
        }
    }, [])


    const DownloadPdf = () => {
        const pdf = new jsPDF('landscape', 'px', [1100, 1100]);
        const margins = {
            top: 5,
            bottom: 5,
            left: 40,
            right: 10
        };
        let specialElementHandlers = {
            '#editor': function (element, renderer) {
                return true;
            }
        };
        const options = {
            'pagesplit': true,
            'width': 1050, 'elementHandlers': specialElementHandlers
        };
        pdf.fromHTML(renderToString(
            <PdfView user={user} keywords={keywords} text={text} htmlText={htmlText}
                     result={result}
                     color={color}
                     convertedText={convertedText}/>
        ), 5, 5, options, function () {
            pdf.save(pdfTitle + '.pdf')
        }, margins);
    }
    const FetchResult = () => {
        if (keywords === '' || text === '') {
            setShowError(true)
        } else {
            setShowError(false)
            const list = setKeyWordList(keywords)
            let textInput = '';
            if (list && text.length > 0) {
                setConvertedText(list)
                try {
                    if (formData && formData.initialHtmlText && initialHtmlText && htmlText) {
                        const oldContent = initialHtmlText;
                        let diff = oldContent.diff(htmlText);

                        for (let i = 0; i < diff.ops.length; i++) {
                            let op = diff.ops[i];
                            if (op.hasOwnProperty('insert')) {
                                textInput = textInput + op['insert']
                            }
                        }
                    } else {
                        textInput = text;
                    }
                } catch (e) {
                    console.log(e)
                    textInput = text;
                }

                Axios.post('https://api.monetoring.com/offline_comparison_api/1.0/offline-comparison/analyzeKeywordsWithFreqJSON', {
                    content: textInput.trim(),
                    keywords: list
                }, {
                    params: {
                        language: language
                    },
                    headers: {
                        'Authorization': 'Bearer c19b42aa-8a99-300d-8fa6-1faeaa3be506',
                        "Content-Type": "application/json",
                        "accept": "application/json;charset=UTF-8"
                    }
                }).then(res => {
                    console.log(res)
                    if (res.status === 200 && res.data.status === "ok") {
                        setLoading(false);
                        setResult(res.data);
                        if (res.data.semanticEnrichment <= 29) {
                            setColor('#FF595D');
                        }
                        if (res.data.semanticEnrichment <= 69 && 29 < res.data.semanticEnrichment) {
                            setColor('#FFBE64');
                        }
                        if (res.data.semanticEnrichment > 69) {
                            setColor('#3ED774');
                        }
                    } else {
                        setLoading(false)
                        setShowError(true);
                        setResult(null)
                    }
                }).catch(e => {
                    console.log(e);
                    setShowError(true)
                });
            }
        }
    }


    const ReactivateInitialText = () => {
        axios.get("/Admin/ReactivateText?idText="+formData.id).then(res => {
            setActiveInitialText(res.data);
        }).catch(() => setShowError(true))
    }

    const EnableInitialForm = () => (((nomText && motCle && url && baliseTitle && baliseMeta) && !(formData && formData.initialHtmlText)
        && !(nbrText > 1)) || activeInitialText)

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                   onClick={() => history.goBack()}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'} style={{marginBottom: 20}}>{
                        formData ? "Détails du texte" : "Ajouter un texte"
                    }</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={7}>
                                    <Typography>Détail du formulaire</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nom du texte"}
                                        value={nomText}
                                        fullWidth
                                        color={"primary"}
                                        onChange={e => setNomText(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Mot clé"}
                                        fullWidth
                                        value={motCle}
                                        onChange={e => setMotCle(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Url"}
                                        fullWidth
                                        value={url}
                                        onChange={e => setUrl(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Balise titre"}
                                        fullWidth
                                        value={baliseTitle}
                                        onChange={e => setBaliseTitle(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Balise meta-description"}
                                        value={baliseMeta}
                                        fullWidth
                                        onChange={e => setBaliseMeta(e.target.value)}
                                    />
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <h6 style={{ display: 'flex' }}><p style={{ color: '#c6c6c6' }}>Étape 1 - </p>&nbsp; Insérer votre gap sémantique avec l'occurrence :
                            </h6>
                        </Grid>
                        <Grid item xs={2}>
                            <HelpIcon id="Popover"/>
                            <Popover placement="bottom" isOpen={popoverOpen} target="Popover" toggle={toggle}>
                                <PopoverHeader>Instruction</PopoverHeader>
                                <PopoverBody>
                                    <Container>
                                        <Row>
                                            Format souhaité :
                                            - mot-clé (fréquence)
                                            Séparez les mots-clés par un " Enter ".

                                        </Row>
                                        <Row>
                                            ---------------------------------
                                        </Row>
                                        <Row>
                                            Exemple :
                                        </Row>
                                        <Row>
                                            mot-clé1 (2)
                                        </Row>
                                        <Row>
                                            mot-clé2 (1)
                                        </Row>
                                        <Row>
                                            mot-clé3 (1)
                                        </Row>
                                        <Row>
                                            mot-clé4 (2)
                                        </Row>
                                    </Container>
                                </PopoverBody>
                            </Popover>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={keywords}
                                rowsMax="10"
                                onChange={(e) => setKeywords(e.target.value)}
                                inputProps={{
                                    style: {
                                        display: 'flex',
                                        height: 200,
                                        marginBottom: 20,
                                        overflow: 'auto'

                                    }
                                }}
                                placeholder={'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'}
                                className={classes.form}
                                variant={'outlined'}
                                multiline
                                margin={'dense'}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <h6 style={{ display: 'flex' }}><p style={{ color: '#c6c6c6' }}>Étape 2 - </p>&nbsp;Coller le texte initial dans le champ ci-dessous :
                            </h6>
                        </Grid>
                        <Grid item xs={2}>
                            <p style={{color: '#c6c6c6'}}>(optionnel)</p>
                        </Grid>
                        <Grid item xs={10}/>
                        <Grid item xs={2} style={{ marginTop: -13 }}>
                            {
                                !activeInitialText &&
                                <Button color={'primary'}  variant={"contained"} style={{
                                    color: '#ffffff'
                                }}  onClick={ReactivateInitialText}>Réactiver</Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            toggleTab('1');
                                        }}
                                    >
                                        Texte
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            toggleTab('2');
                                        }}
                                    >
                                        Html
                                    </NavLink>
                                </NavItem>


                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <ReactQuill id={"old"}
                                                modules={modules}
                                                style={{
                                                    height: 200,
                                                    marginBottom: 50
                                                }}
                                                value={initialContent || ""}
                                                readOnly={!EnableInitialForm()}
                                                onChange={(content, delta, source, editor) => {
                                                    setInitialContent(content);
                                                    setTimeout(() => {
                                                        setInitialHtmlText(editor.getContents());
                                                        let textValue = editor.getText().toString();
                                                        setInitialText(textValue);
                                                    }, 100);
                                                }}/>
                                </TabPane>
                                <TabPane tabId="2">
                                    <textarea
                                        value={initialContent}
                                        readOnly
                                        style={{
                                            width: "100%",
                                            margin: 0,
                                            background: "rgb(29, 29, 29)",
                                            boxSizing: "border-box",
                                            color: "rgb(204, 204, 204)",
                                            outline: "none",
                                            padding: "12px 15px",
                                            fontFamily: "Consolas, Menlo, Monaco, Courier New, monospace",
                                            height: 250
                                        }}
                                    />
                                </TabPane>
                            </TabContent>
                        </Grid>
                        <Grid item xs={6}>
                            <b>Nombre total de mots : {NumberOfWords(initialText)}</b>
                        </Grid>

                        <Grid item xs={3}>
                            <Button color={'primary'}  variant={"contained"} style={{
                                backgroundColor: formData && '#848484',
                                color: '#ffffff'
                            }}
                                    disabled={formData}
                                    onClick={() => {
                                        setText('');
                                        setKeywords('');
                                        setLanguage('FRENCH');
                                        setResult(null);
                                        setContent('');
                                        setInitialContent('');
                                        setInitialText('');
                                        setHtmlText(null);
                                        setInitialHtmlText(null);
                                    }}
                            >Réinitialiser</Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button color={"primary"}  variant={"contained"} style={{
                                backgroundColor: !EnableInitialForm() && '#848484',
                                color: '#ffffff'
                            }}
                                    disabled={!EnableInitialForm()}
                                    onClick={() => {
                                        setShowAddCopiedText(true);
                                    }}>Sauvegarder</Button>
                        </Grid>

                        <Grid item xs={5}>

                            <h4 style={{margin: 10}}>Langue : </h4>
                        </Grid>
                        <Grid item xs={7}>
                            <Select
                                labelId="language-label"
                                id="language-select"
                                inputProps={{
                                    style: {
                                        margin: 15
                                    }
                                }}
                                open={openLanguage}
                                onClose={() => setOpenLanguage(false)}
                                onOpen={() => setOpenLanguage(true)}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <MenuItem value={'GERMAN'}>ALLEMAND</MenuItem>
                                <MenuItem value={'ENGLISH'}>ANGLAIS</MenuItem>
                                <MenuItem value={'BULGARIAN'}>BULGARIE</MenuItem>
                                <MenuItem value={'KOREAN'}>CORÉEN</MenuItem>
                                <MenuItem value={'SPANISH'}>ESPAGNOL</MenuItem>
                                <MenuItem value={'ESTONIAN'}>ESTONIEN</MenuItem>
                                <MenuItem value={'FINNISH'}>FINNISH</MenuItem>
                                <MenuItem value={'FRENCH'}>FRANÇAIS</MenuItem>
                                <MenuItem value={'GREEK'}>GREC</MenuItem>
                                <MenuItem value={'ITALIAN'}>ITALIEN</MenuItem>
                                <MenuItem value={'DUTCH'}>NÉERLANDAIS</MenuItem>
                                <MenuItem value={'POLISH'}>POLISH</MenuItem>
                                <MenuItem value={'PORTUGUESE'}>PORTUGAIS</MenuItem>
                                <MenuItem value={'ROMANIAN'}>ROMAIN</MenuItem>
                                <MenuItem value={'RUSSIAN'}>RUSSIE</MenuItem>
                                <MenuItem value={'CZECH'}>TCHÈQUE</MenuItem>
                            </Select>
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} style={{ width: 500, height: 250, overflow: 'auto', marginBottom: 20 }}>
                            <div style={{
                                display: 'flex', borderRadius: 15, borderWidth: 1
                            }}>
                                {
                                    loading && <LinearProgress className={classes.root}/>
                                }
                                {
                                    result &&
                                    <Col>
                                        <Grid item xs={9}>
                                            <h3 style={{ textAlign: 'left' }}>Résultat : </h3>
                                        </Grid>
                                        <b>Enrichissement sémantique: </b>  <h2
                                        style={{
                                            color: color,
                                            marginLeft: 10
                                        }}>{result.semanticEnrichment.toFixed(2)}%</h2>
                                        {
                                            (result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) ?
                                                <div>
                                                    <b>Ajout de mots-clés avec la fréquence restante : </b>
                                                    <p>
                                                        {Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {

                                                            return (
                                                                <span key={key}>{item}(<span
                                                                    style={{textDecorationLine: 'line-through'}}>{convertedText[item]},</span>{result.greenWordsByFreq.greenWordsWithRemainingFreq[item]})<span>  </span></span>
                                                            )
                                                        })}
                                                    </p>
                                                </div> : null
                                        }

                                        {
                                            (result.remainingKeywords && Object.keys(result.remainingKeywords).length > 0) ?
                                                <div>
                                                    <b>Mots clés restants : </b>
                                                    <p>
                                                        {Object.keys(result.remainingKeywords).map((item, key) => {
                                                            let word = item + '(' + result.remainingKeywords[item] + ') '
                                                            return (word)
                                                        })}
                                                    </p>
                                                </div> : null
                                        }
                                        <Grid item xs={5}>
                                            {
                                                ready ? <Button color={'primary'} variant={"contained"}
                                                    onClick={DownloadPdf}>
                                                    Télécharger le pdf
                                    </Button> :
                                                    <Button color={'primary'}  variant={"contained"} onClick={() => {
                                                        setPdfShow(true);
                                                    }}>Générer un PDF</Button>
                                            }
                                        </Grid>
                                    </Col>
                                }


                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <h6 style={{ display: 'flex' }}><p style={{ color: '#c6c6c6' }}>Étape 3 - </p> &nbsp; Ajouter/Compléter ci-dessous votre contenu  :
                            </h6>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{marginBottom: 50}}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => {
                                                toggleTab('1');
                                            }}
                                        >
                                            Texte
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() => {
                                                toggleTab('2');
                                            }}
                                        >
                                            Html
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <ReactQuill
                                            id={"new"}
                                            modules={modules}
                                            theme={'snow'}
                                            value={content}
                                            preserveWhitespace={true}
                                            style={{
                                                height: 250
                                            }}
                                            onChange={(content, delta, source, editor) => {
                                                setContent(content);
                                                setTimeout(() => {
                                                    setHtmlText(editor.getContents());
                                                    let textValue = editor.getText().toString();
                                                    setText(textValue);
                                                    setNbrText(textValue.length);
                                                }, 100);
                                            }}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                    <textarea
                                        value={content}
                                        readOnly
                                        style={{
                                            width: "100%",
                                            margin: 0,
                                            background: "rgb(29, 29, 29)",
                                            boxSizing: "border-box",
                                            color: "rgb(204, 204, 204)",
                                            outline: "none",
                                            padding: "12px 15px",
                                            fontFamily: "Consolas, Menlo, Monaco, Courier New, monospace",
                                            height: 250
                                        }}
                                    />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <b> Nombre total de mots (texte existant & ajouté)
                                : {NumberOfWords(text)}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b> Nombre de mot min à ajouter (défini sur la commande) : {nbrTextMin}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b> Nombre de mot max à ajouter (défini sur la commande) : {nbrTextMax}</b>
                        </Grid>


                        <Grid item xs={12}>
                            <NbrTextColor formData={formData} initialText={initialText} text={text}
                                          setValid={res => setValidNbrtText(res)}
                                          nbrTextMax={nbrTextMax} nbrTextMin={nbrTextMin}/>
                        </Grid>

                        <Grid item xs={4}>
                            <Button onClick={Update}
                                    disabled={!validNbrText}
                                    variant={"contained"}
                                    color={"primary"}
                                    style={{
                                        backgroundColor: !validNbrText && '#848484',
                                        color: '#ffffff'
                                    }}>Sauvegarder</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button color={'primary'}
                                    variant={"contained"}
                                    onClick={() => {
                                        try {
                                            setShowDifference(true)
                                            setTimeout(() => {
                                                try {
                                                    let quill_diff = new Quill("#diff");
                                                    let diff = initialHtmlText.diff(htmlText);
                                                    for (let i = 0; i < diff.ops.length; i++) {
                                                        let op = diff.ops[i];
                                                        if (op.hasOwnProperty('insert')) {
                                                            op.attributes = {
                                                                background: "#cce8cc",
                                                                color: "#003700"
                                                            };
                                                        }
                                                        if (op.hasOwnProperty('delete')) {
                                                            op.retain = op.delete;
                                                            delete op.delete;
                                                            op.attributes = {
                                                                background: "#e8cccc",
                                                                color: "#370000"
                                                            };
                                                        }
                                                    }
                                                    let adjusted = initialHtmlText.compose(diff);
                                                    quill_diff.setContents(adjusted);
                                                } catch (e) {
                                                    console.log(e)
                                                }

                                            }, 100);
                                        } catch (e) {
                                            console.log(e)
                                            setShowDifference(false)
                                        }
                                    }}>Comparer</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button color={'primary'}
                                    variant={"contained"}
                                    onClick={() => {
                                        setResult(null);
                                        setLoading(true);
                                        FetchResult();
                                    }}>Analyser</Button>
                        </Grid>


                    </Grid>

                </Grid>
            </Grid>
            <Modal isOpen={pdfReady} toggle={() => setPdfShow(false)}>
                <ModalHeader toggle={() => setPdfShow(false)}>Veuillez saisir le nom du mot-clé principal de
                    l'analyse (à utiliser pour le nom du pdf) : </ModalHeader>
                <ModalBody>
                    <TextField
                        onChange={e => setPdfTitle(e.target.value)}
                    />

                </ModalBody>
                <ModalFooter>
                    <Button   variant={"contained"} color="primary" onClick={() => {
                        const pdfr = pdfTitle + '-' + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear()
                        setPdfTitle(pdfr)
                        setPdfShow(false)
                        setReady(true)
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>
            {
                showError ?
                    <Modal isOpen={showError} toggle={() => {
                        setShowError(false);
                        setLoading(false);
                        setResult(null)
                    }}>
                        <ModalHeader toggle={() => {
                            setShowError(false);
                            setLoading(true);
                            setResult(null)
                        }}>Problème</ModalHeader>
                        <ModalBody>
                            Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard. Si vous
                            ne
                            pouvez toujours pas exécuter
                            votre analyse, veuillez contacter <Link
                            to='#'
                            onClick={(e) => {
                                window.location = 'mailto:support@pixalione.com';
                                e.preventDefault();
                            }}
                        >
                            support@pixalione.com
                        </Link>..
                        </ModalBody>
                        <ModalFooter>
                            <Button   variant={"contained"} color="primary" onClick={() => {
                                setShowError(false);
                                setLoading(false);
                                setResult(null)
                            }}>Ok</Button>
                        </ModalFooter>
                    </Modal> : null
            }
            <Modal isOpen={success} toggle={() => {
                setSuccess(false);
            }}>
                <ModalHeader toggle={() => {
                    setSuccess(false);
                }}>Succès</ModalHeader>
                <ModalBody>
                    Modification effectuée avec succès
                </ModalBody>
                <ModalFooter>
                    <Button  variant={"contained"} color="primary" onClick={() => {
                        setSuccess(false);
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showDifference} toggle={() => {
                setShowDifference(false);
            }}>
                <ModalHeader toggle={() => {
                    setShowDifference(false);
                }}>Différence</ModalHeader>
                <ModalBody>
                    <ReactQuill id={"diff"}
                                theme={'bubble'}
                                readOnly/>
                </ModalBody>
                <ModalFooter>
                    <Button  variant={"contained"} color="primary" onClick={() => {
                        setShowDifference(false);
                    }}>Ok</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showAddCopiedText} toggle={() => {
                setShowAddCopiedText(false);
            }}>
                <ModalHeader toggle={() => {
                    setShowAddCopiedText(false);
                }}>Confirmation</ModalHeader>
                <ModalBody>
                    Êtes vous sur de vouloir sauvegarder le texte ?
                </ModalBody>
                <ModalFooter>
                    <Button  variant={"contained"} color="primary" onClick={() => {
                        setShowAddCopiedText(false);
                    }}>Annuler</Button>
                    <Button color="primary" onClick={AddCopiedText}>Oui</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )


}


const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        margin: 15
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
/*
  <TextField
                        value={text}
                        className={classes.form}
                        multiline
                        inputProps={{
                            style: {
                                display: 'flex'
                            }
                        }}
                        onChange={(e) => setText(e.target.value)}
                        variant={'outlined'}
                        rowsMax="10"
                    />
 */

/*

const DownloadPdff = () => {
const body = document.getElementById("pdf");
const width = body.getBoundingClientRect().width;
const height = body.getBoundingClientRect().height;
console.log(width);
console.log(height);
const pdf = new jsPDF('p', 'pt', [height, width]);
pdf.html(body, { html2canvas: { scale: 0.57 } }).then(() => {
pdf.save(pdfTitle + '.pdf');
});
}

<Accordion>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1a-content"
id="panel1a-header"
>
<Typography>Visualiser le document</Typography>
</AccordionSummary>
<AccordionDetails>
<div id={"pdf"} style={{ display: 'flex' }}>
    <PdfView
        user={user} keywords={keywords} text={text} htmlText={htmlText}
        result={result}
        color={color}
        convertedText={convertedText} />
</div>
</AccordionDetails>
</Accordion>
*/
