import {createSlice} from '@reduxjs/toolkit'
import {fetchConsultantCommandDisplay} from "./features";


export const consultantCommandDisplay = createSlice({
    name: 'consultantCommandDisplay',
    initialState: {
        command: null,
        isLoading: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchConsultantCommandDisplay.fulfilled, (state, {payload}) => {
            state.command = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchConsultantCommandDisplay.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchConsultantCommandDisplay.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default consultantCommandDisplay.reducer


export const consultantCommandDisplaySelector = (state) => state.consultantCommandDisplay;
