import {createSlice} from '@reduxjs/toolkit'
import {fetchAdminCommandList} from "./features";


export const adminCommandList = createSlice({
    name: 'adminCommandList',
    initialState: {
        title: null,
        url: null,
        listCommand: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateCommande: (state, action) => {
            const index = state.listCommand.findIndex(obj => obj.id === action.payload.id);
            state.listCommand[index] = action.payload.data;
        },
        sortListCommand: (state, {payload}) => {
            switch (payload.column) {
                default :
                    break;
                case "delivered_texts" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        const pourcentageA = (a.deliveredText * 100) / a.nbrText;
                        const pourcentageB = (b.deliveredText * 100) / b.nbrText;
                        if (payload.direction === "asc") {
                            return pourcentageA - pourcentageB;
                        } else {
                            return pourcentageB - pourcentageA;
                        }
                    });
                    break;
                case "validated_texts" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        const pourcentageA = (a.validatedText * 100) / a.nbrText;
                        const pourcentageB = (b.validatedText * 100) / b.nbrText;
                        if (payload.direction === "asc") {
                            return pourcentageA - pourcentageB;
                        } else {
                            return pourcentageB - pourcentageA;
                        }
                    });
                    break;
                case "date" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return new Date(a.deadline) - new Date(b.deadline);
                        } else {
                            return new Date(b.deadline) - new Date(a.deadline);
                        }
                    });
                    break;
                case "temps_restant" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (a.remainingTime === null) {
                            return 1;
                        }
                        if (b.remainingTime === null) {
                            return -1;
                        }
                        if (a.remainingTime === b.remainingTime) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return a.remainingTime < b.remainingTime ? -1 : 1;
                        } else {
                            return a.remainingTime < b.remainingTime ? 1 : -1;
                        }
                    });
                    break;
                case "thematique" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (a.commandeThematique.length === 0) {
                            return 1;
                        }
                        if (b.commandeThematique.length === 0) {
                            return -1;
                        }
                        const sortA = a.commandeThematique.map(x => x.thematique).sort();
                        const sortB = b.commandeThematique.map(x => x.thematique).sort();
                        if (payload.direction === "asc") {
                            return sortA < sortB ? -1 : 1;
                        } else {
                            return sortB < sortA ? -1 : 1;
                        }
                    })
                    break;
                case "redacteur" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (a.demandeRedacteur.length === 0) {
                            return 1;
                        }
                        if (b.demandeRedacteur.length === 0) {
                            return -1;
                        }
                        const sortA = a.demandeRedacteur.map(x => x.idRedacteurNavigation).sort();
                        const sortB = b.demandeRedacteur.map(x => x.idRedacteurNavigation).sort();
                        if (payload.direction === "asc") {
                            return sortA < sortB ? -1 : 1;
                        } else {
                            return sortB < sortA ? -1 : 1;
                        }
                    })
                    break;
                case "validated" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return a.allTextValidated - b.allTextValidated;
                        } else {
                            return b.allTextValidated - a.allTextValidated;
                        }
                    })
                    break;
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAdminCommandList.fulfilled, (state, {payload}) => {
            state.listCommand = payload.data;
            state.title = payload.title;
            state.url = payload.url;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchAdminCommandList.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchAdminCommandList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminCommandList.reducer


export const adminCommandListSelector = (state) => state.adminCommandList;


export const {updateCommande, sortListCommand} = adminCommandList.actions
