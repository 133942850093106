import React, {useEffect, useState} from 'react'
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Homepage.css"
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListCommande from "./ListCommande";
import Grid from "@material-ui/core/Grid";
import Jumbotron from "reactstrap/lib/Jumbotron";
import Divider from "@material-ui/core/Divider";
import {fetchClientList} from "../../store/Consultant/ClientList/features";
import {useDispatch, useSelector} from "react-redux";
import {UserSessionSelector} from "../../store/UserSession";
import {consultantClientListSelector} from "../../store/Consultant/ClientList";
import {updateClientForm} from "../../store/Consultant/ClientDisplay";
import {useHistory} from "react-router-dom";
import {fetchClientsDetails} from "../../store/Consultant/ClientsDetails/features";

//TODO finish the commande state
export default function HomePage() {


    let {user} = useSelector(UserSessionSelector);
    let {allClients, myClients, isLoadingClients} = useSelector(consultantClientListSelector);

    const [allClientNameSearch, setAllClientNameNameSearch] = useState(null);
    const [myClientNameSearch, setMyClientNameNameSearch] = useState(null);
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])

    useEffect(() => {
        dispatch(fetchClientList(user.id));
        document.title = "Typix - Liste des commandes";
    }, [])

    return (
        <div style={{marginTop: 5, marginBottom: 100, marginRight: 50, marginLeft: 50}}>
            <Grid container spacing={3} >
                <Grid item xs={3} >
                    <Jumbotron>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography> Mes clients : </Typography>
                            </Grid>

                            {isLoadingClients && <Grid item xs={12}> <CircularProgress color="secondary"/></Grid>}
                            <Grid item xs={12}>
                                {!myClients && <CircularProgress color="secondary"/>}
                                {(myClients && myClients.length > 0) &&
                                <div>
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-1-demo"
                                        disableClearable
                                        options={myClients}
                                        onInputChange={((event, value) => setMyClientNameNameSearch(value))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recherche"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{...params.InputProps, type: 'search'}}
                                            />
                                        )}
                                    />

                                    <List component="nav" aria-label="secondary mailbox folders">
                                        {
                                            myClients.filter(x => {
                                                if (myClientNameSearch && myClientNameSearch.length > 0) {
                                                    return x.toUpperCase().includes(myClientNameSearch.toUpperCase())
                                                }
                                                return true;
                                            }).map((item, key) => <ListItem key={key} button
                                                                                   onClick={() => {
                                                                                       dispatch(fetchClientsDetails(item))
                                                                                       history.push('/Consultant/FicheClient')
                                                                                   }}>
                                                <ListItemText primary={item}/>
                                            </ListItem>)
                                        }

                                    </List>
                                </div>
                                }
                                {
                                    (myClients && myClients.length === 0) &&
                                    <Typography>Votre liste est vide</Typography>
                                }
                            </Grid>
                            <Grid item xs={12}><Divider/></Grid>
                            <Grid item xs={12}>
                                <Typography> Autres clients : </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {isLoadingClients && <CircularProgress color="secondary"/>}
                                {(allClients && allClients.length > 0) &&
                                <div>

                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={allClients}
                                        onInputChange={((event, value) => setAllClientNameNameSearch(value))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recherche"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{...params.InputProps, type: 'search'}}
                                            />
                                        )}
                                    />

                                    <List component="nav" aria-label="secondary mailbox folders">
                                        {
                                            allClients.filter(x => {
                                                if (allClientNameSearch && allClientNameSearch.length > 0) {
                                                    return x.toUpperCase().includes(allClientNameSearch.toUpperCase())
                                                }
                                                return true;
                                            }).map((item, key) => <ListItem key={key} button
                                                                                    onClick={() => {
                                                                                        dispatch(fetchClientsDetails(item))
                                                                                        history.push('/Consultant/FicheClient')
                                                                                    }}>
                                                <ListItemText primary={item}/>
                                            </ListItem>)
                                        }

                                    </List>
                                </div>
                                }
                                {
                                    (allClients && allClients.length === 0) &&
                                    <Typography paragraph variant={"h5"}>Votre liste est vide</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Jumbotron>
                </Grid>

                <Grid item xs={9}>
                    <ListCommande/>
                </Grid>
            </Grid>
        </div>
    )
}
