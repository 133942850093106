import {createSlice} from '@reduxjs/toolkit'


export const consultantAnalyzer = createSlice({
    name: 'consultantAnalyzer',
    initialState: {
        formData: null
    },
    reducers: {
        updateForm: (state, action) => {
            state.formData = action.payload.data;
        }
    }
})


export default consultantAnalyzer.reducer

export const {updateForm} = consultantAnalyzer.actions
export const consultantAnalyzerSelector = (state) => state.consultantAnalyzer;
