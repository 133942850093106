import * as React from 'react';
import {Route} from 'react-router';
import Layout from './Layout';
import HomePage from "./Homepage";
import MyProfile from "./MyProfile";
import CommandeDisplay from "./CommandeDisplay";
import Analyzer from "./Analyzer";
import FicheClient from "./FicheClient";

export default class MainMenu extends React.Component {

    render() {
        return (
            <Layout>
                <Route path='/Consultant/MainMenu' render={(props) => <HomePage {...props} reload={true}/>}/>
                <Route path='/Consultant/MonProfile' component={MyProfile}/>
                <Route path='/Consultant/DétailsDeLaCommande' component={CommandeDisplay}/>
                <Route path='/Consultant/TextDetails' component={Analyzer}/>
                <Route path='/Consultant/FicheClient' component={FicheClient}/>
            </Layout>
        )
    }

}
