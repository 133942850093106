import React, {useState, useEffect} from 'react'
import {MDBDataTable} from "mdbreact";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";


export default function ListCommandeClient(props) {
    const [list, setList] = useState([]);
    useEffect(() => {

        console.log(props)
        if (props.selectedCommands && props.selectedCommands.length > 0) {
            const columns = [
                {
                    label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Titre de la commande</div>,
                    field: 'commande',
                    sort: 'asc',
                    width: 250,
                }, {
                    label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Consultant</div>,
                    field: 'consultant',
                    sort: 'asc',
                    width: 250,
                }
            ];
            let rows = [];
            props.selectedCommands.map(item => rows.push({
                commande: item.titre,
                consultant: item.consultant
            }));
            setList({columns, rows});
        }
    }, [props]);


    if (!list || !props.visible) {
        return null
    }

    return (
        <Dialog open={props.visible} onClose={props.Close} fullWidth maxWidth={'lg'}>
            <DialogTitle disableTypography style={{minWidth: 800}}>
                <div style={{display: 'flex'}}>
                    <Typography style={{width: '80%'}} variant="h6">Liste des commandes</Typography>
                    <IconButton onClick={props.Close} style={{display: 'contents', justifyContent: 'flex-end'}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{minWidth: 800}}>
                <MDBDataTable striped hover entriesOptions={[10, 20, 50]} entries={10}
                              noBottomColumns
                              infoLabel={['', '-', 'sur', 'résultats']}
                              sortable
                              paging={list && list.rows && list.rows.length > 0}
                              paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                              searchLabel={'Recherche'}
                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                              info={true}
                              entriesLabel={'Afficher les résultats'}
                              data={list}/>
            </DialogContent>
        </Dialog>
    )
}
