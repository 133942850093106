import {createSlice} from '@reduxjs/toolkit'
import {fetchCommandSummary} from "./features";


export const adminCommandSummary = createSlice({
    name: 'adminCommandSummary',
    initialState: {
        ordersInProgress: null,
        ordersDeadlineExceeded: null,
        ordersCompleted: null,
        ordersUnassigned: null,
        isLoading: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCommandSummary.fulfilled, (state, {payload}) => {
            state.ordersInProgress = payload.ordersInProgress;
            state.ordersDeadlineExceeded = payload.ordersDeadlineExceeded;
            state.ordersCompleted = payload.ordersCompleted;
            state.ordersUnassigned = payload.ordersUnassigned;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchCommandSummary.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchCommandSummary.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminCommandSummary.reducer


export const commandSummarySelector = (state) => state.adminCommandSummary;
