import React, {useEffect, useState} from 'react'
import {Col, Row, Container} from "reactstrap";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Homepage.css"
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Scrollbars} from 'react-custom-scrollbars';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import {commandSummarySelector} from "../../store/Admin/CommandSummary";
import {fetchCommandSummary} from "../../store/Admin/CommandSummary/features";
import {adminClientListSelector} from "../../store/Admin/ClientList";
import {fetchClientList} from "../../store/Admin/ClientList/features";
import {fetchAdminCommandList} from "../../store/Admin/CommandList/features";
import {useHistory} from 'react-router-dom';
import {updateClientForm} from "../../store/Admin/ClientDisplay";
import {useTranslation} from "react-i18next";
import {fetchWebsiteDisplay} from "../../store/Admin/WebsiteDisplay/features";
//TODO fix the loader
export default function HomePage() {
    const {t} = useTranslation();
    let {
        ordersInProgress,
        ordersDeadlineExceeded,
        ordersCompleted,
        ordersUnassigned,
        isLoading
    } = useSelector(commandSummarySelector);

    let {
        clients,
        isLoadingClients
    } = useSelector(adminClientListSelector);

    const dispatch = useDispatch();
    const history = useHistory();

    const [nameSearch, setNameSearch] = useState(null);
    const SelectThis = (input, title) => {
        dispatch(fetchAdminCommandList({
            url: input,
            title: title
        }))
        if (title === t('admin.homepage.ordersUnassigned')) {
            history.push("/Admin/AffichageDesCommandesNonAttribuée")
        } else {
            history.push("/Admin/AffichageDesCommandes")
        }
    }

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])

    useEffect(() => {
        dispatch(fetchCommandSummary());
        dispatch(fetchClientList());
        document.title = "Typix - Mon tableau de bord"
    }, [])


    return (
        <Container>
            <Row>
                <Col>
                    <Typography variant={'h5'} style={{marginBottom : 20}} >Mon tableau de bord</Typography>
                </Col>
            </Row>
            <Row>
                <Col className={"right-form"} xs={5}>
                    <form className={"form"} id={"myForm"} style={{display: 'flex'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography paragraph
                                            variant={"h5"}>{t('admin.homepage.clientListContracts')}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{height: '70%'}}>
                                {isLoadingClients ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <CircularProgress color="secondary"/>
                                    </div>
                                    : (clients && clients.length > 0) ?
                                        <div>
                                            <Autocomplete
                                                id="free-solo-2-demo"
                                                disableClearable
                                                freeSolo
                                                options={clients}
                                                getOptionLabel={(option) => option.urlSite}
                                                onInputChange={((event, value) => setNameSearch(value))}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('admin.homepage.search')}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{...params.InputProps, type: 'search'}}
                                                    />
                                                )}
                                            />
                                            <Scrollbars autoHeight>

                                                <List component="nav" style={{maxHeight: '100%', overflow: 'auto'}}>
                                                    {
                                                        clients.filter(x => {
                                                            if (nameSearch && nameSearch.length > 0) {
                                                                return x.urlSite.includes(nameSearch)
                                                            }
                                                            return true;
                                                        }).map((item, key) => <ListItem key={key} button
                                                                                        onClick={() => {
                                                                                            dispatch(fetchWebsiteDisplay(item.id));
                                                                                            history.push("/Admin/FicheClient")
                                                                                        }}>
                                                            <ListItemText primary={item.urlSite}/>
                                                        </ListItem>)
                                                    }

                                                </List>

                                            </Scrollbars>
                                        </div> :
                                        <Typography paragraph
                                                    variant={"h5"}>{t('admin.homepage.emptyList')}</Typography>

                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" component="span"
                                        onClick={() => {
                                            history.push("/Admin/AjouterClient")
                                        }}>
                                    {t('admin.homepage.addClient')}
                                </Button>

                            </Grid>
                        </Grid>


                    </form>
                </Col>
                <Col xs={7}>
                    <Row>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        onClick={() => SelectThis("OrdersUnassigned", t('admin.homepage.ordersUnassigned'))}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                                    <h5>{t('admin.homepage.ordersUnassigned')}</h5>
                                    <h5>{!isLoading ? ordersUnassigned : "XXX"}</h5>
                                </button>
                            </div>

                        </Col>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersInProgress", t('admin.homepage.ordersInProgress'))}>
                                    <h5>{t('admin.homepage.ordersInProgress')}</h5>
                                    <h5>{!isLoading ? ordersInProgress : "XXX"}</h5>
                                </button>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersCompleted", t('admin.homepage.ordersCompleted'))}>
                                    <h5>{t('admin.homepage.ordersCompleted')}</h5>
                                    <h5>{!isLoading ? ordersCompleted : "XXX"}</h5>
                                </button>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className={"commande-form"}>
                                <button className={"commande"} disabled={isLoading}
                                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                        onClick={() => SelectThis("OrdersDeadlineExceeded", t('admin.homepage.ordersDeadlineExceeded'))}>
                                    <h5>{t('admin.homepage.ordersDeadlineExceeded')}</h5>
                                    <h5>{!isLoading ? ordersDeadlineExceeded : "XXX"}</h5>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
/*
*   const AddRedacteurTest = () => {
testdata.map(item => {
if (item['Mail'] && item['Téléphone'] && item['Nom Prénom'] && item['Langue'] && item['Thématique'] ) {
let langueItem = item['Langue'].split(';').filter(x => x.length > 0).map(x => x.slice(0, x.length))
let thematiqueItem = item['Thématique'].split(';').filter(x => x.length > 0).map(x => x.slice(0, x.length))
const note = item['Note (sur 5)'] ? item['Note (sur 5)'].length : 0;
console.log({
redacteur: {
mail: item['Mail'],
nomPrenom: item['Nom Prénom'],
motDePasse: item["Mot de passe"],
numTelephone: item['Téléphone'],
nomSociete: '',
adresse: '',
note : note
},
langue: langueItem,
thematique: thematiqueItem,
passwordChanged: false
})
axios.post(data.URL + "/Admin/AddRedacteur", {
redacteur: {
mail: item['Mail'],
nomPrenom: item['Nom Prénom'],
motDePasse: item["Mot de passe"],
numTelephone: item['Téléphone'],
nomSociete: '',
adresse: '',
note: note
},
langue: langueItem,
thematique: thematiqueItem,
passwordChanged: false
}, {
headers: {
Authorization: `Bearer ${UserSession.token}`
}
}).then(e => console.log(e.response.data))

}
})
}
*    axios.post(data.URL + "/Admin/AddRedacteur", {
redacteur: {
mail: item['Mail'],
nomPrenom: item['Nom Prénom'],
motDePasse: item["Mot de passe"],
numTelephone: item['Téléphone'],
nomSociete: '',
adresse: '',
note: note
},
langue: langueItem,
thematique: thematiqueItem,
passwordChanged: false
}, {
headers: {
Authorization: `Bearer ${UserSession.token}`
}
}).then(e => console.log(e.response.data))
const testdata = [
{
"Nom Prénom": "Alexis manière",
"Mail": "alexis.maniere@hotmail.fr",
"Téléphone": "06 73 82 20 02",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "AlexisM2021",
"Thématique": "Polyvalent;Banque/Assurance;Immo/Defisc;Outillage;Droit"
},
{
"Nom Prénom": "Anne Marie Arinal",
"Mail": "annemarie.redac@gmail.com",
"Téléphone": "06.73.80.21.08",
"Note (sur 5)": "****",
"Langue": "FR;EN",
"Mot de passe": "AnneMarieA2021",
"Thématique": "Polyvalent;Business/Management;Banque/Assurance;Tourisme/Loisirs"
},
{
"Nom Prénom": "Antonin Cyrille",
"Mail": "antonin.cyrille@outlook.fr",
"Téléphone": "06 45 58 55 39",
"Note (sur 5)": "*****",
"Langue": "FR;",
"Mot de passe": "AntoninC2021",
"Thématique": "Polyvalent;Banque/Assurance;Immo/Defisc;Auto;Mode;Technologie;Literie;Droit"
},
{
"Nom Prénom": "Arnaud bernier",
"Mail": "bernier.arnaud.91@gmail.com",
"Téléphone": "06 33 71 29 75",
"Note (sur 5)": "***",
"Langue": "FR;EN;IT;DE",
"Mot de passe": "ArnaudB2021",
"Thématique": "Polyvalent;Maison/Deco;Tourisme/Loisirs;Santé/Bien-être;Humanitaire;Outillage;Travaux/BTP"
},
{
"Nom Prénom": "Céline Weber",
"Mail": "celine@uppercase.fr",
"Téléphone": "06 85 55 15 57",
"Note (sur 5)": "****",
"Langue": "FR;EN",
"Mot de passe": "CelineW2021",
"Thématique": "Polyvalent;Tourisme/Loisirs;Santé/Bien-être;Technologie"
},
{
"Nom Prénom": "Christian Mibanga Solano",
"Mail": "christian.mibanga@outlook.com",
"Téléphone": "07 68 12 65 09",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "ChristianMS2021",
"Thématique": "Polyvalent;Outillage;Travaux/BTP"
},
{
"Nom Prénom": "Clémentine Brochier",
"Mail": "clementine.brochier@gmail.com",
"Téléphone": "06 59 68 81 17",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "ClementineB2021",
"Thématique": "Polyvalent;Maison/Deco;Outillage;Travaux/BTP"
},
{
"Nom Prénom": "Estelle Corre",
"Mail": "estelle.corre21@gmail.com",
"Téléphone": "06 29 80 90 00",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "EstelleC2021",
"Thématique": "Polyvalent;Tourisme/Loisirs;Mode;Beauté;Téléphonie;Energie/Environnement"
},
{
"Nom Prénom": "Lavinia Cometa",
"Mail": "lavinia.cometa@gmail.com",
"Note (sur 5)": "****",
"Langue": "FR;IT",
"Mot de passe": "LaviniaC2021",
"Thématique": "Polyvalent;Mode;Beauté"
},
{
"Nom Prénom": "Lucie Boiago",
"Mail": "lucieboiago@gmail.com",
"Téléphone": "07 78 68 25 95",
"Note (sur 5)": "***",
"Langue": "FR;",
"Mot de passe": "LucieB2021",
"Thématique": "Polyvalent;Tourisme/Loisirs;Grande distribution;Mode;Santé/Bien-être;Outillage;Energie/Environnement;Travaux/BTP;Droit"
},
{
"Nom Prénom": "Marie-Amélie Clément",
"Mail": "mamelie.clement@gmail.com",
"Téléphone": "06 38 61 32 98",
"Note (sur 5)": "***",
"Langue": "FR;",
"Mot de passe": "MarieAmelieC2021",
"Thématique": "Polyvalent;Maison/Deco;Tourisme/Loisirs;Grande distribution;Mode;Puériculture;Santé/Bien-être;Beauté;Energie/Environnement"
},
{
"Nom Prénom": "Marlène Piriou",
"Mail": "marlene.piriou@gmail.com",
"Téléphone": "06 10 55 79 00",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "MarleneP2021",
"Thématique": "Polyvalent;Maison/Deco;Tourisme/Loisirs;Mode;Beauté;Travaux/BTP"
},
{
"Nom Prénom": "Morgane Leroy",
"Mail": "morganeleroy0310@gmail.com",
"Téléphone": "06 51 15 86 01",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "MorganeL2021",
"Thématique": "Polyvalent;Business/Management;Tourisme/Loisirs;Mode;Santé/Bien-être;Beauté;Energie/Environnement;Travaux/BTP;Droit"
},
{
"Nom Prénom": "Rachel Bertrand",
"Mail": "rach.bertrand@sfr.fr",
"Téléphone": "06 63 60 90 78",
"Note (sur 5)": "*****",
"Langue": "FR;",
"Mot de passe": "RachelB2021",
"Thématique": "Polyvalent;Maison/Deco;Outillage;Travaux/BTP"
},
{
"Nom Prénom": "Sarah Vinet",
"Mail": "sarahredacweb@gmail.com",
"Téléphone": "06 25 01 29 60",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "SarahV2021",
"Thématique": "Polyvalent;Business/Management;Mode;Santé/Bien-être;Beauté"
},
{
"Nom Prénom": "Vanessa Halot",
"Mail": "vanessa.halot@gmail.com",
"Téléphone": "06 12 64 31 23",
"Note (sur 5)": "****",
"Langue": "FR;",
"Mot de passe": "VanessaH2021",
"Thématique": "Polyvalent;Banque/Assurance;Immo/Defisc;Tourisme/Loisirs;Mode;Santé/Bien-être;Beauté"
}
]
/* const AddRedacteurTest = () => {
redacteur.Base.map(item => {
if(item['Mail'] &&  item['Téléphone']&& item['Nom Prénom'] && item['Langues'] && item['Thématiques'] ) {
if (item['Mail'].length > 0 && item['Téléphone'].length > 0 && item['Nom Prénom'].length > 0 && item['Langues'].length > 0 && item['Thématiques'].length > 0) {
let langueItem = item['Langues'].split(';').filter(x => x.length > 0).map(x => x.slice(0, x.length))
let thematiqueItem = item['Thématiques'].split(';').filter(x => x.length > 0).map(x => x.slice(0, x.length))
const note = item['Note (sur 5)'] ? item['Note (sur 5)'].length : 0;
console.log({
redacteur: {
mail: item['Mail'],
nomPrenom: item['Nom Prénom'],
motDePasse: 'Password123',
numTelephone: item['Téléphone'],
nomSociete: '',
adresse: '',
note : note
},
langue: langueItem,
thematique: thematiqueItem,
passwordChanged: false
})
axios.post(data.URL + "/Admin/AddRedacteur", {
redacteur: {
mail: item['Mail'],
nomPrenom: item['Nom Prénom'],
motDePasse: 'Password123',
numTelephone: item['Téléphone'],
nomSociete: '',
adresse: '',
note : note
},
langue: langueItem,
thematique: thematiqueItem,
passwordChanged: false
}).then(e => console.log(e.response.data))
}
}
})
}
*/
