import React, {useEffect, useState} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import {
    BatteryDisplay,
    CheckValid,
    DeliveredTexts,
    getDateFormat,
    HowManyDayLeft,
    ValidatedTexts
} from "../../helpers/Constants";
import axios from "axios";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useDispatch, useSelector} from "react-redux";
import {clientCommandListSelector} from "../../store/Client/CommandList";
import {UserSessionSelector} from "../../store/UserSession";
import {fetchClientCommandList} from "../../store/Client/CommandList/features";
import LinearProgress from "@material-ui/core/LinearProgress";
import {fetchClientNavCounter} from "../../store/Client/NavCounter/features";
import {fetchClientCommandDisplay} from "../../store/Client/CommandDisplay/features";
import {useHistory} from "react-router-dom";
import {Badge} from "reactstrap";
import {sortListCommand} from "../../store/Client/CommandList";
import TextField from "@material-ui/core/TextField";

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
export default function Homepage() {
    const classes = useStyle();

    const history = useHistory();
    let {listCommand, isLoading} = useSelector(clientCommandListSelector);
    let {user} = useSelector(UserSessionSelector)
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const[search,setSearch] = useState('');
    const UpdateNew = (id) => {
        axios.get("/Client/ChangeNewState?id=" + id).then(() => {
            dispatch(fetchClientNavCounter(user.id));
            dispatch(fetchClientCommandList(user.id));
        })
    }

    const fetchData = () => {
        const columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Commande</div>,
                field: 'title',
                width: 250,
            }, {
                label: <div style={{cursor: 'text', fontWeight: 'bold', width: 200}}>Détails de la commande</div>,
                field: 'details',
                sort: 'disabled',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date de livraison estimée</div>,
                field: 'date',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes livrés</div>,
                field: 'delivered_texts',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes validés</div>,
                field: 'validated_texts',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Temps restant pour valider la
                    commande</div>,
                field: 'temps_restant',
                width: 250,
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Validée</div>,
                field: 'validated',
                width: 250,
            }
        ];
        let rows = [];
        listCommand.filter(x => {
            if(search.length === 0) {
                return true;
            }
            return (x.titre.toUpperCase().includes(search.toUpperCase()) ||  getDateFormat(x.deadline).includes(search));
        }).map(item => rows.push({
            title: <div style={{display: 'flex', alignItems: 'center'}}><Typography
                style={{color: item.newText ? '#32c200' : '#282828'}}>{item.titre}</Typography> {
               item.new &&
                <Badge pill color="success">New!</Badge>
            }
            </div>,
            date: <Typography
                color={(new Date() > new Date(item.deadline)) ? "secondary" : "initial"}>  {item.deadline ? getDateFormat(item.deadline) : "non spécifié"} </Typography>,
            delivered_texts: <DeliveredTexts deliveredText={item.deliveredText} total={parseInt(item.nbrText)} />,
            validated_texts: <ValidatedTexts validatedText={item.validatedText} total={parseInt(item.nbrText)} />,
            temps_restant:<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {
                    item.allTextValidated ? <Typography>0 jour</Typography> : (item.remainingTime ?
                        <HowManyDayLeft id={item.id} finishedAt={item.finishedAt} url={"/Client"}/> :
                        <Typography> - </Typography>)
                }
            </div>,
            validated: <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{
                item.allTextValidated ? <CheckCircleIcon
                    style={{color: 'green'}}/> : <Typography>-</Typography>
            }</div>,
            details: <Button variant="contained" color="primary" onClick={() => {
                if (item.new) {
                    UpdateNew(item.id)
                }
                dispatch(fetchClientCommandDisplay(item.id))
                history.push("/Client/DétailsDeLaCommande")
            }}>Voir la commande</Button>
        }));
        setData({columns, rows});
    }

    useEffect(() => {
        dispatch(fetchClientCommandList(user.id))
        document.title = "Typix - Mes commandes";
    }, [])

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    },[])


    useEffect(() => {
        if (!isLoading)
            fetchData()
    }, [listCommand, isLoading,search])

    return (

        <div style={{marginTop: 5, marginBottom: 100, marginRight: 100, marginLeft: 100}}>
            <Card className={classes.cardContainer}>
                <CardHeader className={classes.cardHeader} title={"Mes commandes"}/>
                <CardContent>
                    {
                        isLoading ? <React.Fragment>
                                <LinearProgress/>
                                <LinearProgress color="secondary"/>
                            </React.Fragment> :

                            <React.Fragment>
                                <TextField
                                    label={"Recherche"}
                                    style={{float: 'right' , position :'relative'}}
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />

                                <MDBDataTable striped entriesOptions={[10, 20, 50]} entries={10}
                                              noBottomColumns
                                              infoLabel={['Affichage', 'a', 'de', 'résultats']}
                                              sortable
                                              searching={false}
                                              onSort={({column, direction}) => {
                                                  if (data && data.rows && data.rows.length > 0 && column) {
                                                      dispatch(sortListCommand({column, direction}))
                                                  }
                                              }}
                                              paginationLabel={['Précédent', 'Suivant']}
                                              searchLabel={'Recherche'}
                                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                              info={true}
                                              entriesLabel={'Afficher les résultats'}
                                              data={data}/>

                            </React.Fragment>

                    }


                </CardContent>
            </Card>
        </div>
    )
}


