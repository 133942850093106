import {createSlice, current} from '@reduxjs/toolkit'
import {fetchAdminUserManagement} from "./features";


export const adminUserManagement = createSlice({
    name: 'adminUserManagement',
    initialState: {
        userList: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        sortListUser: (state, {payload}) => {

            let list = current(state.userList);
            switch (payload.column) {
                default :
                    break;
                case "site" :
                    list = list.filter(x => x.role === "Client").sort((a, b) => {
                        if (a.urlSite.length === 0) {
                            return 1;
                        }
                        if (b.urlSite.length === 0) {
                            return -1;
                        }
                        if (a.urlSite[0] === b.urlSite[0]) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return a.urlSite[0] < b.urlSite[0] ? -1 : 1;
                        } else {
                            return a.urlSite[0] < b.urlSite[0] ? 1 : -1;
                        }
                    });
                    state.userList = list.concat(current(state.userList).filter(x => x.role !== "Client"));
                    break;

                case  "nomPrenom" :
                    state.userList  =   state.userList.sort((a, b) => {
                        if (a.nomPrenom === b.nomPrenom) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return a.nomPrenom.localeCompare(b.nomPrenom);
                        } else {
                            return b.nomPrenom.localeCompare(a.nomPrenom);
                        }
                    })
                    break;

                case  "mail" :
                    state.userList  =   state.userList.sort((a, b) => {
                        if (a.mail === b.mail) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return a.mail.localeCompare(b.mail);
                        } else {
                            return b.mail.localeCompare(a.mail);
                        }
                    })
                    break;
                case "analyse" :
                    list = list.filter(x => x.role === "Redacteur").sort((a, b) => {
                        const dataA = JSON.parse(a.data);
                        const dataB = JSON.parse(b.data);
                        if (!dataA.Analysis) {
                            dataA.Analysis = 0
                        }
                        if (!dataA.Limite) {
                            dataA.Limite = 0
                        }
                        if (!dataB.Analysis) {
                            dataB.Analysis = 0
                        }
                        if (!dataB.Limite) {
                            dataB.Limite = 0
                        }
                        const res1 = dataA.Analysis - dataA.Limite;
                        const res2 = dataB.Analysis - dataB.Limite;
                        if (res1 === res2) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return res1 < res2 ? -1 : 1;
                        } else {
                            return res1 < res2 ? 1 : -1;
                        }
                    });
                    const FN = list.concat(current(state.userList).filter(x => x.role !== "Redacteur"))
                    state.userList = FN;
                    break;
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAdminUserManagement.fulfilled, (state, {payload}) => {
            state.userList = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchAdminUserManagement.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchAdminUserManagement.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default adminUserManagement.reducer


export const adminUserManagementSelector = (state) => state.adminUserManagement;


export const {sortListUser} = adminUserManagement.actions
