import * as React from 'react';
import { Route } from 'react-router';
import Layout from './Layout';
import HomePage from "./Homepage";
import AddClient from "./AddClient";
import NouvelleCommande from "./NouvelleCommande";
import ListRedacteur from "./ListRedacteur";
import GestionUtilisateur from "./GestionUtilisateur";
import MyProfile from "./MyProfile";
import CommandeDisplay from "./CommandeDisplay";
import ListCommande from "./ListCommande";
import Analyzer from "./Analyzer";
import ListCommandeNonAttribuee from "./ListCommandeNonAttribuee";
import AddRedacteur from "./AddRedacteur";
import UpdateClient from "./UpdateClient";
import ListDesDemandes from "./ListeDesDemandes";

export default class MainMenu extends React.Component {

    render() {
        return (
            <Layout>
                <Route path='/Admin/MainMenu' render={(props) => <HomePage {...props} reload={true}  />} />
                <Route path='/Admin/AjouterClient' component={AddClient} />
                <Route path='/Admin/FicheClient' component={UpdateClient} />
                <Route path='/Admin/AjouterRédacteur' component={AddRedacteur} />
                <Route path='/Admin/Rédacteur' component={ListRedacteur} />
                <Route path='/Admin/NouvelleCommande' component={NouvelleCommande} />
                <Route path='/Admin/AffichageDesCommandes' component={ListCommande} />
                <Route path='/Admin/AffichageDesCommandesNonAttribuée' component={ListCommandeNonAttribuee} />
                <Route path='/Admin/DétailsDeLaCommande' component={CommandeDisplay} />
                <Route path='/Admin/ListeDesDemandes' component={ListDesDemandes} />
                <Route path='/Admin/TextDetails' component={Analyzer} />
                <Route path='/Admin/GestionUtilisateur' component={GestionUtilisateur} />
                <Route path='/Admin/MonProfile' component={MyProfile} />
            </Layout>
        )
    }

}
