import {createSlice} from '@reduxjs/toolkit'


export const adminRedactorDisplay = createSlice({
    name: 'adminRedactorDisplay',
    initialState: {
        redactorForm: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateRedactorForm: (state, action) => {
            state.redactorForm = action.payload;
        }
    }
})


export default adminRedactorDisplay.reducer


export const {updateRedactorForm} = adminRedactorDisplay.actions

export const adminRedactorDisplaySelector = (state) => state.adminRedactorDisplay;
