import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchClientList = createAsyncThunk(
    'consultantClientList/fetchClientList',
    async (id, thunkAPI) => {
        try {
            if (!id)
                return thunkAPI.rejectWithValue(404);
            const myClients = await axios.get("/Consultant/GetMyClients?id=" +id);
            const allClients = await  axios.get("/Consultant/GetAllClients?id=" +id);
            if(myClients.status === 200 && allClients.status === 200){
                return {
                    myClients : myClients.data,
                    allClients : allClients.data,
                }
            }
            return thunkAPI.rejectWithValue(502);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

