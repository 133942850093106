import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {MDBDataTable} from "mdbreact";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import {DeliveredTexts, ValidatedTexts} from "../../helpers/Constants";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";


export default function CommandeClient(props) {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (props.id && props.visible) {
            console.log(props.id);
            axios.get("/Consultant/GetCommandClient?id=" + props.id).then(res => {
                const columns = [
                    {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Titre de la commande</div>,
                        field: 'titre',
                        sort: 'asc',
                        width: 250,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Rédacteur</div>,
                        field: 'redactor',
                        sort: 'asc',
                        width: 250,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date de livraison de la
                            commande</div>,
                        field: 'created_at',
                        sort: 'asc',
                        width: 800,
                    }, {
                        label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes livrés</div>,
                        field: 'delivered_texts',
                        sort: 'asc',
                        width: 800,
                    }, {
                        label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Textes validés</div>,
                        field: 'validated_texts',
                        sort: 'asc',
                        width: 250,
                    }
                ];
                let rows = [];
                res.data.map(item => rows.push({
                    titre: item.titre,
                    redactor: <TextField id="select" InputLabelProps={{shrink: true}}
                                         value={item.redactorMail[0]}
                                         select>
                        {
                            item.redactorMail.map((x, key) => <MenuItem key={key}
                                                                        value={x}>{x}</MenuItem>)
                        }
                    </TextField>,
                    created_at: item.deadline ? formatDate(item.deadline) : "-",
                    delivered_texts: <DeliveredTexts deliveredText={item.deliveredText}
                                                     total={parseInt(item.nbrText)}/>,
                    validated_texts: <ValidatedTexts validatedText={item.validatedText}
                                                     total={parseInt(item.nbrText)}/>,
                }));

                setList({columns, rows});
            })
        }
    }, [props]);


    const formatDate = (date) => {
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
    if (!list) {
        return null
    }

    return (
        <Dialog open={props.visible} onClose={props.Close} fullWidth maxWidth={'lg'}>
            <DialogTitle disableTypography style={{minWidth: 800}}>
                <div style={{display: 'flex'}}>
                    <Typography style={{width: '80%'}} variant="h6">Liste des commandes</Typography>
                    <IconButton onClick={props.Close} style={{display: 'contents', justifyContent: 'flex-end'}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{minWidth: 800}}>
                <MDBDataTable striped hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4}
                              noBottomColumns
                              infoLabel={['', '-', 'sur', 'résultats']}
                              sortable
                              paging={list && list.rows && list.rows.length > 0}
                              paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                              searchLabel={'Recherche'}
                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                              info={true}
                              entriesLabel={'Afficher les résultats'}
                              data={list}/>
            </DialogContent>
        </Dialog>
    )
}
