import React, {useEffect, useState, useCallback} from 'react'
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {MDBDataTable} from "mdbreact";
import Typography from '@material-ui/core/Typography';
import {HowManyDayLeft, DeliveredTexts, getDateFormat, ValidatedTexts, COULEUR_BLEUE} from "../../helpers/Constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import {adminCommandListSelector, sortListCommand} from "../../store/Admin/CommandList";

import {useHistory} from 'react-router-dom';
import {fetchAdminCommandDisplay} from "../../store/Admin/CommandDisplay/features";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {useTranslation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const useStyle = theme => ({
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    }
})
export default function ListCommande() {

    const {t} = useTranslation();
    const {listCommand, title, isLoading, isError} = useSelector(adminCommandListSelector);

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyle();
    const [data, setData] = useState([]);
    const[search,setSearch] = useState('');

    const fetchData = () => {
        let columns = [
            {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>{title}</div>,
                field: 'title',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Date de livraison estimée</div>,
                field: 'date',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Rédacteurs</div>,
                field: 'redactor',
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes livrés</div>,
                field: 'delivered_texts'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Textes validés</div>,
                field: 'validated_texts'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Temps restant pour valider la
                    commande</div>,
                field: 'temps_restant'
            }, {
                label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Validée</div>,
                field: 'validated'
            }
        ];
        if (title === t('admin.homepage.ordersCompleted')) {
            columns = columns.filter(x => x.field !== 'temps_restant');
        }
        let rows = [];
        if (listCommand && listCommand.length > 0) {
            listCommand.filter(x => {
                if(search.length === 0) {
                    return true;
                }
                return (x.titre.toUpperCase().includes(search.toUpperCase()) ||  getDateFormat(x.deadline).includes(search) || x.redactors.some(x => x.includes(search)));
            }).map(item => {
                rows.push({
                    title: <Typography style={{cursor: 'pointer'}} onClick={() => {
                        dispatch(fetchAdminCommandDisplay(item.id))
                        history.push("/Admin/DétailsDeLaCommande")
                    }
                    }>{item.titre}</Typography>,
                    date: <Typography
                        align={'center'}
                        position="static"
                        className={(new Date() > new Date(item.deadline)) && "text-danger"}> {item.deadline ? getDateFormat(item.deadline) : "non spécifié"} </Typography>,
                    redactor: <TextField id="select"
                                         inputProps={{'aria-label': 'Without label'}} defaultValue={item.redactors[0]}
                                         select>
                        {
                            item.redactors.map((x, key) => <MenuItem key={key} value={x}>{x}</MenuItem>)
                        }
                    </TextField>,
                    delivered_texts: <DeliveredTexts deliveredText={item.deliveredText}
                                                     total={parseInt(item.nbrText)}/>,
                    validated_texts: <ValidatedTexts validatedText={item.validatedText}
                                                     total={parseInt(item.nbrText)}/>,
                    temps_restant: <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {
                            item.allTextValidated ? <Typography>0 jour</Typography> : (item.remainingTime ?
                                <HowManyDayLeft id={item.id} finishedAt={item.finishedAt} url={"/Admin"}/> :
                                <Typography> - </Typography>)
                        }
                    </div>,
                    validated: <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{
                        item.allTextValidated ? <CheckCircleIcon
                            style={{color: 'green'}}/> : <Typography>-</Typography>
                    }</div>,

                })
            });
            document.title = "Typix - " + title;
        }
        setData({columns, rows});
    }

    if (isError) {
        history.push("/Admin/MainMenu")
    }

    useEffect(() => {
        if (!isLoading) {
            fetchData()
        }
    }, [isLoading, listCommand,search])

    return (
        <div style={{marginTop: 5, marginBottom: 100, marginRight: 100, marginLeft: 100}}>

            <Card>
                <CardHeader className={classes.cardHeader} title={title}
                            action={<Tooltip title="Retour" placement="right">
                                <span>
                                        <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                                       onClick={() => history.goBack()}/>
                                </span>
                            </Tooltip>}/>
                <CardContent>
                    {
                        isLoading ? <React.Fragment>
                                <LinearProgress/>
                                <LinearProgress color="secondary"/>
                            </React.Fragment> :
                            <React.Fragment>
                                <TextField
                                    label={"Recherche"}
                                    style={{float: 'right' , position :'relative'}}
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />

                                <MDBDataTable striped entriesOptions={[10, 20, 50]} entries={10}
                                              noBottomColumns
                                              infoLabel={['', '-', 'sur', 'résultats']}
                                              sortable
                                              searching={false}
                                              onSort={({column, direction}) => {
                                                  if (data && data.rows && data.rows.length > 0 && column) {
                                                      dispatch(sortListCommand({column, direction}))
                                                  }
                                              }}
                                              paging={data && data.rows && data.rows.length > 0}
                                              paginationLabel={[<div
                                                  style={{cursor: 'pointer'}}>Précédent</div>,
                                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                                              searchLabel={'Recherche'}
                                              noRecordsFoundLabel={'aucun enregistrement correspondant trouvé'}
                                              info={true}
                                              entriesLabel={'Afficher les résultats'}
                                              data={data}/>


                            </React.Fragment>
                    }

                </CardContent>
            </Card>

        </div>

    )
}

// + (new Date() > new Date(item.deadline)) ? 'danger' : 'initial'
/*
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
<CheckValid id={item.id}
url={"/Admin"}
allTextValidated={item.allTextValidated}
finishedAt={item.finishedAt}
finished={item.finished}/>
</div>
*/
