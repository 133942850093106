import {createSlice} from '@reduxjs/toolkit'
import {fetchClientNavCounter} from "./features";


export const clientNavCounter = createSlice({
    name: 'clientNavCounter',
    initialState: {
        newCommand: null,
        newText: null,
        isLoading : true
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClientNavCounter.fulfilled, (state, {payload}) => {
            state.newCommand = payload.newCommand;
            state.newText = payload.newText;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchClientNavCounter.pending, (state, {payload}) => {
            state.isLoading = true;
        }).addCase(fetchClientNavCounter.rejected, (state, {payload}) => {
            state.isLoading = false;
        })
    }
})


export default clientNavCounter.reducer


export const clientNavCounterSelector = (state) => state.clientNavCounter;
