import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from "reactstrap";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import "./Homepage.css"
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import ListItemText from "@material-ui/core/ListItemText";
import {UncontrolledPopover, PopoverBody} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ISOlanguages} from "../../helpers/Constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import {
    Card,
    CardContent,
    CardHeader
} from "@material-ui/core";
import {MDBDataTable} from "mdbreact";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {Scrollbars} from 'react-custom-scrollbars';
import {useDispatch, useSelector} from "react-redux";
import {adminRedactorListSelector} from "../../store/Admin/RedactorList";
import {useHistory} from 'react-router-dom';
import {fetchAdminRedactorList} from "../../store/Admin/RedactorList/features";
import {updateRedactorForm} from "../../store/Admin/RedactorDisplay";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Jumbotron from "reactstrap/lib/Jumbotron";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
export default function ListRedacteur() {
    let {redactorList, isLoadingRedactor} = useSelector(adminRedactorListSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [result, setResult] = useState(null);
    useEffect(() => {
        dispatch(fetchAdminRedactorList());
    }, [])
    const [searchView, setSearchView] = useState(true);
    const [loading, setLoading] = useState(false);
    const [nameSearch, setNameSearch] = useState(null);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, [])

    const Search = (input) => {
        axios.post("/Admin/RechercherReducteur", input).then(res => setResult(res.data)).then(() => setSearchView(false)).catch(e => console.log(e.response.data))
        setLoading(false);
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Jumbotron>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <ArrowBackIcon color={"primary"} style={{fontSize: 40, cursor: 'pointer'}}
                                               onClick={() => history.push("/Admin/MainMenu")}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography paragraph variant={"h5"}> Rédacteurs : </Typography>
                            </Grid>
                            {isLoadingRedactor && <Grid item xs={12}>
                                <LinearProgress color="secondary"/>
                            </Grid>}
                            {(redactorList && redactorList.length > 0) &&
                            <React.Fragment>

                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={redactorList}
                                        getOptionLabel={(option) => option.nomPrenom}
                                        onInputChange={((event, value) => setNameSearch(value))}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recherche"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{...params.InputProps, type: 'search'}}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Scrollbars autoHeight autoHeightMin={400}>

                                        <List component="nav" aria-label="secondary mailbox folders">
                                            {
                                                (redactorList && redactorList.length > 0) && redactorList.filter(x => {
                                                    if (nameSearch && nameSearch.length > 0) {
                                                        return x.nomPrenom === nameSearch
                                                    }
                                                    return true;
                                                }).map((item, key) => <ListItem
                                                    key={key} button onClick={() => {
                                                    dispatch(updateRedactorForm(item))
                                                    history.push("/Admin/AjouterRédacteur")
                                                }}>
                                                    <ListItemText primary={item.nomPrenom}/>
                                                </ListItem>)
                                            }

                                        </List>

                                    </Scrollbars>
                                </Grid>

                            </React.Fragment>
                            }
                            {(redactorList && redactorList.length === 0) &&
                            <Typography paragraph variant={"h5"}>Aucun rédacteur</Typography>}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" style={{margin: 15}}
                                        onClick={() => {
                                            dispatch(updateRedactorForm(null))
                                            history.push("/Admin/AjouterRédacteur")
                                        }}>
                                    Ajouter un Rédacteur
                                </Button>
                            </Grid>

                        </Grid>
                    </Jumbotron>
                </Grid>
                <Grid item xs={8}>
                    <Jumbotron>
                        {
                            searchView ? <SearchArea Search={Search} load={() => setLoading(true)} loading={loading}/> :
                                <ResultArea result={result} return={() => setSearchView(true)}/>
                        }
                    </Jumbotron>

                </Grid>
            </Grid>
        </Container>
    )
}

const SearchArea = (props) => {

    const [thematique, setThematique] = useState([]);
    const [listLangue, setListLangue] = useState([]);
    const [otherLangue, setOtherLangue] = useState([]);
    const [rating, setRating] = useState(0);
    const history = useHistory();
    const [ThématiqueList, setThématiqueList] = useState([]);
    useEffect(() => {
        axios.get("/Admin/GetListThematique").then(res => setThématiqueList(res.data)).catch(e => console.log(e.response.data))
        document.title = "Typix - Rechercher un rédacteur";
    }, [])
    return (
        <Container className="themed-container" fluid="sm">
            <Col xs="auto">

                <Row>
                    <InputLabel>Rechercher un rédacteur</InputLabel>
                </Row>
                <Row>
                    <FormControl fullWidth style={{marginTop: 30, marginBottom: 30}}>
                        <InputLabel shrink>Thématique</InputLabel>
                        <Select
                            multiple
                            value={thematique}
                            onChange={e => setThematique(e.target.value)}
                            inputProps={{
                                name: 'thematique',
                                id: 'thematique-native-simple',
                            }}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em> Menu déroulant </em>;
                                }
                                return (<div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} style={{margin: 1}}/>
                                    ))}
                                </div>)
                            }}
                        >
                            {
                                ThématiqueList.map((item, key) => <MenuItem key={key} value={item}>
                                        <Checkbox checked={thematique.indexOf(item) > -1}/>
                                        <ListItemText primary={item}/>
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Row>
                <Row>
                    <InputLabel style={{marginBottom: 30}}>Langues :</InputLabel>
                </Row>

                <Row>
                    <Col md={'6'}>
                        <FormControlLabel
                            style={{margin: 15}}
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Français")}
                                    onChange={() => listLangue.includes("Français") ? setListLangue(listLangue.filter(x => x !== "Français")) : setListLangue([...listLangue, "Français"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Français"
                        />
                    </Col>
                    <Col md={'6'}>
                        <FormControlLabel
                            style={{margin: 15}}
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Italien")}
                                    onChange={() => listLangue.includes("Italien") ? setListLangue(listLangue.filter(x => x !== "Italien")) : setListLangue([...listLangue, "Italien"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Italien"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={'6'}>
                        <FormControlLabel
                            style={{margin: 15}}
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Anglais")}
                                    onChange={() => listLangue.includes("Anglais") ? setListLangue(listLangue.filter(x => x !== "Anglais")) : setListLangue([...listLangue, "Anglais"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Anglais"
                        />
                    </Col>
                    <Col md={'6'}>
                        <FormControlLabel
                            style={{margin: 15}}
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Allemand")}
                                    onChange={() => listLangue.includes("Allemand") ? setListLangue(listLangue.filter(x => x !== "Allemand")) : setListLangue([...listLangue, "Allemand"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Allemand"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={'6'}>
                        <FormControlLabel
                            style={{margin: 15}}
                            control={
                                <Checkbox
                                    checked={listLangue.includes("Espagnol")}
                                    onChange={() => listLangue.includes("Espagnol") ? setListLangue(listLangue.filter(x => x !== "Espagnol")) : setListLangue([...listLangue, "Espagnol"])}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Espagnol"
                        />
                    </Col>
                    <Col md={'6'}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            fullWidth
                            style={{margin: 15}}
                            options={ISOlanguages.filter(x => !['Français', 'Italien', 'Allemand', 'Espagnol', 'Anglais'].includes(x.name)).sort(function (a, b) {
                                if (a.name > b.name) {
                                    return 1;
                                }
                                if (b.name > a.name) {
                                    return -1;
                                }
                                return 0;
                            })}
                            onInputChange={((event, value) => setOtherLangue(value))}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Autre" placeholder="Langue"/>
                            )}
                        />
                    </Col>
                </Row>

                <Row>
                    <Box component="fieldset" mb={3} borderColor="transparent">
                        <Typography component="legend">Note</Typography>
                        <Rating
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                        />
                    </Box>
                </Row>

                <Row>

                    {
                        !props.loading ? <Button variant="contained" color="primary" onClick={() => {
                                props.load();
                                props.Search({
                                    thematiques: thematique,
                                    langues: listLangue.concat(otherLangue),
                                    note: rating
                                })
                            }}>Rechercher</Button> :
                            <React.Fragment>
                                <LinearProgress/>
                                <LinearProgress color="secondary"/>
                            </React.Fragment>
                    }
                </Row>


            </Col>
        </Container>
    )
}


const useStyle = makeStyles((theme) => ({
    cardContainer: {
        marginTop: '5px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    },
    typography: {
        padding: theme.spacing(2),
    }
}))

const ResultArea = (props) => {
    const classes = useStyle();
    const [data, setData] = useState([]);
    useEffect(() => {
        (() => {
            const columns = [
                {
                    label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Nom/Prénom</div>,
                    field: 'nomPrenom',
                    width: 250,

                }, {
                    label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Langue parlées</div>,
                    field: 'langue',
                    width: 250,
                }, {
                    label: <div style={{cursor: 'pointer', fontWeight: 'bold'}}>Note</div>,
                    field: 'note',
                    width: 250,
                }, {
                    label: <div style={{cursor: 'text', fontWeight: 'bold'}}>Contacter</div>,
                    field: 'contacter',
                    sort: 'disabled'
                }
            ];
            let rows = [];
            if (props && props.result && props.result.length > 0) {
                props.result.map((item, key) => rows.push({
                    nomPrenom: item.nomPrenom,
                    langue: item.redacteurLangue.map(x => ISOlanguages.filter(s => s.name === x.langue).length > 0 &&
                        ISOlanguages.find(i => i.name === x.langue).code.toUpperCase()).join("/"),
                    note: <Rating value={item.note} readOnly/>,
                    contacter: <div style={{flexDirection: 'row', display: 'flex'}}>
                        <button style={{marginRight: 10, cursor: 'pointer'}}
                                onClick={() => window.open("mailto:" + item.mail)}>
                            <MailIcon/>
                        </button>
                        <button id={"PopoverFocus" + key}>
                            <PhoneIcon/>
                        </button>
                        <UncontrolledPopover trigger="legacy" placement="bottom" target={"PopoverFocus" + key}>
                            <PopoverBody>{item.numTelephone}</PopoverBody>
                        </UncontrolledPopover>
                    </div>

                }));
            }
            setData({columns, rows});
            document.title = "Typix - Rédacteurs trouvés";
        })();
    }, [props, props.result]);
    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}
                        title={"Rédacteurs trouvés : " + props.result.length + " rédacteur" + ((props.result && props.result.length > 0) ? 's' : '')}
                        action={<Tooltip title="Retour" placement="right">
                    <span>
                                <ArrowBackIcon color={"primary"} style={{fontSize: 40,  cursor: 'pointer'}}
                                               onClick={props.return}/>


                    </span>
                        </Tooltip>}/>
            <CardContent>
                <MDBDataTable striped hover entriesOptions={[10, 20, 50]} entries={10}
                              noBottomColumns
                              infoLabel={['', '-', 'sur', 'résultats']}
                              sortable
                              paging={data && data.rows && data.rows.length > 0}
                              paginationLabel={[<div style={{cursor: 'pointer'}}>Précédent</div>,
                                  <div style={{cursor: 'pointer'}}>Suivant</div>]}
                              searchLabel={'Recherche'}
                              noRecordsFoundLabel={'Aucun résultat trouvé '}
                              info={true}
                              entriesLabel={'Afficher les résultats'}
                              data={data}/>
            </CardContent>
        </Card>
    )
}
