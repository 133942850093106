import {createSlice} from '@reduxjs/toolkit'
import {fetchUserSession} from "./features";


export const UserSession = createSlice({
    name: 'userSession',
    initialState: {
        user : null,
        type : null,
        isLoading: true,
        isError: false,
        notFound: false,
        isSuccess: false,
    },
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload;
        }
        ,updateRedactorLimit: (state, action) => {
            let newData = state.user;
            newData.analysis = action.payload.analysis;
            newData.limite = action.payload.limite;
            state.user = newData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserSession.fulfilled, (state, {payload}) => {
            state.user = payload.user;
            state.type = payload.type;
            state.isLoading = false;
            state.isError = false;
            state.notFound = false;
            state.isSuccess = true;
        }).addCase(fetchUserSession.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
            state.notFound = false;
        }).addCase(fetchUserSession.rejected, (state, {payload}) => {
            state.notFound = true;
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
        })
    }
})


export default UserSession.reducer


export const {updateUser,updateRedactorLimit} = UserSession.actions
export const UserSessionSelector = (state) => state.userSession;
