import {createSlice} from '@reduxjs/toolkit'
import {fetchConsultantCommandList} from "./features";


export const consultantCommandList = createSlice({
    name: 'consultantCommandList',
    initialState: {
        listCommand: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        sortListCommand: (state, {payload}) => {
            switch (payload.column) {
                default :
                    break;
                case "delivered_texts" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        const pourcentageA = (a.deliveredText * 100) / a.nbrText;
                        const pourcentageB = (b.deliveredText * 100) / b.nbrText;
                        if (payload.direction === "asc") {
                            return pourcentageA - pourcentageB;
                        } else {
                            return pourcentageB - pourcentageA;
                        }
                    });
                    break;
                case "validated_texts" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        const pourcentageA = (a.validatedText * 100) / a.nbrText;
                        const pourcentageB = (b.validatedText * 100) / b.nbrText;
                        if (payload.direction === "asc") {
                            return pourcentageA - pourcentageB;
                        } else {
                            return pourcentageB - pourcentageA;
                        }
                    });
                    break;
                case "date" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return new Date(a.deadline) - new Date(b.deadline);
                        } else {
                            return new Date(b.deadline) - new Date(a.deadline);
                        }
                    });
                    break;
                case "title" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return a.titre.localeCompare(b.titre);
                        } else {
                            return b.titre.localeCompare(a.titre);
                        }
                    });
                    break;
                case "temps_restant" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (a.remainingTime === null) {
                            return 1;
                        }
                        if (b.remainingTime === null) {
                            return -1;
                        }
                        if (a.remainingTime === b.remainingTime) {
                            return 0;
                        }
                        else if (payload.direction === "asc") {
                            return a.remainingTime < b.remainingTime ? -1 : 1;
                        }
                        else {
                            return a.remainingTime < b.remainingTime ? 1 : -1;
                        }
                    });
                    break;
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConsultantCommandList.fulfilled, (state, {payload}) => {
            state.listCommand = payload;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchConsultantCommandList.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchConsultantCommandList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default consultantCommandList.reducer


export const consultantCommandListSelector = (state) => state.consultantCommandList;


export const {sortListCommand} = consultantCommandList.actions
