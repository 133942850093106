import * as React from 'react';
import { Route } from 'react-router';
import Layout from './Layout';
import HomePage from "./Homepage";
import MyProfile from "./MyProfile";
import ListCommande from "./ListCommande";
import ListCommandeNonAttribuee from "./ListCommandeNonAttribuee";
import CommandeDisplay from "./CommandeDisplay";
import Analyzer from "./Analyzer";
export default class MainMenu extends React.Component {

    render() {
        return (
            <Layout>
                <Route path='/Rédacteur/MainMenu' render={(props) => <HomePage {...props} reload={true}  />}  />
                <Route path='/Rédacteur/MonProfile' component={MyProfile} />
                <Route path='/Rédacteur/AffichageDesCommandes' component={ListCommande} />
                <Route path='/Rédacteur/AffichageDesCommandesNonAttribuée' component={ListCommandeNonAttribuee} />
                <Route path='/Rédacteur/DétailsDeLaCommande' component={CommandeDisplay} />
                <Route path='/Rédacteur/TextDetails' component={Analyzer} />
            </Layout>
        )
    }

}
