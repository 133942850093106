import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router';
import './custom.css'
import {Redirect} from 'react-router-dom';
import Login from './Login'

import './custom.css'
import MainMenu from './components/Admin/MainMenu';
import RedacteurMainMenu from './components/Redacteur/MainMenu';
import ConsultantMainMenu from './components/Consultant/MainMenu';
import ClientMainMenu from './components/Client/MainMenu';
import {fetchUserSession} from "./store/UserSession/features";
import {useDispatch, useSelector} from "react-redux";
import {UserSessionSelector} from "./store/UserSession";

export default function App() {
    let {isLoading, type, notFound, isSuccess} = useSelector(UserSessionSelector);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUserSession(localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User")) : null))
    }, [])
    if (isLoading) {
        return (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={'/Images/logo.png'} height={"50%"} width={"50%"} align={'center'} alt={''}/>
        </div>)
    }
    return (
        <Switch>
            <Route exact path='/' render={() => {
                if (isSuccess && !notFound) {
                    if (type === "Admin") {
                        return (<Redirect to={'/Admin/MainMenu'}/>)
                    }
                    if (type === "Rédacteur") {
                        return (<Redirect to={'/Rédacteur/MainMenu'}/>)
                    }
                    if (type === "Consultant") {
                        return (<Redirect to={'/Consultant/MainMenu'}/>)
                    }
                    if (type === "Client") {
                        return (<Redirect to={'/Client/MainMenu'}/>)
                    }
                } else {
                    return (<Login/>)
                }
            }}/>
            <Route path='/Admin' component={MainMenu}/>
            <Route path='/Rédacteur' component={RedacteurMainMenu}/>
            <Route path='/Consultant' component={ConsultantMainMenu}/>
            <Route path='/Client' component={ClientMainMenu}/>
        </Switch>
    )
}
