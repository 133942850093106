import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchAdminListDesDemandes = createAsyncThunk(
    'adminListDesDemandes/fetchAdminCommandDisplay',
    async (obj, thunkAPI) => {
        try {
            if (!obj)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Admin/ListDesDemandes?id=" + obj.id)
            if (response.status === 200) {
                console.log("DATA :" , response.data)
                return {
                    title: obj.title,
                    id: obj.id,
                    data: response.data.listDemande,
                    nbrRemaining: response.data.nbrRemaining
                }
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

