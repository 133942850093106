import {createSlice} from '@reduxjs/toolkit'


export const clientAnalyzer = createSlice({
    name: 'clientAnalyzer',
    initialState: {
        idCommande: null,
        formData: null,
        revisionDisabled: false,
        idCommandeRedacteurRelation: null,
        finished: false,
    },
    reducers: {
        updateForm: (state, action) => {
            state.formData = action.payload.data;
            state.revisionDisabled = action.payload.revisionDisabled;
            state.idCommandeRedacteurRelation = action.payload.idCommandeRedacteurRelation;
            state.idCommande = action.payload.idCommande;
            state.finished = action.payload.finished;
        }
    }
})


export default clientAnalyzer.reducer

export const {updateForm} = clientAnalyzer.actions
export const clientAnalyzerSelector = (state) => state.clientAnalyzer;
