import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchUserSession = createAsyncThunk(
    'userSession/fetchUserSession',
    async (userSession, thunkAPI) => {
        try {
            console.log("User" , userSession);
            if (!userSession)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Auth/CheckTokenValid", {
                params: {
                        tokenString: userSession.token,
                        type: userSession.type,
                        userMail: userSession.mail,
                }
            })
            console.log("RESPONSE" , response);
            if (response.status === 200) {
                axios.defaults.headers['Authorization'] = `Bearer ${userSession.token}`;
                return {
                    user : response.data,
                    type : userSession.type
                };
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

