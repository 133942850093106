import {createSlice} from '@reduxjs/toolkit'
import {fetchRedactorCommandList} from "./features";


export const redactorCommandList = createSlice({
    name: 'redactorCommandList',
    initialState: {
        title: null,
        listCommand: null,
        isLoading: true,
        isError: true,
    },
    reducers: {
        updateCommande: (state, action) => {
            const index = state.listCommand.findIndex(obj => obj.id === action.payload.id);
            state.listCommand[index] = action.payload.data;
        },
        sortListCommand: (state, {payload}) => {
            switch (payload.column) {
                default :
                    break;
                case "title" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return a.titre.localeCompare(b.titre);
                        } else {
                            return b.titre.localeCompare(a.titre);
                        }
                    });
                    break;
                    case "titleCommande" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return a.idCommandeNavigation.titre.localeCompare(b.titre);
                        } else {
                            return b.idCommandeNavigation.titre.localeCompare(a.titre);
                        }
                    });
                    break;
                case "date" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (payload.direction === "asc") {
                            return new Date(a.deadline) - new Date(b.deadline);
                        } else {
                            return new Date(b.deadline) - new Date(a.deadline);
                        }
                    });
                    break;
                case "status" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        const nbrA = (a.nbrText * 100) / a.completedText;
                        const nbrB = (b.nbrText * 100) / b.completedText;
                        if (nbrA === nbrB) {
                            return 0;
                        } else if (payload.direction === "asc") {
                            return nbrA < nbrB ? -1 : 1;
                        } else {
                            return nbrA < nbrB ? 1 : -1;
                        }
                    });
                    break;
                case "thematique" :
                    state.listCommand = state.listCommand.sort((a, b) => {
                        if (a.idCommandeNavigation.commandeThematique.length === 0) {
                            return 1;
                        }
                        if (b.idCommandeNavigation.commandeThematique.length === 0) {
                            return -1;
                        }
                        const sortA = a.idCommandeNavigation.commandeThematique.map(x => x.thematique).sort();
                        const sortB = b.idCommandeNavigation.commandeThematique.map(x => x.thematique).sort();
                        if (payload.direction === "asc") {
                            return sortA < sortB ? -1 : 1;
                        } else {
                            return sortB < sortA ? -1 : 1;
                        }
                    })
                    break;
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRedactorCommandList.fulfilled, (state, {payload}) => {
            state.listCommand = payload.data;
            state.title = payload.title;
            state.url = payload.url;
            state.isLoading = false;
            state.isError = false;
        }).addCase(fetchRedactorCommandList.pending, (state, {payload}) => {
            state.isLoading = true;
            state.isError = false;
        }).addCase(fetchRedactorCommandList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.isError = true;
        })
    }
})


export default redactorCommandList.reducer


export const redactorCommandListSelector = (state) => state.redactorCommandList;


export const {updateCommande, sortListCommand} = redactorCommandList.actions
