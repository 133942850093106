import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";


export const fetchClientsDetails = createAsyncThunk(
    'consultantWebsiteDetails/fetchClientsDetails',
    async (compte, thunkAPI) => {
        try {
            if (!compte)
                return thunkAPI.rejectWithValue(404);
            const response = await axios.get("/Consultant/GetClientsDetails",{
                params :{
                    compte : compte
                }
            })
            console.log(response)
            if(response.status === 200){
                return response.data
            }
            return thunkAPI.rejectWithValue(response.status);

        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

